<template>
    <v-dialog v-model="openAlertDetails" :max-width="dinamicWidth" persistent scrollable>
        <template v-slot:activator="{ on, attrs }">
            <v-btn class="pa-2 mx-1" :icon="icon" :plain="plain" v-bind="attrs" v-on="on">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">visibility </v-icon>
                        <span class="ml-2" v-if="plain">{{ $t("headers.details") }}</span>
                    </template>
                    <span>{{ $t("headers.details") }}</span>
                </v-tooltip>
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="primary white--text">
                <v-icon color="white" class="mr-2">notifications</v-icon>
                {{ tripAlert ? tripAlert.tenantAlertRule.name : "" }}
                <v-spacer></v-spacer>
                <v-btn @click="redirect(dashboardTripId)" color="white" class="mr-2" v-if="dashboardTripId" icon>
                    <v-icon>
                        open_in_new
                    </v-icon>
                </v-btn>
                <v-btn outlined @click="openAlertDetails = false" rounded color="white">{{
                    $t("general.close")
                }}</v-btn>
            </v-card-title>
            <v-card-text :loading="$apollo.queries.tripAlert.loading">
                <LoadingBar v-if="$apollo.queries.tripAlert.loading && !tripAlert"></LoadingBar>
                <v-row v-else>
                    <v-col
                        v-if="tripAlert ? tripAlert.tenantAlertRule.isStandardAlert : false"
                        class="d-flex justify-center align-center mt-4"
                        cols="12"
                    >
                        <!-- <span class="subtitle-1"
                            >{{ getStandardAlertRuleText() }}
                        </span> <br /> -->
                        <!-- <p v-html="seaInfo"></p> -->
                        <v-row class="subtitle-1">
                            <v-col cols="12">
                                <table style="width:100%">
                                    <tr>
                                        <td class="txtIndex text-start">
                                            {{ $t("headers.information") }}
                                        </td>
                                        <td class="txtBody text-end">
                                            {{ getStandardAlertRuleText() }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="txtIndex text-start">
                                            {{ $t("headers.vessel") }}
                                        </td>
                                        <td class="txtBody text-end">
                                            {{ this.standardAlertInfo.vesselName }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="txtIndex text-start">
                                            {{ $t("trips.voyage") }}
                                        </td>
                                        <td class="txtBody text-end">
                                            {{ this.standardAlertInfo.voyage }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="txtIndex text-start">
                                            <!-- {{$t("headers.container_number")}} -->
                                            {{ this.standardAlertInfo.dateType }}
                                        </td>
                                        <td class="txtBody text-end">
                                            {{ this.standardAlertInfo.date }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="txtIndex text-start">
                                            {{ $t("headers.port") }}
                                        </td>
                                        <td class="txtBody text-end">
                                            {{ this.standardAlertInfo.port }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="txtIndex text-start">
                                            {{ $t("headers.container_number") }}
                                        </td>
                                        <td class="txtBody text-end">
                                            {{ this.standardAlertInfo.container }}
                                        </td>
                                    </tr>
                                </table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-else>
                        <v-card flat outlined width="100%" class="mt-3 mb-2 pa-3">
                            <span class="subtitle-1">
                                <b>{{ $t("headers.trigger") }}</b>
                            </span>
                            <v-row class="mt-1" align="stretch">
                                <v-col class="mt-3" cols="auto" v-if="trigger ? trigger.Sensor.length > 0 : false">
                                    <v-row v-for="(sensor, i) in trigger ? trigger.Sensor : []" :key="i"
                                        ><span>
                                            <v-icon>{{
                                                sensorTypeChoices.find(
                                                    sensorTypeChoice => sensorTypeChoice.value === sensor.Type
                                                ).icon
                                            }}</v-icon>
                                            {{ sensorText(sensor) }}
                                        </span></v-row
                                    >
                                </v-col>
                                <v-col cols="auto" v-if="trigger ? trigger.Location.Condition : false">
                                    <span>
                                        <v-icon>location_on</v-icon>
                                        {{ locationText(trigger) }}
                                    </span>
                                </v-col>

                                <v-col cols="auto">
                                    <span>
                                        <v-icon
                                            v-if="
                                                (trigger ? trigger.RemainMinutes != null : false) &&
                                                    (trigger ? trigger.RemainMinutes != 0 : false)
                                            "
                                            >timer</v-icon
                                        >
                                        <v-icon v-else>
                                            bolt
                                        </v-icon>
                                        {{ timerText(trigger) }}
                                    </span>
                                </v-col>
                            </v-row>
                        </v-card>
                        <v-row dense>
                            <v-col cols="12">
                                <LoadingBar v-if="isLoadingChart"></LoadingBar>
                                <div v-else>
                                    <TripFreightTimelineChart
                                        v-if="trackerId"
                                        :minTemperature="minTemperature"
                                        :maxTemperature="maxTemperature"
                                        :minHumidity="minHumidity"
                                        :maxHumidity="maxHumidity"
                                        :minLight="minLight"
                                        :maxLight="maxLight"
                                        :minShock="minShock"
                                        :maxShock="maxShock"
                                        :chartStartTime="startTime"
                                        :chartEndTime="endTime"
                                        :currentStartTime="startTime"
                                        :currentEndTime="currentEndTime"
                                        :alertTrigger="tripAlertTriggers"
                                        :tripAlert="_tripAlert"
                                        :flat="false"
                                        :outlined="true"
                                        :temperatureSeries="mergeSensorData.temperature"
                                        :probeTemperaturesSeries="mergeSensorData.probeTemperature"
                                        :externalTemperaturesSeries="mergeSensorData.externalTemperature"
                                        :lightSeries="mergeSensorData.light"
                                        :humiditySeries="mergeSensorData.humidity"
                                        :shockSeries="mergeSensorData.shock"
                                        :selected="selectedSensors"
                                    ></TripFreightTimelineChart>
                                </div>
                            </v-col>
                            <v-col cols="12">
                                <div class="" width="100%">
                                    <AlertRuleTripMap
                                        v-if="showMap"
                                        ref="alertRuleTripMap"
                                        :alert="tripAlert"
                                        :startDate="startTime"
                                        :endDate="endTime"
                                    ></AlertRuleTripMap>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>

            <!--  <v-card-actions class="d-flex justify-end pb-5 pr-5">
                <v-btn
                    text
                    @click="
                        openAlertDetails = false;
                        showMap = false;
                    "
                    rounded
                    >{{ $t("general.close") }}</v-btn
                >
            </v-card-actions> -->
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";
import LoadingBar from "@/components/base/LoadingBar.vue";
import TripFreightTimelineChart from "@/components/trips/details/TripFreightTimelineChart.vue";
import AlertRuleTripMap from "@/components/alertrules/other/AlertRuleTripMap.vue";
import moment from "moment";
import helper from "@/utils/helper.js";

export default {
    props: {
        tripAlertId: {
            type: String,
            required: true
        },
        alertTime: {
            type: String
        },
        deviceTime: {
            type: String
        },
        dashboardTripId: {
            type: String,
            default: null
        },
        plain: {
            type: Boolean,
            default: false
        },
        tripId: {
            type: String,
            default: null
        },
        icon: {
            type: Boolean,
            default: true
        }
    },
    components: {
        LoadingBar,
        TripFreightTimelineChart,
        AlertRuleTripMap
    },
    data() {
        return {
            openAlertDetails: false,
            tripAlert: null,
            trackerId: null,
            tripTimeRange: [],
            // showMap: true, //this is used to fix a bug related to the map and the DOM
            trigger: null,
            centerBounds: {
                lat: 40.73061,
                lng: -73.935242
            },
            tripAlertTriggers: [],
            sensorTypeChoices: [
                {
                    text: this.$t("general.temperature"),
                    value: "temperature",
                    icon: "thermostat",
                    unit: "temperature"
                },
                {
                    text: this.$t("general.external_temperature"),
                    value: "externalTemperature",
                    icon: "thermostat",
                    unit: "temperature"
                },
                {
                    text: this.$t("general.probe_temperature"),
                    value: "probeTemperature",
                    icon: "thermostat",
                    unit: "temperature"
                },
                {
                    text: this.$t("general.humidity"),
                    value: "humidity",
                    icon: "water_drop",
                    unit: "%"
                },
                {
                    text: this.$t("general.light"),
                    value: "light",
                    icon: "lightbulb",
                    unit: "Lux"
                },
                {
                    text: this.$t("general.shock"),
                    value: "shock",
                    icon: "bolt",
                    unit: "G"
                },
                {
                    text: this.$t("general.pressure"),
                    value: "pressure",
                    icon: "tire_repair",
                    unit: "Psi"
                }
            ],
            locationConditionChoices: [
                { text: this.$t("general.enter"), value: "enter" },
                { text: this.$t("general.exit"), value: "exit" },
                { text: this.$t("general.inside"), value: "inside" },
                { text: this.$t("general.outside"), value: "outside" }
            ]
            // standardAlertInfo: {
            //     vesselName: null,
            //     voyage: null,
            //     dateType: null,
            //     date: null,
            //     port: null,
            //     container: null
            // }
        };
    },
    apollo: {
        trackerDeviceTimeRange: {
            query: gql`
                query trackerDeviceTimeRange($tripId: ID, $trackerId: ID) {
                    trackerDeviceTimeRange(tripId: $tripId, trackerId: $trackerId)
                }
            `,
            variables() {
                return {
                    tripId: this.tripId ? this.tripId : null,
                    trackerId: null
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            result({ data }) {
                if (data?.trackerDeviceTimeRange) {
                    this.tripTimeRange = JSON.parse(data.trackerDeviceTimeRange);
                }
            },
            debounce: 0,
            skip() {
                return false;
            }
        },
        tripAlert: {
            query: gql`
                query TripAlert($tripAlertId: ID!) {
                    tripAlert(id: $tripAlertId) {
                        id
                        trackerMergedSensorData {
                            id
                            deviceTime
                            longitude
                            latitude
                            temperature
                            humidity
                            light
                            externalTemperature
                            probeTemperature
                            pressure
                            co2
                            tracker {
                                id
                            }
                        }
                        deviceTime
                        alertTime
                        alertJson
                        tripFreight {
                            id
                        }
                        tenantAlertRule {
                            id
                            name
                            isStandardAlert
                            standardAlertCode
                        }
                        trip {
                            id
                            tripFreightSet {
                                edges {
                                    node {
                                        id
                                        containerNumber
                                    }
                                }
                            }
                            originLocation {
                                id
                                latitude
                                longitude
                                name
                                radius
                            }
                            destinationLocation {
                                id
                                latitude
                                longitude
                                name
                                radius
                            }
                            tripSeaTransportSet(orderBy: "sequence") {
                                edges {
                                    node {
                                        id
                                        sequence
                                        portTransportType
                                        vessel {
                                            id
                                            name
                                        }
                                        estimatedArrivalDate
                                        actualArrivalDate
                                        estimatedDepartureDate
                                        actualDepartureDate
                                        vesselInputType
                                        vesselInputValue
                                        voyage
                                        portName
                                        portCode
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    tripAlertId: this.tripAlertId
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            result({ data, loading }) {
                if (!loading) {
                    if (data.tripAlert) {
                        this.tripAlert = data.tripAlert;
                        this.trackerId = data.tripAlert?.trackerMergedSensorData?.tracker.id;

                        this.centerBounds = {
                            lat: data.tripAlert?.trackerMergedSensorData?.latitude,
                            lng: data.tripAlert?.trackerMergedSensorData?.longitude
                        };
                        if (data.tripAlert?.alertJson) {
                            this.tripAlertTriggers = [data.tripAlert?.alertJson];
                            this.trigger = JSON.parse(data.tripAlert?.alertJson);
                        }
                    }
                }
            },
            skip() {
                return !this.openAlertDetails;
            }
        },
        trackerMergedSensorDatas: {
            query: gql`
                query TrackerMergedSensorDatas(
                    $tracker: ID!
                    $deviceTimeGte: DateTime
                    $deviceTimeLte: DateTime
                    $orderBy: String
                ) {
                    trackerMergedSensorDatas(
                        tracker: $tracker
                        deviceTime_Lte: $deviceTimeLte
                        deviceTime_Gte: $deviceTimeGte
                        orderBy: $orderBy
                    ) {
                        edges {
                            node {
                                id
                                deviceTime
                                receiveTime
                                light
                                shock
                                humidity
                                temperature
                                externalTemperature
                                probeTemperature
                                latitude
                                longitude
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    tracker: this.trackerId,
                    deviceTimeGte: this.startTime,
                    deviceTimeLte: this.endTime,
                    orderBy: "deviceTime"
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.trackerMergedSensorDatas,
            skip() {
                let _trackerId = this.trackerId ? this.trackerId : null;
                return _trackerId !== null ? false : true;
            }
        }
    },

    computed: {
        me() {
            return helper.me();
        },
        availableSensorsData() {
            let sensors = [];
            if (this.mergeSensorData.temperature?.length > 0) {
                sensors.push("temperature");
            }
            if (this.mergeSensorData.probeTemperatures?.length > 0) {
                sensors.push("probeTemperature");
            }
            if (this.mergeSensorData.externalTemperature?.length > 0) {
                sensors.push("externalTemperature");
            }
            if (this.mergeSensorData.light?.length > 0) {
                sensors.push("light");
            }
            if (this.mergeSensorData.humidity?.length > 0) {
                sensors.push("humidity");
            }
            if (this.mergeSensorData.shock?.length > 0) {
                sensors.push("shock");
            }
            return sensors;
        },
        dinamicWidth() {
            let isStandardAlert = this.tripAlert ? this.tripAlert.tenantAlertRule.isStandardAlert : false;
            return isStandardAlert ? "600" : "1000";
        },
        showMap() {
            return this.openAlertDetails;
        },
        isLoadingChart() {
            return this.$apollo.queries.trackerMergedSensorDatas.loading;
        },
        startTime() {
            let _start = this.updateDateTimeTimezone(this.tripTimeRange.start_date);
            return _start;
        },
        endTime() {
            let _end = this.updateDateTimeTimezone(this.tripTimeRange.end_date);
            return _end;
        },
        currentStartTime() {
            let _alert = this.updateDateTimeTimezone(this.alertTime || this.deviceTime);
            let _start = "";
            if (_alert) {
                _start = moment(_alert)
                    .subtract(1, "hours")
                    .toISOString();
            }
            return _start;
        },
        currentEndTime() {
            let _alert = this.updateDateTimeTimezone(this.alertTime || this.deviceTime);
            let _end = null;
            if (_alert) {
                _end = moment(_alert)
                    .add(1, "hours")
                    .toISOString();
            }
            return _end;
        },
        mergeSensorData() {
            const sensorTypes = [
                "light",
                "shock",
                "humidity",
                "temperature",
                "externalTemperature",
                "probeTemperatures"
            ];
            let object = {};
            for (const sensor of sensorTypes) {
                if (!this.$apollo.queries.trackerMergedSensorDatas.loading) {
                    object[sensor] = this.sensorData(sensor);
                } else {
                    object[sensor] = [];
                }
            }

            return object;
        },
        selectedSensors() {
            let sensors = [];
            if (this.trigger) {
                if (this.trigger.Sensor && this.trigger.TriggerType !== "location") {
                    this.trigger.Sensor.forEach(sensor => {
                        sensors.push(sensor.Type);
                    });
                } else {
                    if (this.availableSensorsData.length > 0) {
                        sensors = this.availableSensorsData;
                    } else {
                        sensors = ["temperature", "light"];
                    }
                }
            }

            return sensors;
        },
        _tripAlert() {
            let alert = [];
            if (this.tripAlert) {
                alert.push({
                    deviceTime: this.tripAlert.deviceTime
                        ? this.convertDateTimeTimezoneToMilliseconds(this.tripAlert.deviceTime)
                        : this.convertDateTimeTimezoneToMilliseconds(this.tripAlert.alertTime),

                    type: this.alertType(this.tripAlert.alertJson)
                });
            }

            return alert;
        },
        minTemperature() {
            let _min = 0;

            let temperature = this.mergeSensorData.temperature.map(item => item[1]) || [];

            let probeTemperature = this.mergeSensorData.probeTemperatures.map(item => item[1]) || [];

            let externalTemperature = this.mergeSensorData.externalTemperature.map(item => item[1]) || [];

            let min = [...temperature, ...probeTemperature, ...externalTemperature].filter(item => item !== null);

            _min = min.length > 0 ? min.reduce((a, b) => Math.min(a, b)) : 0;
            _min = Math.floor(_min);

            if (this.tripAlert?.alertJson != null) {
                let alert = JSON.parse(this.tripAlert.alertJson);
                if (alert?.Sensor && alert?.Sensor?.length > 0) {
                    alert.Sensor.forEach(sensor => {
                        if (sensor.Type == "temperature") {
                            if (sensor.Condition == "in" || sensor.Condition == "out") {
                                if (sensor.Value[0] < _min) {
                                    _min = Math.floor(sensor.Value[0]);
                                }
                            } else {
                                if (sensor.Value < _min) {
                                    _min = Math.floor(sensor.Value);
                                }
                            }
                        }
                    });
                }
            }

            return _min - 5;
        },
        maxTemperature() {
            let _max = 0;

            let temperature = this.mergeSensorData.temperature.map(item => item[1]) || [];

            let probeTemperature = this.mergeSensorData.probeTemperatures.map(item => item[1]) || [];

            let externalTemperature = this.mergeSensorData.externalTemperature.map(item => item[1]) || [];

            let max = [...temperature, ...probeTemperature, ...externalTemperature].filter(item => item !== null);

            _max = max.length > 0 ? max.reduce((a, b) => Math.max(a, b)) : 100;
            _max = Math.round(_max);

            if (this.tripAlert?.alertJson != null) {
                let alert = JSON.parse(this.tripAlert.alertJson);
                if (alert?.Sensor && alert?.Sensor?.length > 0) {
                    alert.Sensor.forEach(sensor => {
                        if (sensor.Type == "temperature") {
                            if (sensor.Condition == "in" || sensor.Condition == "out") {
                                if (sensor.Value[1] > _max) {
                                    _max = Math.round(sensor.Value[1]);
                                }
                            } else {
                                if (sensor.Value > _max) {
                                    _max = Math.round(sensor.Value);
                                }
                            }
                        }
                    });
                }
            }

            return _max + 5;
        },
        minHumidity() {
            let _min = 0;

            let humidity = this.mergeSensorData.humidity.map(item => item[1]) || [];

            let min = humidity.filter(item => item !== null);

            _min = min.length > 0 ? min.reduce((a, b) => Math.min(a, b)) : 0;
            _min = Math.floor(_min);

            if (this.tripAlert?.alertJson != null) {
                let alert = JSON.parse(this.tripAlert.alertJson);
                if (alert?.Sensor && alert?.Sensor?.length > 0) {
                    alert.Sensor.forEach(sensor => {
                        if (sensor.Type == "humidity") {
                            if (sensor.Condition == "in" || sensor.Condition == "out") {
                                if (sensor.Value[0] < _min) {
                                    _min = Math.floor(sensor.Value[0]);
                                }
                            } else {
                                if (sensor.Value < _min) {
                                    _min = Math.floor(sensor.Value);
                                }
                            }
                        }
                    });
                }
            }

            return _min - 0.01;
        },
        maxHumidity() {
            let _max = 0;

            let humidity = this.mergeSensorData.humidity.map(item => item[1]) || [];

            let max = humidity.filter(item => item !== null);

            _max = max.length > 0 ? max.reduce((a, b) => Math.max(a, b)) : 100;
            _max = Math.round(_max);

            if (this.tripAlert?.alertJson != null) {
                let alert = JSON.parse(this.tripAlert.alertJson);
                if (alert?.Sensor && alert?.Sensor?.length > 0) {
                    alert.Sensor.forEach(sensor => {
                        if (sensor.Type == "humidity") {
                            if (sensor.Condition == "in" || sensor.Condition == "out") {
                                if (sensor.Value[1] > _max) {
                                    _max = Math.round(sensor.Value[1]);
                                }
                            } else {
                                if (sensor.Value > _max) {
                                    _max = Math.round(sensor.Value);
                                }
                            }
                        }
                    });
                }
            }

            return _max + 0.01;
        },
        minLight() {
            let _min = 0;

            let light = this.mergeSensorData.light.map(item => item[1]) || [];

            let min = light.filter(item => item !== null);

            _min = min.length > 0 ? min.reduce((a, b) => Math.min(a, b)) : 0;
            _min = Math.floor(_min);

            if (this.tripAlert?.alertJson != null) {
                let alert = JSON.parse(this.tripAlert.alertJson);
                if (alert?.Sensor && alert?.Sensor?.length > 0) {
                    alert.Sensor.forEach(sensor => {
                        if (sensor.Type == "light") {
                            if (sensor.Condition == "in" || sensor.Condition == "out") {
                                if (sensor.Value[0] < _min) {
                                    _min = Math.floor(sensor.Value[0]);
                                }
                            } else {
                                if (sensor.Value < _min) {
                                    _min = Math.floor(sensor.Value);
                                }
                            }
                        }
                    });
                }
            }

            return _min - 1;
        },
        maxLight() {
            let _max = 0;

            let light = this.mergeSensorData.light.map(item => item[1]) || [];

            let max = light.filter(item => item !== null);

            _max = max.length > 0 ? max.reduce((a, b) => Math.max(a, b)) : 100;
            _max = Math.round(_max);

            if (this.tripAlert?.alertJson != null) {
                let alert = JSON.parse(this.tripAlert.alertJson);
                if (alert?.Sensor && alert?.Sensor?.length > 0) {
                    alert.Sensor.forEach(sensor => {
                        if (sensor.Type == "light") {
                            if (sensor.Condition == "in" || sensor.Condition == "out") {
                                if (sensor.Value[1] > _max) {
                                    _max = Math.round(sensor.Value[1]);
                                }
                            } else {
                                if (sensor.Value > _max) {
                                    _max = Math.round(sensor.Value);
                                }
                            }
                        }
                    });
                }
            }
            return _max + 100;
        },
        minShock() {
            let _min = 0;

            let shock = this.mergeSensorData.shock.map(item => item[1]) || [];

            let min = shock.filter(item => item !== null);

            _min = min.length > 0 ? min.reduce((a, b) => Math.min(a, b)) : 0;
            _min = Math.floor(_min);

            if (this.tripAlert?.alertJson != null) {
                let alert = JSON.parse(this.tripAlert.alertJson);
                if (alert?.Sensor && alert?.Sensor?.length > 0) {
                    alert.Sensor.forEach(sensor => {
                        if (sensor.Type == "shock") {
                            if (sensor.Condition == "in" || sensor.Condition == "out") {
                                if (sensor.Value[0] < _min) {
                                    _min = Math.floor(sensor.Value[0]);
                                }
                            } else {
                                if (sensor.Value < _min) {
                                    _min = Math.floor(sensor.Value);
                                }
                            }
                        }
                    });
                }
            }

            return _min - 1;
        },
        maxShock() {
            let _max = 0;

            let shock = this.mergeSensorData.shock.map(item => item[1]) || [];

            let max = shock.filter(item => item !== null);

            _max = max.length > 0 ? max.reduce((a, b) => Math.max(a, b)) : 100;
            _max = Math.round(_max);

            if (this.tripAlert?.alertJson != null) {
                let alert = JSON.parse(this.tripAlert.alertJson);
                if (alert?.Sensor && alert?.Sensor?.length > 0) {
                    alert.Sensor.forEach(sensor => {
                        if (sensor.Type == "shock") {
                            if (sensor.Condition == "in" || sensor.Condition == "out") {
                                if (sensor.Value[1] > _max) {
                                    _max = Math.round(sensor.Value[1]);
                                }
                            } else {
                                if (sensor.Value > _max) {
                                    _max = Math.round(sensor.Value);
                                }
                            }
                        }
                    });
                }
            }

            return _max + 10;
        },
        standardAlertInfo() {
            let standardAlertInfo = {
                vesselName: null,
                voyage: null,
                dateType: null,
                date: null,
                port: null,
                container: null
            };
            let code = this.tripAlert.tenantAlertRule.standardAlertCode;
            let containers = this.tripAlert.trip?.tripFreightSet?.edges.map(freight => {
                if (freight?.node?.containerNumber !== null || freight?.node?.containerNumber !== "") {
                    return freight?.node?.containerNumber;
                }
            });
            standardAlertInfo.container = containers.filter(item => item !== null).join(",");

            let obj = {},
                sliced_obj = {},
                obj_1 = {};
            switch (code) {
                case "SEA_ETD_DELAY":
                    obj = this.tripAlert.trip?.tripSeaTransportSet?.edges[0];
                    standardAlertInfo.vesselName = `${obj?.node?.vessel?.name}(${obj?.node?.vesselInputValue})`;
                    standardAlertInfo.voyage = `${obj?.node?.voyage}`;
                    standardAlertInfo.date = `${this.formatDateTime(obj?.node?.estimatedDepartureDate)}`;
                    standardAlertInfo.port = `${obj?.node?.portName}`;
                    standardAlertInfo.dateType = "ETD";
                    break;
                case "SEA_ETA_DELAY":
                    sliced_obj = this.tripAlert.trip?.tripSeaTransportSet?.edges.slice(-2);
                    obj = sliced_obj[1];
                    obj_1 = sliced_obj[0];
                    standardAlertInfo.vesselName = `${obj_1?.node?.vessel?.name}(${obj_1?.node?.vesselInputValue})`;
                    standardAlertInfo.voyage = `${obj?.node?.voyage}`;
                    standardAlertInfo.date = `${this.formatDateTime(obj?.node?.estimatedArrivalDate)}`;
                    standardAlertInfo.port = `${obj?.node?.portName}`;
                    standardAlertInfo.dateType = "ETA";
                    break;
                case "SEA_TRANS_WAIT":
                    this.tripAlert.trip?.tripSeaTransportSet?.edges.forEach(item => {
                        if (
                            item.node?.portTransportType.toLowerCase() === "t/s" &&
                            (item.node?.actualArrivalDate !== null || item.node?.actualArrivalDate !== "")
                        ) {
                            standardAlertInfo.vesselName = `${item?.node?.vessel?.name}(${item?.node?.vesselInputValue})`;
                            standardAlertInfo.voyage = `${item?.node?.voyage}`;
                            standardAlertInfo.date = `${this.formatDateTime(item?.node?.estimatedDepartureDate)}`;
                            standardAlertInfo.port = `${item.node?.portName}`;
                            standardAlertInfo.dateType = "ETD";
                        }
                    });
                    break;
                case "SEA_DEST_WAIT":
                    sliced_obj = this.tripAlert.trip?.tripSeaTransportSet?.edges.slice(-2);
                    obj = sliced_obj[1];
                    obj_1 = sliced_obj[0];
                    standardAlertInfo.vesselName = `${obj_1?.node?.vessel?.name}(${obj_1?.node?.vesselInputValue})`;
                    standardAlertInfo.voyage = `${obj?.node?.voyage}`;
                    standardAlertInfo.date = `${this.formatDateTime(obj?.node?.actualArrivalDate)}`;
                    standardAlertInfo.port = `${obj.node?.portName}`;
                    standardAlertInfo.dateType = "ATA";
                    break;
                default:
                    break;
            }
            return standardAlertInfo;
        }
    },
    created() {
        this.updateDateTimeTimezone = helper.updateDateTimeTimezone;
        this.formatDateTime = helper.formatDateTime;
        this.relativeTime = helper.relativeTime;
        this.formatTemperature = helper.formatTemperature;
        this.getTemperatureSymbol = helper.getTemperatureSymbol;
        this.getTemperatureUnit = helper.getTemperatureSymbol;
        this.convertDateTimeTimezoneToMilliseconds = helper.convertDateTimeTimezoneToMilliseconds;
    },
    methods: {
        setStandardAlertInfo() {
            let code = this.tripAlert.tenantAlertRule.standardAlertCode;
            let containers = this.tripAlert.trip?.tripFreightSet?.edges.map(freight => {
                if (freight?.node?.containerNumber !== null || freight?.node?.containerNumber !== "")
                    return freight?.node?.containerNumber;
            });
            this.standardAlertInfo.container = containers.join(",");

            let obj = {};
            switch (code) {
                case "SEA_ETD_DELAY":
                    obj = this.tripAlert.trip?.tripSeaTransportSet?.edges[0];
                    this.standardAlertInfo.vesselName = `${obj?.node?.vessel?.name}(${obj?.node?.vesselInputValue})`;
                    this.standardAlertInfo.voyage = `${obj?.node?.voyage}`;
                    this.standardAlertInfo.date = `${this.formatDateTime(obj?.node?.estimatedDepartureDate)}`;
                    this.standardAlertInfo.port = `${obj?.node?.portName}`;
                    this.standardAlertInfo.dateType = "ETD";
                    break;
                case "SEA_ETA_DELAY":
                    obj = this.tripAlert.trip?.tripSeaTransportSet?.edges.slice(-1);
                    this.standardAlertInfo.vesselName = `${obj?.node?.vessel?.name}(${obj?.node?.vesselInputValue})`;
                    this.standardAlertInfo.voyage = `${obj?.node?.voyage}`;
                    this.standardAlertInfo.date = `${this.formatDateTime(obj?.node?.estimatedArrivalDate)}`;
                    this.standardAlertInfo.port = `${obj?.node?.portName}`;
                    this.standardAlertInfo.dateType = "ETA";
                    break;
                case "SEA_TRANS_WAIT":
                    this.tripAlert.trip?.tripSeaTransportSet?.edges.forEach(item => {
                        if (
                            item.node?.portTransportType.toLowerCase() === "t/s" &&
                            (item.node?.actualArrivalDate !== null || item.node?.actualArrivalDate !== "")
                        ) {
                            this.standardAlertInfo.vesselName = `${item?.node?.vessel?.name}(${item?.node?.vesselInputValue})`;
                            this.standardAlertInfo.voyage = `${item?.node?.voyage}`;
                            this.standardAlertInfo.date = `${this.formatDateTime(item?.node?.estimatedDepartureDate)}`;
                            this.standardAlertInfo.port = `${item.node?.portName}`;
                            this.standardAlertInfo.dateType = "ETD";
                        }
                    });
                    break;
                case "SEA_DEST_WAIT":
                    obj = this.tripAlert.trip?.tripSeaTransportSet?.edges.slice(-1);
                    this.standardAlertInfo.vesselName = `${obj?.node?.vessel?.name}(${obj?.node?.vesselInputValue})`;
                    this.standardAlertInfo.voyage = `${obj?.node?.voyage}`;
                    this.standardAlertInfo.date = `${this.formatDateTime(obj?.node?.actualArrivalDate)}`;
                    this.standardAlertInfo.port = `${obj.node?.portName}`;
                    this.standardAlertInfo.dateType = "ATA";
                    break;
                default:
                    break;
            }
        },
        sensorData(sensor) {
            let result = [];
            if (!this.trackerMergedSensorDatas?.edges) {
                return [];
            }
            this.trackerMergedSensorDatas?.edges?.forEach(device => {
                const _time = this.convertDateTimeTimezoneToMilliseconds(device.node?.deviceTime);

                if (sensor === "temperature" || sensor === "externalTemperature" || sensor === "probeTemperature") {
                    if (device.node?.[sensor] != null) {
                        let value = parseFloat(device?.node?.[sensor]).toFixed(2);
                        result.push([_time, this.formatTemperature(value)]);
                    }
                } else {
                    if (device.node?.[sensor] != null) {
                        let value = parseFloat(device?.node?.[sensor]).toFixed(2);
                        result.push([_time, value]);
                    }
                }
            });

            return result;
        },
        redirect(id) {
            let localShowPreview = this.$store.getters["user/showPreview"];
            if (localShowPreview === true) {
                let redirectRoute = this.me.isPartnerUser && !this.me.isTenantUser ? "/previewsharedtrips" : "/previewtrips";
                this.$router.push({
                    path: redirectRoute,
                    query: { id: id, tab: "alerts" }
                });
            } else {
                let redirectRoute = this.me.isPartnerUser && !this.me.isTenantUser ? "/sharedtrips" : "/trips";
                this.$router.push({
                    path: redirectRoute,
                    query: { id: id, tab: "alerts" }
                });
            }
        },
        redirectSumarytab(id) {
            let redirectRoute = this.me.isPartnerUser && !this.me.isTenantUser ? "/sharedtrips" : "/trips";
            this.$router.push({
                path: redirectRoute,
                query: { id: id, tab: "summary" }
            });
        },
        getTime(date) {
            return this.formatDateTime(date)?.slice(11, 16);
        },
        alertType(item) {
            let alert = [];
            let newObj = JSON.parse(item);

            if (newObj?.Location?.Condition) alert.push("location");

            if (newObj?.Sensor?.length > 0) {
                newObj.Sensor.forEach(item => {
                    alert.push(item.Type);
                });
            }

            return alert;
        },
        getStandardAlertRuleText() {
            let code = this.tripAlert.tenantAlertRule.standardAlertCode;

            let timerInHours = this.tripAlert?.alertJson
                ? JSON.parse(this.tripAlert.alertJson).RemainMinutes / 60
                : null;
            let text = null;
            switch (code) {
                case "SEA_ETD_DELAY":
                    if (timerInHours) {
                        text = `
                            ${this.$t("alert_rules.vessel_departure")} ${timerInHours} ${this.$t("general.hours")}
                        `;
                    } else {
                        text = this.$t("alert_rules.vessel_delay");
                    }
                    break;
                case "SEA_ETA_DELAY":
                    if (timerInHours) {
                        text = `${this.$t("alert_rules.vessel_arrival_delay")} ${timerInHours} ${this.$t(
                            "general.hours"
                        )}`;
                    } else {
                        text = this.$t("alert_rules.vessel_arrival");
                    }
                    break;
                case "SEA_TRANS_WAIT":
                    if (timerInHours) {
                        text = `${this.$t("alert_rules.transhipment_delay")} ${timerInHours} ${this.$t(
                            "general.hours"
                        )}`;
                    } else {
                        text = this.$t("alert_rules.transhipment_expectation");
                    }
                    break;
                case "SEA_DEST_WAIT":
                    if (timerInHours) {
                        text = `${this.$t("alert_rules.freight_delay")} ${timerInHours} ${this.$t("general.hours")}`;
                    } else {
                        text = this.$t("alert_rules.freight_delay");
                    }
                    break;
                default:
                    break;
            }

            return text;
        },
        sensorText(sensor) {
            let _choice = this.sensorTypeChoices.find(sensorTypeChoice => sensorTypeChoice.value === sensor.Type);
            let text = _choice.text + " ";

            if (sensor.Condition == "in") {
                text += this.$t("general.between") + " ";
                if (_choice.unit == "temperature") {
                    if (this.getTemperatureUnit() == "ºF")
                        text +=
                            this.celsiusToFahrenheit(sensor.Value[0]) +
                            " " +
                            this.getTemperatureUnit() +
                            " " +
                            this.$t("general.and") +
                            " " +
                            this.celsiusToFahrenheit(sensor.Value[1]) +
                            " " +
                            this.getTemperatureUnit();
                    else
                        text +=
                            sensor.Value[0] +
                            " " +
                            this.getTemperatureUnit() +
                            " " +
                            this.$t("general.and") +
                            " " +
                            sensor.Value[1] +
                            " " +
                            this.getTemperatureUnit();
                } else {
                    text +=
                        sensor.Value[0] +
                        " " +
                        _choice.unit +
                        " " +
                        this.$t("general.and") +
                        " " +
                        sensor.Value[1] +
                        " " +
                        _choice.unit;
                }
            } else if (sensor.Condition == "out") {
                text += this.$t("general.out") + " ";
                if (_choice.unit == "temperature") {
                    if (this.getTemperatureUnit() == "ºF")
                        text +=
                            this.celsiusToFahrenheit(sensor.Value[0]) +
                            " " +
                            this.getTemperatureUnit() +
                            " " +
                            this.$t("general.to") +
                            " " +
                            this.celsiusToFahrenheit(sensor.Value[1]) +
                            " " +
                            this.getTemperatureUnit();
                    else
                        text +=
                            sensor.Value[0] +
                            " " +
                            this.getTemperatureUnit() +
                            " " +
                            this.$t("general.to") +
                            " " +
                            sensor.Value[1] +
                            " " +
                            this.getTemperatureUnit();
                } else {
                    text +=
                        sensor.Value[0] +
                        " " +
                        _choice.unit +
                        " " +
                        this.$t("general.to") +
                        " " +
                        sensor.Value[1] +
                        " " +
                        _choice.unit;
                }
            } else {
                if (sensor.Condition == "gt") text += this.$t("general.above1") + " ";
                else if (sensor.Condition == "lt") text += this.$t("general.above1") + " ";
                if (_choice.unit == "temperature")
                    if (this.getTemperatureUnit() == "ºF")
                        text +=
                            this.celsiusToFahrenheit(sensor.Value[0] || sensor.Value) + " " + this.getTemperatureUnit();
                    else text += (sensor.Value[0] || sensor.Value) + " " + this.getTemperatureUnit();
                else text += (sensor.Value[0] || sensor.Value) + " " + _choice.unit;
            }
            return text;
        },
        locationText(trigger) {
            let _locations = "";
            let _hasContent = false;
            _locations += this.locationConditionChoices.find(
                locationConditionChoice => locationConditionChoice.value === trigger.Location.Condition
            ).text;
            _locations += " ";
            if (trigger?.Location?.Origin) {
                _locations += this.$t("trips.origin");
                _hasContent = true;
            }
            if (trigger?.Location?.Destination) {
                if (_hasContent) _locations += ", ";
                _locations += this.$t("trips.destination");
                _hasContent = true;
            }
            if (trigger?.Location?.Public) {
                if (_hasContent) _locations += ", ";
                _locations += this.$t("trips.public_locations");
                _hasContent = true;
            }
            if (trigger?.Location?.Custom?.length > 0) {
                if (_hasContent) _locations += " " + this.$t("general.and") + " ";
                _locations += trigger.Location.Custom.length + " " + this.$t("alert_rules.custom_locations");
                _hasContent = true;
            }
            return _locations;
        },
        timerText(trigger) {
            if (trigger?.RemainMinutes == null || trigger?.RemainMinutes == 0) {
                return this.$t("general.immediately");
            } else if (trigger?.RemainMinutes == 60) {
                return "1 " + this.$t("date.hour");
            } else return trigger?.RemainMinutes / 60 + " " + this.$t("date.hours");
        }
    }
};
</script>
<style scoped>
.maxWidth {
    max-width: 350px;
    min-width: 290px;
}
.maxHeight {
    max-height: 350px;
    min-height: 290px;
}
.content {
    max-width: 1000px;
    max-height: 800px;
}
.chartContainer {
    height: 280px;
}
.txtIndex {
    max-height: 19px;
    font-size: 12.4px;
    font-weight: 720;
    color: #a4a4a8;
}
.txtBody {
    max-height: 19px;
    font-size: 12.3px;
    font-weight: 650;
}
</style>

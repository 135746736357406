<template>
    <v-container fluid class="pa-4 scrollable">
        <v-row dense align="stretch">
            <!-- Filter -->
            <v-col cols="12" class="d-flex justify-end">
                <FilterMenu
                    :title="$t('dashboard.customize')"
                    :description="$t('dashboard.display')"
                    :choices="filterChoices"
                    :open-on-hover="false"
                    height="500px"
                    width="250px"
                    v-on:changed="onFilterMenuChanged"
                    class="mr-n2"
                ></FilterMenu>
            </v-col>
            <!-- KPIs -->
            <v-col v-for="kpi in dynamicChoices.filter(kpi => kpi.tag !== 'dashboard.table' && kpi.tag !== 'dashboard.active_trips_map')" :key="kpi.tag" cols="12" lg="3" md="6" sm="12">
                <v-card
                    :loading="$apollo.queries.tenantDashboards.loading"
                    :loader-height="3"
                    @click="travelTo(kpi)"
                    height="100%"
                >
                    <v-card-title class="pa-3">
                        <v-row no-gutters>
                            <v-col cols="6">
                                <div>
                                    <h6 class="subtitle-2 grey--text text-left no-wrap">
                                        {{ $t(kpi.tag) }}
                                        <v-icon
                                            v-if="
                                                kpi.code === 'UNRESOLVED_TAG_TEMPERATURE' ||
                                                    kpi.code === 'UNRESOLVED_TAG_TEMPERATURE_HUMIDITY' ||
                                                    kpi.code === 'UNRESOLVED_TAG_TEMPERATURE_HUMIDITY_LIGHT' ||
                                                    kpi.code === 'UNRESOLVED_TAG_PRESSURE' ||
                                                    kpi.code === 'UNRESOLVED_TAG_HUMIDITY' ||
                                                    kpi.code === 'UNRESOLVED_TAG_SHOCK' ||
                                                    kpi.code === 'UNRESOLVED_TAG_LIGHT'
                                            "
                                            small
                                            color="warning"
                                        >
                                            warning
                                        </v-icon>
                                    </h6>
                                </div>
                                <div class="text-left grey--text text--darken-3 mt-3">
                                    <h1 class="font-weight-regular no-wrap">
                                        {{ kpi.value }}
                                    </h1>
                                </div>
                            </v-col>
                            <v-col cols="6" class="text-right">
                                <div>
                                    <v-btn :class="kpi.color" elevation="0" fab small>
                                        <v-icon class="d-flex align-center justify-center" color="white">
                                            {{ kpi.icon }}
                                        </v-icon>
                                    </v-btn>
                                </div>
                                <div>
                                    <span class="text-caption text--disabled no-wrap">
                                        {{ formatDateTime(kpi.lastRefreshedAt) }}
                                    </span>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col cols="12" v-if="showMap">
                <ClusterMap :loadingQuery="$apollo.queries.tripFreightTrackers.loading" :data="trackersLocations">
                </ClusterMap>
            </v-col>
            <!-- ALERTS -->
            <v-col cols="12" lg="12" md="12" sm="12" v-if="showTable">
                <v-card class="mt-1">
                    <v-card-title class="subtitle-2 grey--text">
                        {{ $t("dashboard.unresolved_alerts") }}
                    </v-card-title>
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="tripAlerts ? tripAlerts.edges : []"
                            :loading="$apollo.queries.tripAlerts.loading"
                            item-key="node.id"
                            height="500"
                            fixed-header
                            hide-default-footer
                            :loading-text="$t('loading.loading')"
                            disable-pagination
                            disable-sort
                            loader-height="2"
                            :mobile-breakpoint="screenSize === 'xs' ? 650 : 0"
                        >
                            <template v-slot:item.tracker="{ item }">
                                <v-row v-if="item.node.tripFreightTracker" class="mx-0 px-0">
                                    <v-chip
                                        small
                                        color="primary"
                                        class="px-2 caption"
                                        v-if="item.node.tripFreightTracker.tracker"
                                    >
                                        {{ item.node.tripFreightTracker.tracker.serialNumber }}
                                    </v-chip>
                                </v-row>
                                <div v-else>
                                    -
                                </div>
                            </template>
                            <template v-slot:item.status="{ item }">
                                <div class="caption pr-0 mr-0" v-if="!item.node.isResolved">
                                    {{ $t("dashboard.unresolved") }}
                                </div>
                                <div class="caption pr-0 mr-0" v-else>
                                    -
                                </div>
                            </template>
                            <template v-slot:item.trip="{ item }">
                                <div class="caption pr-0 mr-0">
                                    {{ item.node.trip.referenceNumber }}
                                </div>
                            </template>
                            <template v-slot:item.alertTime="{ item }">
                                <div class="caption pr-0 mr-0" v-if="item.node.alertTime">
                                    {{ formatDateTime(item.node.alertTime) }}
                                </div>
                                <div v-else>
                                    -
                                </div>
                            </template>
                            <template v-slot:item.deviceTime="{ item }">
                                <div class="caption pr-0 mr-0" v-if="item.node.deviceTime">
                                    {{ formatDateTime(item.node.deviceTime) }}
                                </div>
                                <div v-else>
                                    -
                                </div>
                            </template>
                            <template v-slot:item.alertDescription="{ item }">
                                <v-row>
                                    <v-col cols="1" v-for="(alert, i) in checkAlert(item.node.alertJson)" :key="i">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon
                                                    small
                                                    class="mr-6"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    v-if="alert === 'temperature'"
                                                >
                                                    thermostat
                                                </v-icon>
                                            </template>
                                            <span>{{ $t("general.temperature") }}</span>
                                        </v-tooltip>

                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon small v-if="alert === 'humidity'" v-bind="attrs" v-on="on">
                                                    water_drop
                                                </v-icon>
                                            </template>
                                            <span>{{ $t("general.humidity") }}</span>
                                        </v-tooltip>

                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon small v-if="alert === 'light'" v-bind="attrs" v-on="on">
                                                    lightbulb
                                                </v-icon>
                                            </template>
                                            <span>{{ $t("general.light") }}</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon small v-if="alert === 'shock'" v-bind="attrs" v-on="on">
                                                    bolt
                                                </v-icon>
                                            </template>
                                            <span>{{ $t("general.shock") }}</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon small v-if="alert === 'location'" v-bind="attrs" v-on="on">
                                                    location_on
                                                </v-icon>
                                            </template>
                                            <span>{{ $t("general.location") }}</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:item.freight="{ item }">
                                <v-row>
                                    <v-col class="caption" v-if="item.node.tripFreightTracker">
                                        {{ item.node.tripFreightTracker.tripFreight.referenceNumber }}
                                    </v-col>
                                    <div v-else>
                                        -
                                    </div>
                                </v-row>
                            </template>

                            <template v-slot:item.alerts="{ item }">
                                <v-chip small color="warning">
                                    <div class="text-truncate">
                                        {{ item.node.tenantAlertRule.name }}
                                    </div>
                                </v-chip>
                            </template>
                            <template v-slot:item.actions="{ item, index }">
                                <div>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn v-bind="attrs" v-on="on" icon @click="redirect(item.node.trip.id)">
                                                <v-icon>
                                                    open_in_new
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t("general.redirect") }}</span>
                                    </v-tooltip>
                                    <AlertRuleDetailDialog
                                        :tripAlertId="item.node.id"
                                        :alertTime="item.node.alertTime"
                                        :deviceTime="item.node.deviceTime"
                                        :dashboardTripId="item.node.trip.id"
                                        :tripId="item.node.trip.id"
                                    ></AlertRuleDetailDialog>
                                    <v-tooltip bottom v-if="me.isTenantUser">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                icon
                                                v-bind="attrs"
                                                v-on="on"
                                                :loading="loading && ind === index"
                                                v-if="!item.node.isResolved"
                                                @click="save(item.node.id, index)"
                                            >
                                                <v-icon>done</v-icon>
                                            </v-btn>
                                            <v-btn
                                                icon
                                                v-bind="attrs"
                                                v-on="on"
                                                class="grey lighten-5"
                                                color="success"
                                                outlined
                                                v-else
                                            >
                                                <v-icon>done</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t("general.resolve") }}</span>
                                    </v-tooltip>
                                </div>
                            </template>
                            <template v-slot:body.append>
                                <td :colspan="headers.length">
                                    <div class="empty-wrapper text-center mt-5">
                                        <v-btn
                                            v-if="hasMoreData"
                                            :disabled="!hasMoreData"
                                            :loading="$apollo.queries.tripAlerts.loading"
                                            plain
                                            text
                                            @click="loadMore"
                                        >
                                            {{ $t("loading.load_more") }}
                                        </v-btn>
                                    </div>
                                </td>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
//import VueApexCharts from "vue-apexcharts";
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import AlertRuleDetailDialog from "@/components/alertrules/other/AlertRuleDetailDialog.vue";
import FilterMenu from "@/components/base/FilterMenu.vue";
import ClusterMap from "@/components/base/ClusterMap.vue";

export default {
    title: "Home",
    components: {
        //apexcharts: VueApexCharts ,
        FilterMenu,
        AlertRuleDetailDialog,
        ClusterMap
    },
    apollo: {
        tripAlerts: {
            query: gql`
                query tripAlerts(
                    $isResolved: Boolean
                    $alertTimeIsnull: Boolean
                    $first: Int
                    $after: String
                    $before: String
                    $last: Int
                    $orderBy: String
                ) {
                    tripAlerts(
                        isResolved: $isResolved
                        alertTime_Isnull: $alertTimeIsnull
                        first: $first
                        after: $after
                        before: $before
                        last: $last
                        orderBy: $orderBy
                    ) {
                        pageInfo {
                            endCursor
                            startCursor
                            hasPreviousPage
                            hasNextPage
                        }
                        edges {
                            node {
                                id
                                alertTime
                                deviceTime
                                isResolved
                                alertJson
                                tripFreightTracker {
                                    id
                                    tracker {
                                        id
                                        serialNumber
                                    }
                                    tripFreight {
                                        id
                                        referenceNumber
                                    }
                                }
                                trip {
                                    id
                                    referenceNumber
                                }
                                tenantAlertRule {
                                    id
                                    name
                                    isStandardAlert
                                    standardAlertCode
                                    shouldNotifyPartner
                                    shouldNotifyForEveryRecord
                                    tenantAlertRuleActionGroupSet {
                                        edges {
                                            node {
                                                id
                                                tenantActionGroup {
                                                    id
                                                    name
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    first: this.first,
                    isResolved: false,
                    alertTimeIsnull: false,
                    orderBy: "-alertTime"
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.tripAlerts,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            skip() {
                return !this.showTable;
            },
            // debounce: 800
            // skip() {
            //     return !this.me.isStaff;
            // }
            pollInterval: 30000 // ms
        },
        tripFreightTrackers: {
            query: gql`
                query TripFreightTrackers($status: String) {
                    tripFreightTrackers(status: $status) {
                        edges {
                            node {
                                id
                                trip {
                                    id
                                    referenceNumber
                                }
                                tracker {
                                    id
                                    serialNumber
                                    lastLongitude
                                    lastLatitude
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    status: "active"
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.tripFreightTrackers,
            result({ loading, data }) {
                if (!loading) {
                    const { tripFreightTrackers } = data;
                    const tripMap = new Map();

                    tripFreightTrackers.edges.forEach(({ node }) => {
                        let {
                            trip: { id: tripId, referenceNumber },
                            tracker: { id: trackerId, lastLatitude, lastLongitude, serialNumber }
                        } = node;

                        if (lastLatitude && lastLongitude) {
                            let tripData = tripMap.get(tripId);

                            if (!tripData) {
                                tripData = {
                                    trip: {
                                        id: tripId,
                                        referenceNumber: referenceNumber,
                                        tracker: []
                                    },
                                    points: [],
                                    trackerIds: new Set()
                                };
                                tripMap.set(tripId, tripData);
                            }

                            if (!tripData.trackerIds.has(trackerId)) {
                                tripData.trackerIds.add(trackerId);
                                tripData.points.push([lastLatitude, lastLongitude]);
                                tripData.trip.tracker.push({
                                    id: trackerId,
                                    serialNumber: serialNumber
                                });
                            }
                        }
                    });

                    this.trackersLocations = Array.from(tripMap.values()).map(({ trip, points }) => ({ trip, points }));
                }
            },
            skip() {
                return !this.showMap;
            }
        },
        tenantDashboards: {
            query: gql`
                query TenantDashboards {
                    tenantDashboards {
                        edges {
                            node {
                                id
                                tenant {
                                    id
                                }
                                value
                                dashboardCode
                                lastRefreshedAt
                            }
                        }
                    }
                }
            `,
            variables() {},
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.tenantDashboards,
            // debounce: 800,
            skip() {
                return false;
            },
            pollInterval: 6000 // ms
        }
    },
    data() {
        return {
            first: 20,
            ind: 0,
            tenantDashboards: {},
            detailDialog: false,
            editAlertsDialog: false,
            loading: false,
            alertRule: {},
            tripAlerts: {
                pageInfo: {
                    endCursor: null,
                    startCursor: null,
                    hasPreviousPage: false,
                    hasNextPage: false
                },
                edges: []
            },
            trackersLocations: [],
            toggleButtton: "month",
            overview: {
                monthOption: {
                    chart: {
                        id: "month-bar",
                        zoom: {
                            enabled: false
                        }
                    },
                    xaxis: {
                        categories: ["Jan", "Feb", "Mar", "Apr"]
                    },
                    /* stroke: {
                        curve: "smooth"
                    }, */
                    colors: ["#00ACC1", "#00ACC1"]
                },
                weekOption: {
                    chart: {
                        id: "week-bar"
                    },
                    xaxis: {
                        categories: ["week 1", "week 2", "week 3", "week 4"]
                    }
                    /* stroke: {
                        curve: "smooth"
                    }  */
                },
                monthSalesPerformance: [
                    {
                        name: "series-1",
                        data: [1, 2, 4, 5]
                    }
                ],
                weekSalesPerformance: [
                    {
                        name: "series-2",
                        data: [6, 3, 6, 8]
                    }
                ]
            },
            performance: {
                monthOption: {
                    chart: {
                        id: "month-bar"
                    },
                    xaxis: {
                        categories: []
                    },
                    colors: ["#00ACC1", "#00ACC1"]
                },
                monthPerformance: [
                    {
                        name: "series-3",
                        data: []
                    }
                ]
            },
            // showMap: true,
            // showTable: true
        };
    },
    computed: {
        screenSize() {
            let _resposiveView = this.$vuetify.breakpoint.width;
            if (_resposiveView < 650) {
                return "xs";
            } else if (_resposiveView < 1000) {
                return "sm";
            } else if (_resposiveView < 1400) {
                return "md";
            } else if (_resposiveView < 1900) {
                return "lg";
            } else {
                return "xl";
            }
        },
        me() {
            return helper.me();
        },
        hasMoreData() {
            return this.tripAlerts?.pageInfo?.hasNextPage;
        },
        isShowPreview() {
            let localShowPreview = this.$store.getters["user/showPreview"];
            if (localShowPreview === null) {
                return false;
            }

            if (localShowPreview === "true") return true;
            if (localShowPreview === "false") return false;
            else return localShowPreview;
        },
        dashboardKPIs() {
            return this.prepareDashboardKPIs();
        },
        filterChoices: {
            get() {
                let _filterChoices = helper.getTableHeaders("dashboardKpIs", this.dashboardKPIs);
                _filterChoices = _filterChoices.filter(
                    kpi => !(kpi.name === "Available Trackers" && this.me.isPartnerUser && !this.me.isTenantUser)
                );
                
                return _filterChoices;
            },
            set(value) {
                helper.updateMyPreferences({
                    dashboardKpIs: value
                });
            }
        },
        dynamicChoices() {
            let _choices = [];

            this.filterChoices.forEach(choice => {
                this.dashboardKPIs.find(kpi => {
                    // if (kpi.code != "TABLE" && kpi.code != "ACTIVE_TRIP_MAP") {
                        if (kpi.code === choice.code && choice.enabled) {
                            _choices.push(kpi);
                        }
                    // }
                });
            });
            
            _choices = _choices.filter(
                kpi => !(kpi.name === "Available Trackers" && this.me.isPartnerUser && !this.me.isTenantUser)
            );
            
            return _choices;
        },
        showTable() {
            return this.dynamicChoices.find(kpi => kpi.tag === 'dashboard.table');
        },
        showMap() {
            return this.dynamicChoices.find(kpi => kpi.tag === 'dashboard.active_trips_map');
        },
        // showTable() {
        //     let _showTable = false;
        //     this.filterChoices.forEach(choice => {
        //         if (choice.code === "TABLE" && choice.enabled && this.me.isTenantUser) {
        //             _showTable = true;
        //         }
        //     });
        //     return _showTable;
        // },
        // showMap() {
        //     let _show = false;
        //     this.filterChoices.forEach(choice => {
        //         if (choice.code === "ACTIVE_TRIP_MAP" && choice.enabled && this.me.isTenantUser) {
        //             _show = true;
        //         }
        //     });
        //     return true;
        // },
        headers: function() {
            let _headers = [
                {
                    text: this.$t("headers.trip"),
                    sortable: false,
                    align: "left",
                    value: "trip",
                    isSelected: false
                },
                {
                    text: this.$t("headers.alert"),
                    sortable: false,
                    value: "alerts",
                    isSelected: false
                },
                {
                    text: this.$t("headers.status"),
                    sortable: false,
                    align: "left",
                    value: "status",
                    isSelected: false
                },
                {
                    text: this.$t("headers.trigger_time"),
                    sortable: false,
                    align: "left",
                    value: "alertTime",
                    isSelected: false
                },
                {
                    text: this.$t("headers.device_time"),
                    sortable: false,
                    align: "left",
                    value: "deviceTime",
                    isSelected: false
                },
                {
                    text: this.$t("headers.alert_trigger"),
                    align: "left",
                    value: "alertDescription",
                    sortable: false
                },
                {
                    text: this.$t("headers.freight"),
                    sortable: false,
                    align: "left",
                    value: "freight",
                    isSelected: false
                },
                {
                    text: this.$t("headers.tracker"),
                    sortable: false,
                    align: "left",
                    value: "tracker",
                    isSelected: false
                },
                {
                    text: this.$t("headers.action"),
                    sortable: false,
                    align: "left",
                    value: "actions",
                    isSelected: false
                }
            ];
            return _headers;
        }
    },
    created() {
        this.formatDateTime = helper.formatDateTime;
        this.hasPermission = helper.hasPermission;
        this.getTemperatureUnit = helper.getTemperatureSymbol;
        this.celsiusToFahrenheit = helper.celsiusToFahrenheit;
    },
    watch: {
        isShowPreview: {
            handler: function(val) {
                this.prepareDashboardKPIs();
            }
        }
    },
    mounted() {},
    methods: {
        prepareDashboardKPIs() {
            let _kpi = [];

            let _tables = this.tenantDashboards;

            const _tripsActive = this.tenantDashboards?.edges?.find(e => e.node.dashboardCode === "TOTAL_OPEN_TRIPS");

            _kpi.push({
                name: "Table",
                tag: "dashboard.table",
                enabled: false,
                code: "TABLE"
            });

            _kpi.push({
                name: "Active Trips",
                tag: "dashboard.active_trips_map",
                enabled: false,
                code: "ACTIVE_TRIP_MAP"
            });

            let openTripsSum = 0;
            let seaEtdDelaySum = 0;
            let seaTransWaitSum = 0;
            let seaEtaDealySum = 0;
            let seaDestWaitSum = 0;
            let unresolvedAlertsSum = 0;
            let availableTrackersSum = 0;
            let unresolvedTagTemperatureSum = 0;
            let unresolvedTagLightSum = 0;
            let unresolvedTagShockSum = 0;
            let unresolvedTagHumiditySum = 0;
            let unresolvedTagPressureSum = 0;
            let unresolvedTagTemperatureHumiditySum = 0;
            let unresolvedTagTemperatureHumidityLightSum = 0;

            let _tripLink = this.me.isPartnerUser && !this.me.isTenantUser ? "sharedtrips" : "/trips";
            if (this.isShowPreview === true) {
                _tripLink = this.me.isPartnerUser && !this.me.isTenantUser ? "previewsharedtrips" : "/previewtrips";
            }

            if (this.tenantDashboards && this.tenantDashboards.edges) {
                for (let i = 0; i < this.tenantDashboards.edges.length; i++) {
                    const edge = this.tenantDashboards.edges[i];
                    const node = edge.node;
                    const dashboardCode = node.dashboardCode;

                    switch (dashboardCode) {
                        case "TOTAL_OPEN_TRIPS":
                            openTripsSum += node.value || 0;
                            break;
                        case "SEA_ETD_DELAY":
                            seaEtdDelaySum += node.value || 0;
                            break;
                        case "SEA_TRANS_WAIT":
                            seaTransWaitSum += node.value || 0;
                            break;
                        case "SEA_ETA_DELAY":
                            seaEtaDealySum += node.value || 0;
                            break;
                        case "SEA_DEST_WAIT":
                            seaDestWaitSum += node.value || 0;
                            break;
                        case "UNRESOLVED_ALERTS":
                            unresolvedAlertsSum += node.value || 0;
                            break;
                        case "AVAILABLE_TRACKERS":
                            availableTrackersSum += node.value || 0;
                            break;
                        case "UNRESOLVED_TAG_TEMPERATURE":
                            unresolvedTagTemperatureSum += node.value || 0;
                            break;
                        case "UNRESOLVED_TAG_LIGHT":
                            unresolvedTagLightSum += node.value || 0;
                            break;
                        case "UNRESOLVED_TAG_SHOCK":
                            unresolvedTagShockSum += node.value || 0;
                            break;
                        case "UNRESOLVED_TAG_HUMIDITY":
                            unresolvedTagHumiditySum += node.value || 0;
                            break;
                        case "UNRESOLVED_TAG_PRESSURE":
                            unresolvedTagPressureSum += node.value || 0;
                            break;
                        case "UNRESOLVED_TAG_TEMPERATURE_HUMIDITY":
                            unresolvedTagTemperatureHumiditySum += node.value || 0;
                            break;
                        case "UNRESOLVED_TAG_TEMPERATURE_HUMIDITY_LIGHT":
                            unresolvedTagTemperatureHumidityLightSum += node.value || 0;
                            break;
                    }
                }
            }

            _kpi.push({
                name: "Active Trips",
                tag: "dashboard.active_trips",
                enabled: true,
                code: "TOTAL_OPEN_TRIPS",
                value: openTripsSum,
                lastRefreshedAt: _tripsActive?.node?.lastRefreshedAt,
                icon: "near_me",
                color: "secondary",
                link: _tripLink
            });

            _kpi.push({
                name: "ETD Delays",
                tag: "dashboard.etd_delays",
                enabled: false,
                code: "SEA_ETD_DELAY",
                value: seaEtdDelaySum,
                lastRefreshedAt: _tripsActive?.node?.lastRefreshedAt,
                icon: "hourglass_top",
                color: "secondary",
                link: _tripLink
            });

            _kpi.push({
                name: "T/S Waitings",
                tag: "dashboard.ts_waitings",
                enabled: false,
                code: "SEA_TRANS_WAIT",
                value: seaTransWaitSum,
                lastRefreshedAt: _tripsActive?.node?.lastRefreshedAt,
                icon: "multiple_stop",
                color: "secondary",
                link: _tripLink
            });

            _kpi.push({
                name: "ETA Delays",
                tag: "dashboard.eta_delays",
                enabled: false,
                code: "SEA_ETA_DELAY",
                value: seaEtaDealySum,
                lastRefreshedAt: _tripsActive?.node?.lastRefreshedAt,
                icon: "schedule",
                color: "secondary",
                link: _tripLink
            });

            _kpi.push({
                name: "POD Waitings",
                tag: "dashboard.pod_waitings",
                enabled: false,
                code: "SEA_DEST_WAIT",
                value: seaDestWaitSum,
                lastRefreshedAt: _tripsActive?.node?.lastRefreshedAt,
                icon: "sports_score",
                color: "secondary",
                link: _tripLink
            });

            _kpi.push({
                name: "Unresolved Alerts",
                tag: "dashboard.unresolved_alerts",
                enabled: true,
                code: "UNRESOLVED_ALERTS",
                value: unresolvedAlertsSum,
                lastRefreshedAt: _tripsActive?.node?.lastRefreshedAt,
                icon: "notifications",
                color: "secondary",
                link: _tripLink
            });

            _kpi.push({
                name: "Available Trackers",
                tag: "dashboard.available_trackers",
                enabled: true,
                code: "AVAILABLE_TRACKERS",
                value: availableTrackersSum,
                lastRefreshedAt: _tripsActive?.node?.lastRefreshedAt,
                icon: "settings_remote",
                color: "secondary",
                link: !this.isShowPreview ? "/trackers" : "/previewtrackers"
            });

            _kpi.push({
                name: "Unresolved Tag Temperature",
                tag: "dashboard.unresolved_tag_temperature",
                enabled: false,
                code: "UNRESOLVED_TAG_TEMPERATURE",
                value: unresolvedTagTemperatureSum,
                lastRefreshedAt: _tripsActive?.node?.lastRefreshedAt,
                icon: "thermostat",
                color: "secondary",
                link: _tripLink
            });

            _kpi.push({
                name: "Unresolved Tag Humidity",
                tag: "dashboard.unresolved_tag_humidity",
                enabled: false,
                code: "UNRESOLVED_TAG_HUMIDITY",
                value: unresolvedTagHumiditySum,
                lastRefreshedAt: _tripsActive?.node?.lastRefreshedAt,
                icon: "water_drop",
                color: "secondary",
                link: _tripLink
            });

            _kpi.push({
                name: "Unresolved Tag light",
                tag: "dashboard.unresolved_tag_light",
                enabled: false,
                code: "UNRESOLVED_TAG_LIGHT",
                value: unresolvedTagLightSum,
                lastRefreshedAt: _tripsActive?.node?.lastRefreshedAt,
                icon: "lightbulb",
                color: "secondary",
                link: _tripLink
            });

            _kpi.push({
                name: "Unresolved Tag Shock",
                tag: "dashboard.unresolved_tag_shock",
                enabled: false,
                code: "UNRESOLVED_TAG_SHOCK",
                value: unresolvedTagShockSum,
                lastRefreshedAt: _tripsActive?.node?.lastRefreshedAt,
                icon: "bolt",
                color: "secondary",
                link: _tripLink
            });

            _kpi.push({
                name: "Unresolved Tag Pressure",
                tag: "dashboard.unresolved_tag_pressure",
                enabled: false,
                code: "UNRESOLVED_TAG_PRESSURE",
                value: unresolvedTagPressureSum,
                lastRefreshedAt: _tripsActive?.node?.lastRefreshedAt,
                icon: "compress",
                color: "secondary",
                link: _tripLink
            });

            _kpi.push({
                name: "Unresolved Tag Temperature Humidity",
                tag: "dashboard.unresolved_tag_temperature_humidity",
                enabled: false,
                code: "UNRESOLVED_TAG_TEMPERATURE_HUMIDITY",
                value: unresolvedTagTemperatureHumiditySum,
                lastRefreshedAt: _tripsActive?.node?.lastRefreshedAt,
                icon: "thermostat",
                color: "secondary",
                link: _tripLink
            });

            _kpi.push({
                name: "Unresolved Tag Temperature Humidity Light",
                tag: "dashboard.unresolved_tag_temperature_humidity_light",
                enabled: false,
                code: "UNRESOLVED_TAG_TEMPERATURE_HUMIDITY_LIGHT",
                value: unresolvedTagTemperatureHumidityLightSum,
                lastRefreshedAt: _tripsActive?.node?.lastRefreshedAt,
                icon: "thermostat",
                color: "secondary",
                link: _tripLink
            });
            return _kpi;
        },
        onFilterMenuChanged(newChoices) {
            this.filterChoices = newChoices.map(choice => choice);
        },
        redirect(id) {
            if (this.isShowPreview === true) {
                let redirectRoute = this.me.isPartnerUser && !this.me.isTenantUser ? "/previewsharedtrips" : "/previewtrips";
                this.$router.push({
                    path: redirectRoute,
                    query: { id: id, tab: "alerts" }
                });
            } else {
                let redirectRoute = this.me.isPartnerUser && !this.me.isTenantUser ? "/sharedtrips" : "/trips";
                this.$router.push({
                    path: redirectRoute,
                    query: { id: id, tab: "alerts" }
                });
            }
        },
        checkAlert(item) {
            let alert = [];
            let newObj = JSON.parse(item);

            if (newObj?.Location?.Condition) alert.push("location");

            if (newObj?.Sensor?.length > 0) {
                newObj.Sensor.forEach(item => {
                    alert.push(item.Type);
                });
            }

            return alert;
        },
        onLoadMoreTriggerIntersect(entries) {
            this.shouldLoadMore = entries[0].isIntersecting;

            if (this.shouldLoadMore && this.tripAlerts?.pageInfo?.hasNextPage) {
                this.loadMore();
            }
        },
        loadMore() {
            if (this.tripAlerts?.pageInfo?.hasNextPage) {
                this.$apollo.queries.tripAlerts.fetchMore({
                    variables: {
                        after: this.tripAlerts.pageInfo.endCursor,
                        isResolved: false
                    }
                });
            }
        },
        alertRuleDetail(item) {
            this.alertRule = { ...item };
            this.detailDialog = true;
        },
        travelTo(obj) {
            this.$router.push({
                path: obj.link,
                query: { code: obj.code }
            });
        },
        save(id, index) {
            this.ind = index;
            this.loading = true;

            let payload = {
                alertId: id
            };

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation UpdateTripAlerts($input: UpdateTripAlertsInput!) {
                            updateTripAlerts(input: $input) {
                                trip {
                                    id
                                    status
                                    tripAlertSet {
                                        edges {
                                            node {
                                                id
                                                deviceTime
                                                alertTime
                                                resolvedTime
                                                isResolved
                                                alertJson
                                                note
                                                resolvedBy {
                                                    id
                                                    fullName
                                                }
                                                tenantAlertRule {
                                                    id
                                                    name
                                                    isStandardAlert
                                                }
                                                trackerMergedSensorData {
                                                    id
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        input: { ...payload }
                    }
                })
                .then(data => {
                    const payload = {
                        color: "success",
                        message: this.$t("general.alert_resolved")
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                    this.$apollo.queries.tripAlerts.refetch();
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                    this.editAlertsDialog = false;
                });
        }
    }
};
</script>

<style scoped>
.no-wrap {
    white-space: nowrap;
}

.scrollable {
    overflow-x: auto;
}
</style>

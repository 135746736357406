<template>
    <v-container class="pb-0 ">
        <v-card flat class="mb-0 pb-0 grey lighten-4" outlined>
            <!-- card title (air) -->
            <v-card-title class="py-3">
                <div class="mr-2" v-if="!createMode">
                    <v-icon>group</v-icon>
                </div>
                <span v-if="!createMode">{{ $t("alert_rules.action") }}</span>
                <v-spacer></v-spacer>
                <v-btn color="primary" rounded class="mr-3" @click="openSelectActionGroupDialog = true"
                    ><v-icon class="mr-1">check_box</v-icon>
                    {{ $t("alert_rules.select") }}
                </v-btn>
                <v-btn color="primary" rounded @click="openCreateActionGroupDialog = true"
                    ><v-icon class="mr-1">add_circle</v-icon>
                    {{ $t("general.create") }}
                </v-btn>
            </v-card-title>
            <v-card-text class="grey lighten-4">
                <v-card flat class="grey lighten-4">
                    <v-card-text>
                        <v-data-table
                            :items="selectedActionGroups"
                            :headers="headerChoices"
                            disable-pagination
                            :loading-text="$t('loading.loading1')"
                            loader-height="2"
                            hide-default-footer
                            :no-data-text="$t('general.no_action_group')"
                            class="grey lighten-4"
                            :loading="$apollo.queries.tenantActionGroups.loading"
                        >
                            <!-- Email Notification -->
                            <template
                                v-slot:item.node.shouldEmailNotification="{
                                    item
                                }"
                            >
                                <v-icon v-if="item.node.shouldEmailNotification" color="success">check_circle</v-icon>
                                <v-icon color="grey lighten-2" v-else>check_circle</v-icon>
                            </template>

                            <!-- Inbox Notification -->
                            <template
                                v-slot:item.node.shouldInboxNotification="{
                                    item
                                }"
                            >
                                <v-icon v-if="item.node.shouldInboxNotification" color="success">check_circle</v-icon>
                                <v-icon color="grey lighten-2" v-else>check_circle</v-icon>
                            </template>

                            <!-- TotalCount of Users -->
                            <template v-slot:item.node.users="{ item }">
                                <span>{{ item.node.tenantActionGroupUserSet.totalCount }}</span>
                            </template>

                            <!-- TotalCount of External Emails -->
                            <template
                                v-slot:item.node.externalEmails="{
                                    item
                                }"
                            >
                                <span>{{ calculateNumberOfEmails(item.node) }}</span>
                            </template>

                            <!-- REMOVE -->
                            <template v-slot:item.actions="{ item }">
                                <div class="d-flex">
                                    <v-icon @click="editActionGroup(item)" class="mr-3">edit</v-icon>
                                    <v-icon @click="removeActionGroup(item)">delete</v-icon>
                                </div>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-card-text> </v-card
        ><v-row class="my-3">
            <v-col cols="12">
                <h4>
                    {{ $t("alert_rules.notify") }}
                </h4>
            </v-col>
            <v-col cols="auto" class="py-0">
                <v-checkbox
                    v-model="valueNotifyTripPartners.shouldNotifyCarrier"
                    :label="$t('headers.carrier')"
                    color="primary"
                    hide-details
                    @change="inputChanged($event, 'shouldNotifyCarrier')"
                ></v-checkbox>
            </v-col>
            <v-col cols="auto" class="py-0">
                <v-checkbox
                    v-model="valueNotifyTripPartners.shouldNotifyConsignor"
                    :label="$t('headers.consignor')"
                    color="primary"
                    hide-details
                    @change="inputChanged($event, 'shouldNotifyConsignor')"
                ></v-checkbox>
            </v-col>
            <v-col cols="auto" class="py-0">
                <v-checkbox
                    v-model="valueNotifyTripPartners.shouldNotifyConsignee"
                    :label="$t('headers.consignee')"
                    color="primary"
                    hide-details
                    @change="inputChanged($event, 'shouldNotifyConsignee')"
                ></v-checkbox>
            </v-col>
            <v-col cols="auto" class="py-0">
                <v-checkbox
                    v-model="valueNotifyTripPartners.shouldNotifyForwarder"
                    :label="$t('headers.forwarder')"
                    color="primary"
                    hide-details
                    @change="inputChanged($event, 'shouldNotifyForwarder')"
                ></v-checkbox>
            </v-col>
            <v-col cols="auto" class="py-0">
                <v-checkbox
                    v-model="valueNotifyTripPartners.shouldNotifyOther"
                    :label="$t('home.other')"
                    color="primary"
                    hide-details
                    @change="inputChanged($event, 'shouldNotifyOther')"
                ></v-checkbox>
            </v-col>
        </v-row>

        <AddAlertRuleActionGroupDialog
            :openAddActionGroupDialog="openSelectActionGroupDialog"
            :actionGroups.sync="selectedActionGroups"
            v-on:closeAddActionGroupDialog="openSelectActionGroupDialog = false"
            v-on:updateActionGroups="updateActionGroups"
        ></AddAlertRuleActionGroupDialog>

        <AddActionGroupDialog
            ref="createGroupDialog"
            :openAddActionGroupDialog="openCreateActionGroupDialog"
            v-on:closeAddGroupDialog="openCreateActionGroupDialog = false"
            v-on:item-created="onActionGroupCreated"
            v-on:item-updated="updateGroupValues"
        ></AddActionGroupDialog>

        <!---- EDIT PARTNER DIALOG ---->
        <EditActionGroupDialog
            ref="editGroupDialog"
            :showGroupSelect="true"
            :openEditGroupDialog="openEditActionGroupDialog"
            v-on:updatedGroup="updateGroupValues"
            v-on:closeEditGroupDialog="openEditActionGroupDialog = false"
            :actionGroup="actionGroupToEdit"
        ></EditActionGroupDialog>
    </v-container>
</template>

<script>
import helper from "@/utils/helper.js";
import gql from "graphql-tag";
import AddAlertRuleActionGroupDialog from "@/components/alertrules/create/AddAlertRuleActionGroupDialog.vue";
import AddActionGroupDialog from "@/components/actiongroups/create/AddActionGroupDialog.vue";
import EditActionGroupDialog from "@/components/actiongroups/update/EditActionGroupDialog.vue";

export default {
    components: {
        AddAlertRuleActionGroupDialog,
        AddActionGroupDialog,
        EditActionGroupDialog
    },
    props: {
        actionGroups: {
            type: Array,
            default: () => []
        },
        notifyTripPartners: {
            type: Object
        },
        createMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            openCreateActionGroupDialog: false,
            openSelectActionGroupDialog: false,
            openEditActionGroupDialog: false,
            actionGroupToEdit: null,
            tenantActionGroups: { edges: [] },
            valueNotifyTripPartners: {
                shouldNotifyCarrier: false,
                shouldNotifyConsignor: false,
                shouldNotifyConsignee: false,
                shouldNotifyForwarder: false,
                shouldNotifyOther: false
            }
            // headerChoices: [
            //     {
            //         text: this.$("general.name"),
            //         align: "left",
            //         value: "node.name",
            //         sortable: false
            //     },
            //     {
            //         text: this.$("general.users"),
            //         align: "right",
            //         value: "node.users"
            //     },
            //     {
            //         text: this.$("headers.external_emails"),
            //         align: "right",
            //         value: "node.externalEmails"
            //     },
            //     {
            //         text: this.$("headers.email_notification"),
            //         align: "center",
            //         value: "node.shouldEmailNotification"
            //     },
            //     {
            //         text: this.$("headers.inbox_notification"),
            //         align: "center",
            //         value: "node.shouldInboxNotification"
            //     },
            //     {
            //         text: "",
            //         align: "center",
            //         value: "actions",
            //         width: "5px",
            //         sortable: false
            //     }
            // ]
        };
    },
    beforeCreate() {
        this.hasPermission = helper.hasPermission;
    },
    apollo: {
        tenantActionGroups: {
            query: gql`
                query TenantActionGroups($tenantId: ID) {
                    tenantActionGroups(tenantId: $tenantId) {
                        edges {
                            node {
                                id
                                tenantActionGroupUserSet {
                                    totalCount
                                    edges {
                                        node {
                                            user {
                                                email
                                                firstName
                                                id
                                                fullName
                                            }
                                        }
                                    }
                                }
                                name
                                userEmails
                                externalEmails
                                shouldEmailNotification
                                shouldInboxNotification
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    tenantId: this.me?.tenant?.id
                };
            },
            fetchPolicy: "network-only",
            result({ data }) {
                this.tenantActionGroups = data.tenantActionGroups;
            },
            skip: false
        }
    },
    computed: {
        me() {
            return helper.me();
        },
        /*         valueNotifyTripPartners: {
            get() {
                return this.notifyTripPartners;
            },
            set(value) {
                this.$emit("updateNotifyTripPartners", value);
            }
        }, */
        valueActionGroups: {
            get() {
                return this.actionGroups;
            },
            set(value) {
                this.$emit("updateActionGroups", value);
            }
        },
        selectedActionGroups() {
            return this.tenantActionGroups.edges.filter(edge => {
                return this.valueActionGroups.find(actionGroup => {
                    return actionGroup === edge.node.id;
                });
            });
        },
        headerChoices: function() {
            let _headerChoices = [
                {
                    text: this.$t("headers.name"),
                    align: "left",
                    value: "node.name",
                    sortable: false
                },
                {
                    text: this.$t("home.users"),
                    align: "right",
                    value: "node.users"
                },
                {
                    text: this.$t("headers.external_emails"),
                    align: "right",
                    value: "node.externalEmails"
                },
                {
                    text: this.$t("headers.email_notification"),
                    align: "center",
                    value: "node.shouldEmailNotification"
                },
                {
                    text: this.$t("headers.inbox_notification"),
                    align: "center",
                    value: "node.shouldInboxNotification"
                },
                {
                    text: "",
                    align: "center",
                    value: "actions",
                    width: "5px",
                    sortable: false
                }
            ];
            return _headerChoices;
        }
    },
    watch: {
        notifyTripPartners: {
            handler: function(val) {
                this.valueNotifyTripPartners = val ;
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        //reset the action groups
        resetActionGroups() {
            this.valueActionGroups = [];
        },
        calculateNumberOfEmails(actionGroup) {
            if (!actionGroup.externalEmails) return 0;
            else if (actionGroup.externalEmails === "[]") return 0;
            else return actionGroup.externalEmails.split(",").length;
        },
        removeActionGroup(item) {
            this.valueActionGroups = this.valueActionGroups.filter(actionGroup => {
                return actionGroup !== item.node.id;
            });
        },
        editActionGroup(item) {
            //this.actionGroupToEdit = item.node;
            this.$refs.createGroupDialog.editGroup(item.node);
            this.openCreateActionGroupDialog = true;
        },
        onActionGroupCreated(actionGroup) {
            this.$apollo.queries.tenantActionGroups.refetch();
            let actionGroups = this.valueActionGroups;
            actionGroups.push(actionGroup.id);
            this.valueActionGroups = actionGroups;
        },
        updateActionGroups(actionGroups) {
            this.valueActionGroups = actionGroups;
        },
        updateGroupValues() {
            this.openEditActionGroupDialog = false;
            this.$apollo.queries.tenantActionGroups.refetch();
        },
        inputChanged(event, key) {
            this.valueNotifyTripPartners[key] = event;
            this.$emit("updateNotifyTripPartners", this.valueNotifyTripPartners);
        }
    }
};
</script>

<template>
    <v-sheet class="mx-auto background" max-width="100%" min-height="100%">
        <v-row dense class="pl-5 pr-3" align="stretch">
            <v-col cols="12">
                <OverviewTemplate :tripTemplate="template" />
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import OverviewTemplate from "@/components/triptemplates/tab/OverviewTemplate.vue";
export default {
    props: {
        template: {
            type: Object,
            required: true
        }
    },
    components: {
        OverviewTemplate
    },
    data() {
        return {};
    },
    computed: {},
    watch: {},
    mounted() {},
    created() {},
    methods: {}
};
</script>
<style scoped>
.v-select.fit {
    max-width: min-content;
    padding: 10px;
}
.v-select.fit .v-select__selection--comma {
    text-overflow: unset;
    padding-right: 1rem;
}

.v-select.fit .v-select__selections input {
    display: none;
}
.textTitle {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
}
.textBody {
    font-size: 12px !important;
    font-weight: 400 !important;
    color: "#868599" !important;
}
</style>

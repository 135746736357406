<template>
    <div :key="template.id">
        <!-- tabs -->
        <v-tabs v-model="tab" color="primary" show-arrows ref="tabs" background-color="transparent">
            <v-tab v-for="t in selectedFilters" :key="t.code" :href="`#tab-${t.code}`">
                <template v-slot:default>
                    <div class="text-capitalize">
                        {{ t.name }}
                    </div>
                </template>
            </v-tab>
        </v-tabs>
        <v-divider> </v-divider>
        <!-- tab content -->
        <v-tabs-items v-model="tab" :style="`height: ${heightScreen}px;`" class="overflow-y-auto background">
            <v-tab-item value="tab-map" :transition="false" active-class="maxHeight">
                <v-container fluid class="px-0">
                    <TabDetails :template="template"> </TabDetails>
                </v-container>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import TabDetails from "@/components/triptemplates/tab/TabDetails.vue";

export default {
    props: {
        template: {
            type: Object,
            required: true
        },
        selectedFilters: {
            type: Array,
            required: true
        },
        title: {
            type: String,
            default: "templates"
        }
    },
    components: {
        TabDetails
    },
    data() {
        return {
            tab: null,
            editTemplate: false
        };
    },
    computed: {
        heightScreen() {
            let _break = this.$vuetify.breakpoint.height - 225;
            return _break;
        }
    },
    watch: {
        selectedFilters() {
            this.$refs.tabs.callSlider();
        }
    },
    mounted() {},
    methods: {
        refreshDataTable() {
            this.$emit("refreshDataTable");
        },
        editMethod() {
            this.editTemplate = true;
        }
    }
};
</script>
<style scoped>
.maxHeight {
    max-height: 1200px;
}
</style>

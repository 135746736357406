<template>
    <v-card outlined rouned-lg>
        <v-card-title>
            <v-row dense>
                <v-card v-if="!isMobile" width="220" color="transparent" flat></v-card>
                <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3" class="d-flex flex-row">
                    <div>
                        <v-icon class="mr-2" size="24" :color="'#0e0d35'">{{ title.icon }}</v-icon>
                        <span class="title">{{ $t(title.header) }}</span>
                    </div>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text>
            <v-tabs :vertical="!isMobile" v-model="verticalTab">
                <v-tab
                    v-for="(personalTab, i) in personalTabs"
                    :key="i"
                    class="d-flex justify-start"
                    :value="personalTab.value"
                >
                    <div class="tabsHeader text-capitalize ">
                        {{ $t(personalTab.name) }}
                    </div>
                </v-tab>
                <v-tab-item :value="verticalTab">
                    <v-row>
                        <v-col cols="12" v-if="verticalTab === 0">
                            <ProfileForm :tenant="tenant" @loading="onLoading"></ProfileForm>
                        </v-col>
                        <v-col cols="12" v-if="verticalTab === 1">
                            <PreferencesForm :tenant="tenant"></PreferencesForm>
                            <NotificationForm :user="me" @loading="onLoading"></NotificationForm>
                        </v-col>
                        <v-col cols="12" v-if="verticalTab === 2">
                            <PreviewPasswordForm :tenant="tenant" @loading="onLoading"></PreviewPasswordForm>
                        </v-col>
                    </v-row>
                </v-tab-item>
            </v-tabs>
        </v-card-text>
    </v-card>
</template>

<script>
import ProfileForm from "@/components/userSettings/forms/ProfileForm.vue";
import PreferencesForm from "@/components/userSettings/forms/PreferencesForm.vue";
import PreviewPasswordForm from "@/components/userSettings/forms/PreviewPasswordForm.vue";
import NotificationForm from "@/components/userSettings/forms/NotificationForm.vue";
import helper from "@/utils/helper";

export default {
    title: "Profile Settings",
    components: {
        ProfileForm,
        PreferencesForm,
        PreviewPasswordForm,
        NotificationForm
    },
    props: {
        tenant: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            verticalTab: 0,
            personalTabs: [
                { name: "general.general_info", value: "general" },
                { name: "general.preferences", value: "preferences" },
                { name: "general.security", value: "security" }
            ]
        };
    },
    computed: {
        me() {
            return helper.me();
        },
        isMobile() {
            return this.$vuetify.breakpoint.smAndDown;
        },
        title() {
            let title = {
                header: "general.general_info",
                icon: "manage_accounts"
            };

            if (this.verticalTab === 0) {
                title = {
                    header: "general.general_info",
                    icon: "manage_accounts"
                };
            }
            if (this.verticalTab === 1) {
                title = {
                    header: "general.preferences",
                    icon: "tune"
                };
            }
            if (this.verticalTab === 2) {
                title = {
                    header: "general.security",
                    icon: "verified_user"
                };
            }
            return title;
        }
    },
    methods: {
        onLoading(val) {
            this.isLoading = val;
        }
    }
};
</script>

<style scoped>
.max-width-settings-details {
    max-width: 800px;
    margin: 0 auto;
}

.tabsHeader {
    font-size: 16px;
    font-weight: 700;
    line-height: 19.2px;
    text-align: left;
}

.textHeader {
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    width: 291px;
    height: 58px;
    top: 141px;
    left: 376px;
}
.title {
    color: #0e0d35 !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 21.6px !important;
    text-align: left !important;
}
.warningMessage {
    color: #868599;
    font-size: 10px;
    font-weight: 700;
    line-height: 12px;
    text-align: left;
}
</style>

<template>
    <v-container fluid class="pa-0">
        <v-dialog v-model="requestDialog" max-width="700" persistent scrollable>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-if="editMode"
                    rounded
                    icon
                    v-bind="attrs"
                    v-on="on"
                    :disabled="!me.isTenantUser"
                    @click="enableEditObj"
                >
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on">edit</v-icon>
                        </template>
                        <span>Edit Change Request</span>
                    </v-tooltip>
                </v-btn>
                <v-btn
                    v-else
                    rounded
                    color="primary"
                    class=""
                    v-bind="attrs"
                    v-on="on"
                    :disabled="me.isTenantUser || booking.orderStatus === 'draft'"
                >
                    {{ "Change request" }}
                </v-btn>
            </template>
            <v-card flat>
                <v-card-title class="primary white--text">
                    {{ editMode ? "Edit" : "Submit" }} Change Request
                </v-card-title>
                <v-card-text>
                    <v-row v-if="obj" class="mt-6 pt-4 px-8">
                        <v-col cols="12" class="py-0">
                            <v-row dense>
                                <v-col cols="auto" class="text">
                                    <span>
                                        Title
                                    </span>
                                </v-col>
                            </v-row>
                            <v-text-field
                                v-model="obj.title"
                                outlined
                                dense
                                placeholder="Title"
                                :disabled="isLogco"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="py-0">
                            <v-row dense>
                                <v-col cols="auto" class="text">
                                    <span>
                                        Request
                                    </span>
                                </v-col>
                            </v-row>
                            <v-textarea
                                v-model="obj.content"
                                placeholder="Write your request here..."
                                outlined
                                dense
                                no-resize
                                :disabled="isLogco"
                            >
                            </v-textarea>
                        </v-col>
                        <v-col
                            v-if="me.isTenantUser && editMode"
                            cols="4"
                            class="py-0"
                        >
                            <v-row dense>
                                <v-col cols="auto" class="text">
                                    <span>
                                        Status
                                    </span>
                                </v-col>
                            </v-row>
                            <v-select
                                v-model="obj.status"
                                :items="changeRequestStatus"
                                item-text="text"
                                item-value="value"
                                outlined
                                dense
                                no-resize
                            >
                            </v-select>
                        </v-col>
                        <v-col cols="12" class="py-0" v-if="isLogco && editMode">
                            <v-row dense>
                                <v-col cols="auto" class="text">
                                    <span>
                                        Notes
                                    </span>
                                </v-col>
                            </v-row>
                            <v-textarea
                                v-model="obj.notes"
                                placeholder="Write your request here..."
                                outlined
                                dense
                                no-resize
                                :disabled="!isLogco"
                            >
                            </v-textarea>
                        </v-col>
                        <v-col
                            cols="12"
                            class="py-0"
                            v-if="
                                obj.status === 'cancelled' ||
                                    obj.status === 'delined'
                            "
                        >
                            <v-row dense>
                                <v-col cols="auto" class="text">
                                    <span>
                                        Reason
                                    </span>
                                </v-col>
                            </v-row>
                            <v-textarea
                                v-model="obj.reason"
                                placeholder="Write your reason here..."
                                outlined
                                dense
                                no-resize
                                :disabled="!isLogco"
                            >
                            </v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="pt-0 pr-12">
                    <v-spacer></v-spacer>
                    <v-btn text rounded @click="cancel()">
                        Cancel
                    </v-btn>
                    <v-btn color="primary" rounded @click="createEdit" :loading="loading">
                        Submit
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
//import DateTimeComponent from "@/components/base/DateTimeComponent.vue";
import helper from "@/utils/helper.js";
import gql from "graphql-tag";
export default {
    components: {
        //DateTimeComponent
    },
    props: {
        editMode: {
            type: Boolean,
            default: false
        },
        booking: {
            type: Object,
            default: () => {}
        },
        changeRequestObj: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            obj: {
                content: "",
                title: "",
                notes: ""
            },
            requestDialog: false,
            loading: false,
            changeRequestStatus: [
                { text: "Pending", value: "pending" },
                { text: "Approved", value: "approved" },
                { text: "Cancelled", value: "cancelled" },
                { text: "Declined", value: "delined" }
            ]
        };
    },
    computed: {
        me() {
            return helper.me();
        },
        isLogco() {
            return helper.me()?.tenant?.id === this.booking?.tenant?.id;
        }
    },
    watch: {},
    mounted() {},
    methods: {
        enableEditObj() {
            console.log(this.changeRequestObj);
            this.obj = this.changeRequestObj;
            console.log(this.obj);
        },
        cancel() {
            this.requestDialog = false;
            this.obj = {
                content: "",
                title: "",
                notes: ""
            };
        },
        createEdit() {
            this.loading = true;

            let payload = {
                content: this.obj.content,
                title: this.obj.title,
                notes: this.obj.notes
            };

            //CreateBookingOrderChangeRequestQxInput
            if (!this.editMode) {
                payload.status = "pending";
                payload.tenantId = this.me?.tenant?.id || "";
                payload.bookingOrderQxId = this.booking.id;

                this.$apollo
                    .mutate({
                        mutation: gql`
                            mutation createBookingOrderChangeRequestQx(
                                $input: CreateBookingOrderChangeRequestQxInput!
                            ) {
                                createBookingOrderChangeRequestQx(
                                    input: $input
                                ) {
                                    bookingOrderChangeRequestQx {
                                        id
                                        content
                                        notes
                                        reason
                                        status
                                        title
                                        modifiedBy {
                                            id
                                            firstName
                                            lastName
                                        }
                                        modifiedAt
                                    }
                                }
                            }
                        `,
                        variables: {
                            input: {
                                ...payload
                            }
                        }
                    })
                    .then(res => {
                        this.$emit("changeRequestAdded", res.data);
                        this.obj = null;
                    })
                    .catch(err => {
                        console.log(err);
                    })
                    .finally(() => {
                        this.requestDialog = false;
                        this.loading = false;
                    });
            } else {
                payload.status = this.obj.status;
                payload.bookingOrderChangeRequestQxId = this.obj.id;
                payload.reason = this.obj.reason;

                this.$apollo
                    .mutate({
                        mutation: gql`
                            mutation updateBookingOrderChangeRequestQx(
                                $input: UpdateBookingOrderChangeRequestQxInput!
                            ) {
                                updateBookingOrderChangeRequestQx(
                                    input: $input
                                ) {
                                    bookingOrderChangeRequestQx {
                                        id
                                        content
                                        notes
                                        reason
                                        status
                                        title
                                        modifiedBy {
                                            id
                                            firstName
                                            lastName
                                        }
                                        modifiedAt
                                    }
                                }
                            }
                        `,
                        variables: {
                            input: {
                                ...payload
                            }
                        }
                    })
                    .then(res => {
                        this.$emit("changeRequestAdded", res.data);
                        this.obj = null;
                    })
                    .catch(err => {
                        console.log(err);
                    })
                    .finally(() => {
                        this.requestDialog = false;
                        this.loading = false;
                    });
            }
        }
    }
};
</script>
<style scope></style>

<template>
    <v-hover>
        <template v-slot="{ hover }">
            <!---- TOOLBAR LEFT SIDE ---->
            <v-card v-resize="onResize" class="py-5 transparent" outlined>
                <v-row dense align="start" class="d-flex align-center py-0">
                    <v-col class="py-0">
                        <SearchBar
                            v-model="filter.nameIcontains"
                            :label="$t('templates.search_template')"
                            ref="searchBar"
                        >
                            <template v-slot:append-outer>
                                <v-menu
                                    v-if="!isSmallScreen"
                                    ref="menu"
                                    v-model="menu"
                                    :nudge-width="300"
                                    nudge-top="-10"
                                    :nudge-left="1000"
                                    offset-x
                                    offset-y
                                    bottom
                                    :close-on-click="true"
                                    :close-on-content-click="false"
                                    attach
                                    content-class="content"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            color="primary"
                                            v-bind="attrs"
                                            v-on="on"
                                            x-large
                                            @click="openFilterMenu"
                                            style="
                                                height: 53px;
                                                margin-top: -1em;
                                            "
                                        >
                                            <v-icon>
                                                filter_alt
                                            </v-icon>
                                            <v-spacer></v-spacer>
                                            {{ $t("trips.filter") }}
                                            <v-spacer></v-spacer>
                                        </v-btn>
                                    </template>
                                    <v-card class="px-5 py-5" height="100%">
                                        <v-card-text class="pa-0">
                                            <v-row align="start" class="px-5 align-center">
                                                <v-col
                                                    v-for="(f, i) in dynamicFilters"
                                                    :key="i"
                                                    cols="12"
                                                    xs="12"
                                                    sm="6"
                                                    md="4"
                                                    lg="4"
                                                >
                                                    <!---- DESCRPTION ---->
                                                    <v-text-field
                                                        v-if="f === 'description'"
                                                        v-model="menuObject.descriptionIcontains"
                                                        :label="$t('templates.template_description')"
                                                        clearable
                                                        hide-details
                                                        dense
                                                    ></v-text-field>
                                                    <!---- TRANSPORT MODE ---->
                                                    <v-select
                                                        v-if="f === 'transport_mode'"
                                                        v-model="menuObject.transportMode"
                                                        :items="transportModeChoices"
                                                        :label="$t('headers.transport_mode')"
                                                        @change="refreshCarriers"
                                                        clearable
                                                        hide-details
                                                        dense
                                                    ></v-select>
                                                    <!---- CARRIER ---->
                                                    <v-autocomplete
                                                        v-if="f === 'carrier'"
                                                        v-model="menuObject.carrier"
                                                        :items="carriers"
                                                        :no-data-text="$t('loading.no_available')"
                                                        item-text="name"
                                                        :loading="isLoadingCarriers"
                                                        item-value="id"
                                                        :label="$t('headers.carrier')"
                                                        clearable
                                                        hide-details
                                                        dense
                                                    ></v-autocomplete>
                                                    <!---- GOODS ---->
                                                    <v-text-field
                                                        v-if="f === 'goods'"
                                                        v-model="menuObject.goods"
                                                        :label="$t('headers.goods')"
                                                        clearable
                                                        hide-details
                                                        dense
                                                    ></v-text-field>
                                                    <!---- TERMS ---->
                                                    <v-select
                                                        v-if="f === 'term'"
                                                        v-model="menuObject.term"
                                                        :items="termChoices"
                                                        :label="$t('forms.term')"
                                                        clearable
                                                        hide-details
                                                        dense
                                                    ></v-select>
                                                    <!---- LOADPOINT ---->
                                                    <v-text-field
                                                        v-if="f === 'loadPoint'"
                                                        v-model="menuObject.loadPoint"
                                                        :label="$t('forms.load_point')"
                                                        clearable
                                                        hide-details
                                                        dense
                                                    ></v-text-field>
                                                    <!---- CONSIGNEE ---->
                                                    <v-autocomplete
                                                        v-if="f === 'consignee'"
                                                        v-model="menuObject.partner"
                                                        :items="consignees"
                                                        :no-data-text="$t('loading.no_available')"
                                                        item-text="name"
                                                        :loading="isLoadingPartners"
                                                        item-value="id"
                                                        :label="$t('headers.consignee')"
                                                        clearable
                                                        hide-details
                                                        dense
                                                    ></v-autocomplete>
                                                    <!---- CONSIGNOR ---->
                                                    <v-autocomplete
                                                        v-if="f === 'consignor'"
                                                        v-model="menuObject.partner"
                                                        :items="consignors"
                                                        :no-data-text="$t('loading.no_available')"
                                                        item-text="name"
                                                        :loading="isLoadingPartners"
                                                        item-value="id"
                                                        :label="$t('headers.consignor')"
                                                        clearable
                                                        hide-details
                                                        dense
                                                    ></v-autocomplete>
                                                    <!---- FORWARDER ---->
                                                    <v-autocomplete
                                                        v-if="f === 'forwarder'"
                                                        v-model="menuObject.partner"
                                                        :items="forwarders"
                                                        :no-data-text="$t('loading.no_available')"
                                                        item-text="name"
                                                        :loading="isLoadingPartners"
                                                        item-value="id"
                                                        :label="$t('headers.forwarder')"
                                                        clearable
                                                        hide-details
                                                        dense
                                                    ></v-autocomplete>
                                                    <!---- OTHER ---->
                                                    <v-autocomplete
                                                        v-if="f === 'other'"
                                                        v-model="menuObject.partner"
                                                        :items="others"
                                                        :no-data-text="$t('loading.no_available')"
                                                        item-text="name"
                                                        :loading="isLoadingPartners"
                                                        item-value="id"
                                                        :label="$t('transport_mode_choice.other')"
                                                        clearable
                                                        hide-details
                                                        dense
                                                    ></v-autocomplete>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn text @click="closeFilterMenu">
                                                Cancel
                                            </v-btn>
                                            <v-btn color="primary" @click="searchFields(menuObject)">
                                                Search
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-menu>
                            </template>
                            <template v-slot:content-below>
                                <v-row dense class="mt-1" v-if="filterIsSet">
                                    <v-col cols="auto" v-for="i in filterValuesObj" :key="i.id">
                                        <v-chip
                                            label
                                            close
                                            small
                                            class="elevation-0 text-capitalize"
                                            @click:close="clearFilterOption(i.id)"
                                        >
                                            <span v-if="i.id == ''"></span>
                                            {{ i.name + ":" + " " + i.value }}
                                        </v-chip>
                                    </v-col>
                                </v-row>
                            </template>
                        </SearchBar>
                    </v-col>
                </v-row>
            </v-card>
        </template>
    </v-hover>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import FilterMenu from "@/components/base/FilterMenu.vue";
import SearchBar from "@/components/base/SearchBar.vue";
/* import AddTrip from "@/components/trips/create/AddTrip.vue"; */

export default {
    components: {
        SearchBar
    },
    props: {},
    apollo: {
        getCarrierPartners: {
            query: gql`
                query getTenantPartners($partnerType: String, $orderBy: String, $carrierType: String) {
                    getTenantPartners(partnerType: $partnerType, orderBy: $orderBy, carrierType: $carrierType) {
                        edges {
                            node {
                                id
                                name
                                carrierType
                                carrier {
                                    id
                                    isSupportTracking
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    partnerType: "carrier",
                    carrierType: helper.getFilterValues("TemplateTripFilters")?.transportMode,
                    orderBy: "name"
                };
            },
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            // this setting can avoid query again after fetchMore
            update: data => data.getTenantPartners,
            watchLoading(isLoading) {
                this.isLoadingCarriers = isLoading;
                this.$emit("loading", isLoading);
            },
            skip() {
                return false;
            }
        },
        tenantPartners: {
            query: gql`
                query tenantPartners(
                    $first: Int
                    $last: Int
                    $before: String
                    $after: String
                    $orderBy: String
                    $nameIcontains: String
                    $referenceNumberIcontains: String
                    $partnerType: String
                    $carrierType: String
                ) {
                    tenantPartners(
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                        orderBy: $orderBy
                        name_Icontains: $nameIcontains
                        referenceNumber_Icontains: $referenceNumberIcontains
                        partnerType: $partnerType
                        carrierType: $carrierType
                    ) {
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                        edges {
                            node {
                                id
                                name
                                partnerType
                            }
                        }
                    }
                }
            `,
            variables() {
                return;
            },
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            update: data => data.tenantPartners,
            watchLoading(isLoading) {
                this.isLoadingPartners = isLoading;
                this.$emit("loading", isLoading);
            },
            skip: false
        }
    },
    data() {
        return {
            fab: false,
            isLoading: false,
            isLoadingCarriers: false,
            isLoadingPartners: false,
            selectMode: false,
            menu: false,
            dateRange: [],
            savedDateRange: [],
            sortAsc: true,
            shouldStickyFilter: false,
            openAddTripTemplate: false,
            filterChoices: null,
            sortBy: null,
            // graphql query filter
            filter: {
                first: 15,
                orderBy: null,
                nameIcontains: null,
                descriptionIcontains: null,
                transportMode: null,
                carrier: null,
                goods: null,
                loadPoint: null,
                term: null
            },
            menuObject: {},
            filterValuesObj: [],
            filterIsSet: false
        };
    },
    beforeCreate() {
        this.me = helper.me();
    },
    computed: {
        dateRangeText() {
            return this.dateRange
                .map(d => {
                    return this.formatDate(d);
                })
                .join(" ~ ");
        },
        isSmallScreen() {
            return this.$vuetify.breakpoint.width < 700;
        },
        dynamicFilters() {
            return this.filterChoices.map(f => f.code);
        },
        carriers() {
            return this.getCarrierPartners?.edges?.map(e => e.node) || [];
        },
        consignees() {
            return this.tenantPartners?.edges?.filter(e => e.node.partnerType === "consignee").map(e => e.node);
        },
        consignors() {
            return this.tenantPartners?.edges?.filter(e => e.node.partnerType === "consignor").map(e => e.node);
        },
        forwarders() {
            return this.tenantPartners?.edges?.filter(e => e.node.partnerType === "forwarder").map(e => e.node);
        },
        others() {
            return this.tenantPartners?.edges?.filter(e => e.node.partnerType === "other").map(e => e.node);
        },
        sortByChoices: function() {
            let _sortByChoices = [
                {
                    code: "name",
                    name: this.$t("headers.name")
                },
                {
                    code: "description",
                    name: this.$t("general.description")
                }
            ];
            return _sortByChoices;
        },
        transportModeChoices: function() {
            let _transportModeChoices = [
                { text: this.$t("transport_mode_choice.air"), value: "air" },
                { text: this.$t("transport_mode_choice.road"), value: "road" },
                { text: this.$t("transport_mode_choice.rail"), value: "rail" },
                { text: this.$t("transport_mode_choice.sea"), value: "sea" }
            ];
            return _transportModeChoices;
        },
        termChoices: function() {
            let _termChoices = [
                {
                    value: "DAP",
                    text: "DAP" + this.$t("term.dap")
                },
                {
                    value: "DAT",
                    text: "DAT" + this.$t("term.dat")
                },
                {
                    value: "DDP",
                    text: "DDP" + this.$t("term.ddp")
                },
                {
                    value: "DPU",
                    text: "DPU" + this.$t("term.dpu")
                },
                {
                    value: "CFR",
                    text: "CFR" + +this.$t("term.cfr")
                },
                {
                    value: "CIF",
                    text: "CIF" + this.$t("term.cif")
                },
                {
                    value: "CIP",
                    text: "CIP" + this.$t("term.cip")
                },
                {
                    value: "CPT",
                    text: "CPT" + this.$t("term.cpt")
                },
                { value: "EXW", text: "EXW" + this.$t("term.exw") },
                { value: "FCA", text: "FCA" + this.$t("term.fca") },
                {
                    value: "FAS",
                    text: "FAS" + this.$t("term.fas")
                },
                { value: "FOB", text: "FOB(Free On Board)" }
            ];
            return _termChoices;
        },
        defaultFilterChoices: function() {
            let _defaultFilterChoices = [
                /* {
                    code: "name",
                    name: "Name",
                    tag: "headers.name",
                    enabled: true,
                    class: "col-auto col-md-3 col-lg-2"
                }, */
                {
                    code: "description",
                    name: "Description",
                    tag: "general.description",
                    enabled: true,
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "transport_mode",
                    name: "Transport Mode",
                    tag: "headers.transport_mode",
                    enabled: true,
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "carrier",
                    name: "Carrier",
                    tag: "headers.carrier",
                    enabled: true,
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "goods",
                    name: "Goods",
                    tag: "headers.goods",
                    enabled: true,
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "term",
                    name: "Term",
                    tag: "forms.term",
                    enabled: false,
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "loadPoint",
                    name: "Load Point",
                    tag: "headers.load_points",
                    enabled: false,
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "consignee",
                    name: "Consignee",
                    tag: "headers.consignee",
                    enabled: false,
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "consignor",
                    name: "Consignor",
                    tag: "headers.consignor",
                    enabled: false,
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "forwarder",
                    name: "Forwarder",
                    tag: "headers.forwarder",
                    enabled: false,
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "other",
                    name: "Other",
                    tag: "transport_mode_choice.other",
                    enabled: false,
                    class: "col-auto col-md-3 col-lg-2"
                }
            ];

            return _defaultFilterChoices;
        }
    },
    watch: {
        sortBy(val) {
            if (val) {
                this.filter.orderBy = this.sortAsc ? val : `-${val}`;
            } else {
                this.filter.orderBy = null;
            }
        },
        sortAsc(val) {
            if (this.sortBy) {
                this.filter.orderBy = val ? this.sortBy : `-${this.sortBy}`;
            } else {
                this.filter.orderBy = null;
            }
        },
        filter: {
            handler(value) {
                this.filterValuesObj = this.showFilterValues(value);
                this.filterIsSet = this.filterValuesObj.length > 0;
                this.$emit("filter-changed", JSON.parse(JSON.stringify(value)));
                helper.setFilterValues("TemplateTripFilters", value);
            },
            deep: true
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
        this.filterChoices = helper.getFilterChoices(this.defaultFilterChoices, "TemplateTripFilters");
    },
    mounted() {
        this.onResize();
        this.$nextTick(async () => {
            this.populateFilter();
        });
    },
    methods: {
        populateFilter() {
            let _filter = helper.getFilterValues("TemplateTripFilters");
            if (_filter) {
                Object.keys(_filter).forEach(key => {
                    if (key) {
                        this.menuObject[key] = _filter[key];
                    }
                });
            }
        },
        clearFilterOption(key) {
            // clear filter option from the filter chip component in the search bar
            let _filter = this.filter;
            _filter[key] = null;

            //update filter values
            this.filter = _filter;
            this.menuObject = _filter;
        },
        searchFields(filter) {
            this.menu = false;
            let _filter = Object.keys(this.filter).reduce((acc, key) => {
                acc[key] = filter[key];
                return acc;
            }, {});

            this.filter = _filter;
        },
        refreshCarriers() {
            this.$apollo.queries.getCarrierPartners
                .setVariables({
                    partnerType: "carrier",
                    carrierType: this.filter.transportMode,
                    orderBy: "name"
                })
                .then(() => {
                    this.$apollo.queries.getCarrierPartners.refetch();
                });
        },
        deleteSelectedTemplates() {
            this.isLoading = true;
            this.$emit("delete-selected", true);
        },
        changeSelectedMode() {
            this.selectMode = !this.selectMode;
            this.$emit("select-mode", this.selectMode);
        },
        onResize() {
            // this.shouldStickyFilter =
            //     window.innerHeight >= 900 && window.innerWidth >= 1000;
        },
        getFilterChoiceClassByCode(code) {
            return this.filterChoices.find(item => {
                return item.code === code;
            })?.class;
        },
        showFilterValues(filterValues) {
            if (!filterValues) return [];

            // replace filter values to show in the filter chip component in the search bar
            const replaceName = params => {
                let _params = params;
                if (params === "descriptionIcontains") {
                    _params = this.$t("general.description");
                }
                if (params === "carrier") {
                    _params = this.$t("headers.carrier");
                }
                if (params === "goods") {
                    _params = this.$t("headers.goods");
                }
                if (params === "loadPoint") {
                    _params = this.$t("headers.scope");
                }
                if (params === "term") {
                    _params = this.$t("forms.term");
                }
                if (params === "transportMode") {
                    _params = this.$t("headers.transport_mode");
                }

                return _params;
            };

            // filter values to show in the filter chip component in the search bar
            let _filter = Object.keys(filterValues).reduce((acc, key) => {
                if (filterValues[key] && key !== "first" && key !== "after" && key !=='nameIcontains') {
                    acc.push({
                        id: key,
                        name: replaceName(key),
                        value:filterValues[key]
                    });
                }
                return acc;
            }, []);
            return _filter;
        },
        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },
        cancelDatePicker() {
            this.menu = false;
            if (this.savedDateRange.length == 0) {
                this.dateRange = [];
            }
        },
        onFilterMenuChanged(newChoises) {
            this.filterChoices = newChoises;
        },
        onTemplateCreated() {
            this.$emit("template-created");
        },
        openFilterMenu() {
            this.menu = true;
        },
        closeFilterMenu() {
            // cancel filter from the filter chip component in the search bar
            this.menuObject = {};
            this.menu = false;
        }
    }
};
</script>
<style scoped>
/* .v-menu__content {
    left: 210px !important;
    top: 60px !important;
}
 */
.content {
    left: 350px !important;
    top: 60px !important;
}
.checkBox {
    position: static !important;
}
.classText {
    color: #0e0d35 !important;
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 3px;
}

.tooltipContent {
    max-width: 200px;
}
</style>

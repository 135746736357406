<template>
    <!-- Edit profile -->
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-card outlined class="rounded-lg pa-4">
                    <v-card-title class="mb-2">
                        <div class="title">
                            {{ $t("headers.account") }}
                        </div>
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col class="d-flex flex-row">
                                <v-card class="mr-2 rounded-pill" flat>
                                    <v-img
                                        :src="validateInput ? previewImg : editedItem.photoUrl"
                                        contain
                                        width="50px"
                                    ></v-img>
                                </v-card>
                                <div class="d-flex align-left justify-center flex-column">
                                    <div class="body">
                                        {{ editedItem.firstName + "  " + editedItem.lastName }}
                                    </div>
                                    <div class="detailsText">
                                        {{ editedItem.email }}
                                    </div>
                                </div>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="auto" class="d-flex align-center">
                                <div>
                                    <v-btn
                                        v-if="!previewImg && !validateInput"
                                        :loading="isSaving"
                                        levation="0"
                                        color="primary"
                                        class="mr-1"
                                        @click="handleFileImport"
                                        >{{ $t("users.change_photo") }}</v-btn
                                    >
                                    <v-btn
                                        v-else
                                        :loading="isSaving"
                                        class="mr-1"
                                        elevation="0"
                                        color="primary"
                                        @click="saveUser"
                                        >{{ $t("general.save") }}</v-btn
                                    >
                                    <v-btn outlined elevation="0" color="primary" @click="resetForm('photo')">{{
                                        $t("general.delete")
                                    }}</v-btn>
                                </div>
                                <div>
                                    <v-file-input
                                        ref="fileInputRef"
                                        outlined
                                        style="display:none"
                                        :rules="[rules.maxSize]"
                                        @change="onFileChange"
                                        accept="image/png, image/jpeg, image/jpg, image/bmp, .svg"
                                    ></v-file-input>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-card outlined class="rounded-lg pa-4">
                    <v-card-title class="mb-2">
                        <div class="title">
                            {{ $t("headers.personal_information") }}
                        </div>
                        <v-spacer></v-spacer>
                        <v-btn v-if="!editForm" icon color="primary" @click="editForm = !editForm">
                            <v-icon>
                                edit
                            </v-icon>
                        </v-btn>
                        <v-btn v-if="editForm" :disabled="isSaving" text @click="resetForm('user')">{{
                            $t("general.cancel")
                        }}</v-btn>
                        <v-btn v-if="editForm" :loading="isSaving" elevation="0" color="primary" @click="saveUser">{{
                            $t("general.save")
                        }}</v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-form ref="form">
                            <v-row>
                                <v-col :cols="!editForm ? '12' : '6'">
                                    <v-row v-if="!editForm">
                                        <v-col cols="2">
                                            <div class="headers">
                                                {{ $t("headers.full_name") }}
                                            </div>
                                        </v-col>
                                        <v-col cols="10">
                                            <div class="body">
                                                {{ editedItem.firstName + "  " + editedItem.lastName }}
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <div v-else>
                                        <v-row>
                                            <v-col class="headers">
                                                <span>{{ $t("headers.first_name") + "*" }}</span>
                                            </v-col>
                                        </v-row>
                                        <v-text-field
                                            v-model="editedItem.firstName"
                                            :rules="[rules.required, rules.maxLength(100)]"
                                            outlined
                                            hide-details
                                        ></v-text-field>
                                    </div>
                                </v-col>
                                <v-col :cols="!editForm ? '12' : '6'" v-if="editForm">
                                    <v-row>
                                        <v-col class="headers">
                                            <span>{{ $t("headers.last_name") + "*" }}</span>
                                        </v-col>
                                    </v-row>
                                    <v-text-field
                                        v-model="editedItem.lastName"
                                        :rules="[rules.required, rules.maxLength(100)]"
                                        outlined
                                        hide-details
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12">
                                    <v-row v-if="!editForm">
                                        <v-col cols="2">
                                            <div class="headers">
                                                {{ "E-mail" }}
                                            </div>
                                        </v-col>
                                        <v-col cols="10">
                                            <div class="body">
                                                {{ editedItem.email }}
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <div v-else>
                                        <v-row>
                                            <v-col class="headers">
                                                <span>{{ "E-mail*" }}</span>
                                            </v-col>
                                        </v-row>
                                        <v-text-field
                                            v-model="editedItem.email"
                                            :rules="[rules.required, rules.maxLength(100)]"
                                            type="email"
                                            outlined
                                        ></v-text-field>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col v-if="!isPartner" cols="12">
                <PreviewTenantAdminSettings :tenant="tenant" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import PreviewTenantAdminSettings from "@/components/adminsettings/PreviewTenantAdminSettings.vue";

export default {
    components: {
        PreviewTenantAdminSettings
    },
    props: {
        tenant: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            error: null,
            isSaving: false,
            editForm: false,
            editPhotoForm: false,
            editTenantForm: false,
            validateInput: false,
            previewImg: null,
            isSelecting: false,
            editedItem: {
                photoUrl: null,
                photo: null,
                email: null,
                firstName: null,
                lastName: null,
                phoneNumber: null
            },
            organization: {
                name: null,
                id: null
            },
            defaultItem: {
                photoUrl: null,
                photo: null,
                email: null,
                firstName: null,
                lastName: null,
                phoneNumber: null
            },
            isLoadingFetchMe: false,
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                minLength: len => v => (v || "").length >= len || this.$t("rules.at_least") + ` ${len}`,
                maxLength: len => v => (v || "").length <= len || this.$t("rules.too_long"),
                maxSize: value => !value || value.size < 2000000 || "Logo size should be less than 2 MB!"
            }
        };
    },
    computed: {
        me() {
            return helper.me();
        },
        isTenantAdmin() {
            return this.me.isTenantAdmin;
        },
        isPartner() {
            return this.me.isPartnerUser;
        }
    },
    watch: {},
    created() {
        this.hasPermission = helper.hasPermission;
    },
    mounted() {
        this.$nextTick(() => {
            this.populateForm();
        });
    },
    methods: {
        resetForm(type) {
            if (type === "tenant") {
                this.editedItem = { ...this.defaultItem };
                this.editTenantForm = false;
            } else if (type === "photo") {
                this.editedItem.photoUrl = this.defaultItem.photoUrl;
                this.previewImg = null;
                this.editPhotoForm = false;
                this.validateInput = false;
            } else {
                this.editedItem = { ...this.defaultItem };
                this.editForm = false;
            }
        },
        closeUserDialog() {
            this.$emit("update:dialog", false);
            this.resetForm();
        },
        populateForm() {
            let defImg = require("@/assets/user.png");
            // when editing, set form with object
            let _user = {
                id: this.me.id,
                email: this.me.email,
                firstName: this.me.firstName,
                lastName: this.me.lastName,
                phoneNumber: this.me.phoneNumber,
                photoUrl: this.me.photoUrl || defImg,
                photo: this.me.profilePhoto
            };

            //edited Item
            this.editedItem = { ..._user };

            //set default item
            this.defaultItem = { ..._user };
        },
        handleFileImport() {
            this.isSelecting = true;
            window.addEventListener(
                "focus",
                () => {
                    this.isSelecting = false;
                },
                { once: true }
            );

            this.$refs.fileInputRef.$refs.input.click();
        },
        onFileChange(file) {
            if (!file) {
                this.previewImg = null;
                this.editedItem.photoUrl = null;
            } else {
                this.validateInput = this.$refs.fileInputRef.validate();
                this.previewImg = URL.createObjectURL(file);
                this.createImage(file);
            }
        },
        createImage(file) {
            let reader = new FileReader();
            reader.onload = e => {
                this.editedItem.photoUrl = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        fetchMe() {
            this.isLoadingFetchMe = true;
            return this.$store
                .dispatch("user/fetchMe")
                .then(() => {
                })
                .catch(error => {
                    console.log("fetchMe catched error: ", error);
                }).finally(() => {
                    this.isLoadingFetchMe = false;
                });
        },
        saveUser() {
            if (!this.$refs.form.validate()) {
                return;
            }

            // set form state
            this.isSaving = true;

            var payload = {
                firstName: this.editedItem.firstName,
                lastName: this.editedItem.lastName,
                email: this.editedItem.email,
                photo: this.editedItem.photoUrl
            };

            this.$apollo
                .mutate({
                    // Query
                    mutation: gql`
                        mutation updateMyProfile($input: UpdateMyProfileInput!) {
                            updateMyProfile(input: $input) {
                                user {
                                    id
                                    email
                                    firstName
                                    lastName
                                    profilePhoto
                                    photoUrl
                                }
                            }
                        }
                    `,
                    // Parameters
                    variables: {
                        input: { ...payload }
                    }
                })
                .then(response => {
                    let user = {
                        email: response.data.updateMyProfile.user.email,
                        firstName: response.data.updateMyProfile.user.firstName,
                        lastName: response.data.updateMyProfile.user.lastName,
                        photoUrl: response.data.updateMyProfile.user.photoUrl,
                        photo: response.data.updateMyProfile.user.profilePhoto
                    };

                    this.editedItem = { ...user };

                    const payload = {
                        color: "blue",
                        message: this.$t("general.update_successfully")
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                    this.fetchMe();
                })
                .catch(e => {
                    if (e) {
                        console.log("Error: ", e.message);
                    }
                })
                .finally(() => {
                    this.isSaving = false;
                    this.editForm = false;
                    this.defaultItem.firstName = this.editedItem.firstName;
                    this.defaultItem.lastName = this.editedItem.lastName;
                    this.defaultItem.email = this.editedItem.email;
                    this.defaultItem.photoUrl = this.editedItem.photoUrl;
                });
        }
    }
};
</script>
<style scoped>
.title {
    color: #0e0d35;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.6px;
    text-align: left;
}
.headers {
    color: #868599;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    text-align: left;
}
.body {
    color: #0e0d35;
    font-size: 13px;
    font-weight: 700;
    line-height: 14.4px;
    text-align: left;
}
.detailsText {
    color: #868599;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    text-align: left;
}
</style>

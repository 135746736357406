<template>
    <div>
        <LoadingBar v-if="tracker == null && $apollo.queries.tracker.loading"></LoadingBar>
        <div v-else>
            <v-card flat v-if="tracker" color="background">
                <!-- top bar -->
                <v-toolbar flat color="transparent">
                    <v-btn icon @click="$emit('close')">
                        <v-icon>arrow_back_ios_new</v-icon>
                    </v-btn>
                    <v-btn v-if="shouldShowFullScreenButton" icon @click="changeFullScreen(isFullScreen)">
                        <v-icon>{{ isFullScreen ? "fullscreen_exit" : "crop_free" }}</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <!-- Edit trip -->
                    <v-btn color="" @click="editTracker(tracker)" icon>
                        <v-icon>
                            edit
                        </v-icon>
                    </v-btn>

                    <v-menu offset-y transition="slide-x-reverse-transition" width="250px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon>more_vert</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item>
                                <!-- <v-btn
                                    plain
                                    class="pa-0 ml-2"
                                    @click="assignToStockLocation()"
                                
                                >
                                    <v-icon class="mr-2">description</v-icon>
                                    <span>{{ $t('trackers.assign_to') }} {{ $t('trackers.stock_location') }}</span>
                                </v-btn> -->
                                <TrackerChangeLocation
                                    :isMenuItem="true"
                                    :selectedTrackers="selectedTrackersToMove"
                                    v-on:closeSelector="closeSelector"
                                    v-on:menuItemClicked="assignToStockLocation(tracker)"
                                >
                                </TrackerChangeLocation>
                            </v-list-item>
                            <v-list-item v-if="!(canDelete && !isTrackerActivated)">
                                <v-btn plain class="pa-0 ml-2" @click="openDeleteDialog = true">
                                    <v-icon class="mr-2">delete</v-icon>
                                    <span>{{ $t("general.delete") }}</span>
                                </v-btn>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-toolbar>
                <v-spacer></v-spacer>

                <!-- title Bar header -->
                <v-sheet class="pt-0 mb-5 px-5 background d-flex align-center justify-space-between">
                    <v-row dense>
                        <v-col cols="4" class="d-flex align-center justify-start">
                            <div class="mt-1 textLeft">
                                <div class="titleText">
                                    {{ $t("headers.serial_number") }}
                                </div>
                                <div class="labelText">
                                    {{ tracker ? tracker.serialNumber : "" }}
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="4" class="d-flex align-center justify-start">
                            <div class="mt-1 textRight">
                                <div class="titleText">
                                    {{ $t("headers.status") }}
                                </div>
                                <div
                                    class="font-weight-bold primaryText px-1 d-flex align-center justify-start"
                                    :style="
                                        `background-color: ${
                                            trackerStatus(tracker.activatedDate).color1
                                        }; border-radius: 4px;height: 22px;`
                                    "
                                >
                                    <v-icon left size="8" :color="trackerStatus(tracker.activatedDate).color2">
                                        circle
                                    </v-icon>
                                    <span class="text-capitalize">
                                        {{ trackerStatus(tracker.activatedDate).text }}
                                    </span>
                                </div>
                            </div>
                        </v-col>
                        <v-col v-if="isAvailableTracker" cols="4" class="d-flex align-center justify-start">
                            <div class="mt-1 textRight">
                                <div class="titleText">
                                    {{ $t("headers.trip_number") }}
                                </div>
                                <div class="font-weight-bold primaryText px-1 d-flex align-center justify-start">
                                    <span class="text-capitalize">
                                        {{ tracker.tripReferenceNumber }}
                                    </span>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-sheet>

                <!-- Tabs -->
                <v-tabs v-model="tab" color="primary" ref="tabs" background-color="transparent">
                    <v-tab v-for="t in tabs" :key="t.code" :href="`#tab-${t.code}`">
                        <template v-slot:default>
                            <div class="text-capitalize">
                                {{ t.name }}
                            </div>
                        </template>
                    </v-tab>
                    <v-spacer></v-spacer>

                    <!-- tab content -->
                    <v-tabs-items v-model="tab" :style="`height: ${heightScreen}px;`" class="overflow-y-auto">
                        <v-tab-item value="tab-details" :transition="false">
                            <TrackerDetailsPreviewVue
                                :tracker="tracker"
                                :loading="loading"
                                v-on:updateFullscreen="event => $emit('update:isFullScreen', event)"
                            />
                        </v-tab-item>
                    </v-tabs-items>
                </v-tabs>

                <!---- EDIT TRACKER DIALOG ---->
                <EditTrackerDialog
                    :item="editItem"
                    :dialog.sync="editDialog"
                    :isAvailable="isAvailableTracker"
                    v-on:onEdited="onEdited"
                ></EditTrackerDialog>
                <!---- DELETE PARTNER DIALOG ---->
                <DeleteTrackerDialog
                    :openDeleteTrackerDialog="openDeleteDialog"
                    :trackerId="tracker.id ? tracker.id : trackerId"
                    v-on:refreshDataTable="closeDrawerAndRefresh"
                    v-on:closeDeleteTrackerDialog="openDeleteDialog = false"
                >
                </DeleteTrackerDialog>
            </v-card>
        </div>
    </div>
</template>

<script>
import gql from "graphql-tag";
import helper from "../../utils/helper";
import { TrackerPermissions } from "@/core/enum";
import LoadingBar from "@/components/base/LoadingBar.vue";
import DeleteTrackerDialog from "@/components/trackers/delete/DeleteTrackerDialog.vue";
import TrackerDetailsPreviewVue from "@/components/trackers/details/TrackerDetailsPreview.vue";
import EditTrackerDialog from "@/components/trackers/update/EditTrackerDialog.vue";
import TrackerChangeLocation from "@/components/trackers/TrackerChangeLocation.vue";
import Config from "@/utils/config.json";

export default {
    name: "TrackerDetailPreview",
    // Your component logic goes here
    components: {
        LoadingBar,
        DeleteTrackerDialog,
        TrackerDetailsPreviewVue,
        EditTrackerDialog,
        TrackerChangeLocation
    },
    props: {
        trackerProp: {
            type: Object
        },
        isFullScreen: {
            type: Boolean,
            default: false
        },
        trackerId: {
            type: String
        }
    },
    data() {
        return {
            loading: false,
            editDialog: false,
            trackerDetail: null,
            trackerDetailError: null,
            openDeleteDialog: false,
            tab: 0,
            tabs: [
                {
                    code: "details",
                    name: this.$t("headers.details")
                }
            ],
            editItem: null,
            selectedTrackersToMove: [],
            openSelector: false
        };
    },
    apollo: {
        tracker: {
            query: gql`
                query Tracker($trackerId: ID!) {
                    tracker(id: $trackerId) {
                        id
                        stockLocation {
                            id
                            name
                        }
                        serialNumber
                        brand
                        model
                        mainTracker
                        isBeacon
                        activatedDate
                        expiryDate
                        packageNumber
                        comment
                        isArchived
                        lastConnectionTime
                        lastBatteryDeviceTime
                        lastBatteryValue
                        lastTemperatureValue
                        lastTemperatureDeviceTime
                        lastExternalTemperatureValue
                        lastExternalTemperatureDeviceTime
                        lastProbeTemperatureValue
                        lastProbeTemperatureDeviceTime
                        lastHumidityDeviceTime
                        lastHumidityValue
                        lastLightDeviceTime
                        lastLightValue
                        lastShockDeviceTime
                        lastShockValue
                        lastLocationDeviceTime
                        tripReferenceNumber
                        tenant {
                            id
                            name
                        }
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return { trackerId: this.trackerId };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.tracker,
            result({ loading, data }) {
                if (!loading && data.tracker) {
                    this.trackerDetail = Object.assign({}, this.trackerProp, data.tracker);
                }
            },
            watchLoading(isLoading) {
                this.loading = isLoading;
                this.$emit("loading", isLoading);
            },
            skip() {
                /*  if(this.tracker.id){
                    return true;
                } */

                if (this.trackerId) {
                    return false;
                }

                return true;
            }
        }
    },
    beforeCreate() {
        this.hasPermission = helper.hasPermission;
        this.TrackerPermissions = TrackerPermissions;
    },
    computed: {
        me() {
            return helper.me();
        },
        tracker() {
            return this.trackerDetail ? this.trackerDetail : this.trackerProp;
        },
        shouldShowFullScreenButton() {
            return this.$vuetify.breakpoint.lgAndUp;
        },
        canDelete() {
            if (this.me?.isSuperuser) return true;

            if (this.me?.isStaff && this.hasPermission(TrackerPermissions.DELETE_TRACKER)) return true;

            return false;
        },
        isTrackerActivated() {
            return this.tracker?.activatedDate ? true : false;
        },
        isAvailableTracker() {
            return this.tracker?.tripReferenceNumber ? true : false;
        },
        heightScreen() {
            let _break = this.$vuetify.breakpoint.height - 225;
            return _break;
        }
    },
    watch: {},
    mounted() {
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: "tracker_details_svw"
        });
    },
    methods: {
        onCloseSelector() {
            this.openSelector = false;
            this.selectedTrackersToMove = [];
        },
        closeSelector(val) {
            this.openSelector = val;
            this.selectedTrackersToMove = [];
        },
        assignToStockLocation(item) {
            this.selectedTrackersToMove = [item];
        },
        editTracker(tracker) {
            this.editItem = { ...tracker };
            this.editDialog = true;
        },
        onEdited(tracker) {
            this.$emit("updateTracker", tracker);
        },
        changeFullScreen(val) {
            this.$emit("update:isFullScreen", !val);
        },
        closeDrawerAndRefresh() {
            // Emit event that will refresh table data
            this.$emit("refreshDataTable");
            // Close detail drawer if open
            this.$emit("close");
        },
        trackerStatus(activatedDate) {
            let result = {
                text: this.$t("trackers.not_activated"),
                color1: "#E0E0E0",
                color2: "#616161"
            };
            // activatedDate ? $t("trackers.activated") : $t("trackers.not_activated")
            if (activatedDate !== null) {
                result = {
                    text: this.$t("trackers.activated"),
                    color1: "#C8E6C9",
                    color2: "#1B5E20"
                };
            }
            return result;
        }
    }
};
</script>

<style scoped>
/* Your component styles go here */
</style>

<template>
    <v-card elevation="5" outlined v-resize="onResize">
        <v-card-text class="pb-3 pr-3">
            <div class="d-flex justify-end">
                <div>
                    <v-btn icon>
                        <v-icon
                            @mouseenter="startScrolling('left')"
                            @mouseleave="stopScrolling"
                            >arrow_back</v-icon
                        >
                    </v-btn>
                    <v-btn icon>
                        <v-icon
                            @mouseenter="startScrolling('right')"
                            @mouseleave="stopScrolling"
                            >arrow_forward</v-icon
                        >
                    </v-btn>
                </div>
                <!-- filter menu -->
                <FilterMenu
                    :title="$t('dashboard.customize')"
                    :description="$t('dashboard.display')"
                    :choices="dynamicHeaders"
                    :open-on-hover="false"
                    height="500px"
                    width="250px"
                    v-on:changed="onFilterMenuChanged"
                >
                </FilterMenu>
            </div>
            <v-data-table
                v-model="selectedTrackers"
                :show-select="selector"
                :headers="dynamicHeaders"
                :items="trackers.edges"
                item-key="node.id"
                :loading-text="$t('loading.loading1')"
                :loading="$apollo.queries.trackers.loading"
                hide-default-footer
                disable-pagination
                :mobile-breakpoint="
                    screenSize === 'xs' || screenSize === 'sm' ? 800 : 0
                "
                loader-height="2"
                :no-data-text="$t('loading.no_data')"
                fixed-header
                :height="tableHeight"
                @click:row="item => this.$emit('click:row', item)"
                class="row-pointer"
            >
                <!-- no data availabel -->
                <template slot="no-data">
                    <div>{{ $t("loading.no_data") }}</div>
                </template>

                <!--Serial Number-->
                <template v-slot:item.node.serialNumber="{ item }">
                    <span class="text-uppercase">{{
                        item.node.serialNumber
                    }}</span>
                </template>

                <!--Is Archived-->
                <template v-slot:item.node.isArchived="{ item }">
                    <v-icon v-if="item.node.isArchived == true" color="success"
                        >check_circle</v-icon
                    >
                    <v-icon color="grey lighten-2" v-else>check_circle</v-icon>
                </template>

                <!-- Last Connection Time -->
                <template v-slot:item.node.lastConnectionTime="{ item }">
                    <span>{{
                        formatDateTime(item.node.lastConnectionTime)
                    }}</span>
                </template>

                <!-- Expiration Date -->
                <template v-slot:item.node.expiryDate="{ item }">
                    <span>{{ formatDateTime(item.node.expiryDate) }}</span>
                </template>

                <template v-slot:item.node.lastBatteryValue="{ item }">
                    <span
                        v-if="
                            item.node.lastBatteryValue &&
                            item.node.brand.toLowerCase() !=
                                'sensitech'
                        "
                        >{{ item.node.lastBatteryValue }}</span
                    >
                </template>

                <!-- Tenant -->
                <template v-slot:item.node.tenant.name="{ item }">
                    <span>{{
                        item.node.tenant ? item.node.tenant.name : ""
                    }}</span>
                </template>

                <!-- Activated Date -->
                <template v-slot:item.node.activatedDate="{ item }">
                    <span>{{ formatDateTime(item.node.activatedDate) }}</span>
                </template>

                <!--Status-->
                <template v-slot:item.status="{ item }">
                    <v-chip
                        outlined
                        small
                        v-if="getTrackerStatus(item) == 'Not Activated'"
                        color=""
                        >{{ $t("general.not_activated") }}</v-chip
                    >
                    <v-chip
                        outlined
                        small
                        v-else-if="getTrackerStatus(item) == 'Activated'"
                        color="primary"
                        >{{ $t("general.activated") }}</v-chip
                    >
                </template>

                <!-- Created At -->
                <template v-slot:item.node.createdAt="{ item }">
                    <span>{{ formatDateTime(item.node.createdAt) }}</span>
                </template>

                <!-- Created By -->
                <template v-slot:item.node.createdBy="{ item }">
                    <span>{{
                        item.node.createdBy ? item.node.createdBy.fullName : ""
                    }}</span>
                </template>
                <template v-slot:loading>
                    <tr >
                        <td
                            :colspan="2"
                            :style="centralizeLoadBtnClass"
                        >
                            <div>
                                {{ $t('loading.loading1') }}
                            </div>
                        </td>
                    </tr>
                </template>

                <!-- infinit loading trigger -->
                <template slot="body.append">
                    <tr>
                        <td
                            :colspan="2"
                            :style="centralizeLoadBtnClass"
                        >
                            <v-btn
                                v-if="hasMoreData"
                                v-intersect="onLoadMoreTriggerIntersect"
                                :disabled="!hasMoreData"
                                :loading="$apollo.queries.trackers.loading"
                                plain
                                class="mb-5"
                                @click="loadMore"
                            >
                                {{ $t("loading.load_more") }}
                            </v-btn>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card-text>

        <!-- infinit loading trigger -->
        <!-- <v-card-actions class="justify-center">
            <v-btn
                v-if="hasMoreData"
                v-intersect="onLoadMoreTriggerIntersect"
                :disabled="!hasMoreData"
                :loading="$apollo.queries.trackers.loading"
                plain
                class="mb-5"
                @click="loadMore"
            >
                Load more
            </v-btn>
        </v-card-actions> -->
    </v-card>
</template>

<script>
import gql from "graphql-tag";
import FilterMenu from "@/components/base/FilterMenu.vue";
import helper from "../../utils/helper";

export default {
    props: {
        trackerFilter: {
            type: Object,
            required: false,
            default: () => {
                return {};
            }
        },
        selector: Boolean
    },
    components: { FilterMenu },

    apollo: {
        trackers: {
            query: gql`
                query trackers(
                    $first: Int
                    $last: Int
                    $before: String
                    $after: String
                    $serialNumber_Icontains: String
                    $brand_Icontains: String
                    $search: String
                    $orderBy: String
                    $isArchived: Boolean
                    $lastConnectionTime_Gte: DateTime
                    $lastConnectionTime_Lte: DateTime
                    $activatedDate: DateTime
                    $activatedDateGte: DateTime
                    $activatedDateLte: DateTime
                    $tenant: ID
                    $tenant_Isnull: Boolean
                    $expiryDateLte: DateTime
                    $expiryDateGte: DateTime
                    $packageNumberIcontains: String
                    $commentIcontains: String
                    $status: String
                    $isBeaconTracker: Boolean
                ) {
                    trackers(
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                        serialNumber_Icontains: $serialNumber_Icontains
                        brand_Icontains: $brand_Icontains
                        search: $search
                        orderBy: $orderBy
                        isArchived: $isArchived
                        lastConnectionTime_Gte: $lastConnectionTime_Gte
                        lastConnectionTime_Lte: $lastConnectionTime_Lte
                        activatedDate: $activatedDate
                        activatedDate_Gte: $activatedDateGte
                        activatedDate_Lte: $activatedDateLte
                        tenant: $tenant
                        tenant_Isnull: $tenant_Isnull
                        expiryDate_Lte: $expiryDateLte
                        expiryDate_Gte: $expiryDateGte
                        packageNumber_Icontains: $packageNumberIcontains
                        comment_Icontains: $commentIcontains
                        status: $status
                        isBeaconTracker: $isBeaconTracker
                    ) {
                        pageInfo {
                            startCursor
                            endCursor
                            hasPreviousPage
                            hasNextPage
                        }
                        edges {
                            node {
                                id
                                createdAt
                                createdBy {
                                    id
                                    firstName
                                    lastName
                                    fullName
                                }
                                stockLocation {
                                    id
                                    name
                                }
                                serialNumber
                                mainTracker
                                brand
                                model
                                isBeacon
                                activatedDate
                                expiryDate
                                packageNumber
                                comment
                                isArchived
                                lastConnectionTime
                                lastBatteryDeviceTime
                                lastBatteryValue
                                lastTemperatureValue
                                lastTemperatureDeviceTime
                                lastExternalTemperatureValue
                                lastExternalTemperatureDeviceTime
                                lastProbeTemperatureValue
                                lastProbeTemperatureDeviceTime
                                lastHumidityDeviceTime
                                lastHumidityValue
                                lastLightDeviceTime
                                lastLightValue
                                lastShockDeviceTime
                                lastShockValue
                                lastLocationDeviceTime
                                tenant {
                                    id
                                    name
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return this.trackerFilter;
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.trackers,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            debounce: 800
            // skip() {
            //     return !this.me.isStaff;
            // }
            // pollInterval: 60000 // ms
        }
    },

    data() {
        return {
            shouldLoadMore: false,
            isInitialLoad: true,
            selectedHeaders: ["node.serialNumber"],
            trackers: { edges: [] },
            selectedTrackers: [],
            openTrackerChangeLocationDialog: false,
            tableHeight: 800,

            // defaultHeaderChoices: [
            //     {
            //         // for filter menu
            //         code: "status",
            //         name: this.$t("headers.status"),
            //         enabled: true,
            //         // for table header
            //         text: this.$t("headers.status"),
            //         align: "left",
            //         value: "status",
            //         sortable: false
            //     },
            //     {
            //         // for filter menu
            //         code: "node.serialNumber",
            //         name: this.$t("headers.serial_number"),
            //         enabled: true,
            //         // for table header
            //         text: this.$t("headers.serial_number"),
            //         align: "left",
            //         value: "node.serialNumber",
            //         sortable: false
            //     },
            //     {
            //         // for filter menu
            //         code: "node.brand",
            //         name: this.$t("headers.brand"),
            //         enabled: true,
            //         // for table header
            //         text: this.$t("headers.brand"),
            //         align: "left",
            //         value: "node.brand",
            //         sortable: false
            //     },
            //     {
            //         // for filter menu
            //         code: "node.packageNumber",
            //         name: this.$t("headers.package_number"),
            //         enabled: true,
            //         // for table header
            //         text: this.$t("headers.package_number"),
            //         align: "right",
            //         value: "node.packageNumber",
            //         sortable: false
            //     },
            //     {
            //         // for filter menu
            //         code: "node.stockLocation.name",
            //         name: this.$t("trackers.stock_location"),
            //         enabled: true,
            //         // for table header
            //         text: this.$t("trackers.stock_location"),
            //         align: "center",
            //         value: "node.stockLocation.name",
            //         sortable: false
            //     },
            //     {
            //         // for filter menu
            //         code: "node.activatedDate",
            //         name: this.$t("headers.activation"),
            //         enabled: true,
            //         // for table header
            //         text: this.$t("headers.activation"),
            //         align: "left",
            //         value: "node.activatedDate",
            //         sortable: false
            //     },
            //     {
            //         // for filter menu
            //         code: "node.lastConnectionTime",
            //         name: this.$t("headers.last_date"),
            //         enabled: true,
            //         // for table header
            //         text: this.$t("headers.last_date"),
            //         align: "left",
            //         value: "node.lastConnectionTime",
            //         sortable: false
            //     },
            //     {
            //         // for filter menu
            //         code: "node.expiryDate",
            //         name: this.$t("headers.expiration"),
            //         enabled: true,
            //         // for table header
            //         text: this.$t("headers.expiration"),
            //         align: "left",
            //         value: "node.expiryDate",
            //         sortable: false
            //     },
            //     {
            //         // for filter menu
            //         code: "node.lastBatteryValue",
            //         name: this.$t("headers.battery"),
            //         enabled: true,
            //         // for table header
            //         text: this.$t("headers.battery"),
            //         align: "right",
            //         value: "node.lastBatteryValue",
            //         sortable: false
            //     },
            //     {
            //         // for filter menu
            //         code: "node.comment",
            //         name: this.$t("headers.comment"),
            //         enabled: false,
            //         // for table header
            //         text: this.$t("headers.comment"),
            //         align: "left",
            //         value: "node.comment",
            //         sortable: false
            //     },
            //     {
            //         // for filter menu
            //         code: "node.isArchived",
            //         name: this.$t("headers.archived"),
            //         enabled: false,
            //         // for table header
            //         text: this.$t("headers.archived"),
            //         align: "center",
            //         value: "node.isArchived",
            //         sortable: false
            //     }
            // ],
            headerChoices: null,

            // TODO - ask Liang about this
            tableHeaderFilter: {
                serialNumber: true,
                archived: true
            }
        };
    },
    computed: {
        screenSize() {
            let _resposiveView = this.$vuetify.breakpoint.width;
            if (_resposiveView < 650) {
                return "xs";
            } else if (_resposiveView < 1000) {
                return "sm";
            } else if (_resposiveView < 1400) {
                return "md";
            } else if (_resposiveView < 1900) {
                return "lg";
            } else {
                return "xl";
            }
        },
        me() {
            return helper.me();
        },
        // infinite
        hasMoreData() {
            return this.trackers?.pageInfo?.hasNextPage;
        },
        centralizeLoadBtnClass() {
            let _centralizeLoadBtnClass = {
                position: "sticky",
                left: "50%",
            };
            return _centralizeLoadBtnClass;
        }, 
        dynamicHeaders() {
            let _headers = this.headerChoices.filter(header => header.enabled);
            _headers = _headers.map(header => {
                return {
                    ...header,
                    text: this.$t(header.tag)
                };
            });

            // put header filter in the last column
            // _headers.push({
            //     text: "",
            //     align: "right",
            //     value: "headerFilter",
            //     sortable: false
            // });

            return _headers;
        },
        isStaff() {
            return this.me.isStaff || false;
        },
        defaultHeaderChoices: function() {
            let _defaultHeaderChoices;
            if (this.me.isStaff){
                _defaultHeaderChoices = [
                    {
                        // for filter menu
                        code: "status",
                        name: "Status",
                        tag: "headers.status",
                        enabled: true,
                        // for table header
                        text: "Status",
                        align: "left",
                        value: "status",
                        sortable: false
                    },
                    {
                        // for filter menu
                        code: "node.serialNumber",
                        name: "Serial Number",
                        tag: "headers.serial_number",
                        enabled: true,
                        // for table header
                        text: "Serial Number",
                        align: "left",
                        value: "node.serialNumber",
                        sortable: false
                    },
                    // {
                    //     code: "node.tenant.name",
                    //     name: "Tenant",
                    //     tag: "trackers.tenant",
                    //     enabled: true,
                    //     // for table header
                    //     text: "Tenant",
                    //     align: "left",
                    //     value: "node.tenant.name",
                    //     sortable: false
                    // },
                    {
                        // for filter menu
                        code: "node.brand",
                        name: "Brand",
                        tag: "headers.brand",
                        enabled: true,
                        // for table header
                        text: "Brand",
                        align: "left",
                        value: "node.brand",
                        sortable: false
                    },
                    {
                        // for filter menu
                        code: "node.packageNumber",
                        name: "Package Number",
                        tag: "headers.package_number",
                        enabled: true,
                        // for table header
                        text: "Package Number",
                        align: "right",
                        value: "node.packageNumber",
                        sortable: false
                    },
                    {
                        // for filter menu
                        code: "node.stockLocation.name",
                        name: "Stock Location",
                        tag: "trackers.stock_location",
                        enabled: true,
                        // for table header
                        text: "Stock Location",
                        align: "center",
                        value: "node.stockLocation.name",
                        sortable: false
                    },
                    {
                        // for filter menu
                        code: "node.activatedDate",
                        name: "Activation Date",
                        tag: "headers.activation",
                        enabled: true,
                        // for table header
                        text: "Activation Date",
                        align: "left",
                        value: "node.activatedDate",
                        sortable: false
                    },
                    {
                        // for filter menu
                        code: "mainTracker",
                        name: "Main Tracker",
                        tag: "headers.main_tracker",
                        enabled: true,
                        // for table header
                        text: "Main Tracker",
                        align: "left",
                        value: "node.mainTracker",
                        sortable: false
                    },
                    {
                        // for filter menu
                        code: "node.lastConnectionTime",
                        name: "Last Connection Date",
                        tag: "headers.last_date",
                        enabled: true,
                        // for table header
                        text: "Last Connection Date",
                        align: "left",
                        value: "node.lastConnectionTime",
                        sortable: false
                    },
                    {
                        // for filter menu
                        code: "node.expiryDate",
                        name: "Expiration Date",
                        tag: "headers.expiration",
                        enabled: true,
                        // for table header
                        text: "Expiration Date",
                        align: "left",
                        value: "node.expiryDate",
                        sortable: false
                    },
                    {
                        // for filter menu
                        code: "node.lastBatteryValue",
                        name: "Battery Level",
                        tag: "headers.battery",
                        enabled: true,
                        // for table header
                        text: "Battery Level",
                        align: "right",
                        value: "node.lastBatteryValue",
                        sortable: false
                    },
                    {
                        // for filter menu
                        code: "node.comment",
                        name: "Comment",
                        tag: "headers.comment",
                        enabled: false,
                        // for table header
                        text: "Comment",
                        align: "left",
                        value: "node.comment",
                        sortable: false
                    },
                    {
                        // for filter menu
                        code: "node.isArchived",
                        name: "Archived",
                        tag: "headers.archived",
                        enabled: false,
                        // for table header
                        text: "Archived",
                        align: "center",
                        value: "node.isArchived",
                        sortable: false
                    },
                    {
                        // for filter menu
                        code: "node.createdBy",
                        name: "Created By",
                        tag: "headers.create_by",
                        enabled: false,
                        // for table header
                        text: "Created By",
                        align: "center",
                        value: "node.createdBy",
                        sortable: false
                    },
                    {
                        // for filter menu
                        code: "node.createdAt",
                        name: "Created At",
                        tag: "headers.create_at",
                        enabled: false,
                        // for table header
                        text: "Created At",
                        align: "center",
                        value: "node.createdAt",
                        sortable: false
                    }
                ];
            } else {
                _defaultHeaderChoices = [
                    {
                        // for filter menu
                        code: "status",
                        name: "Status",
                        tag: "headers.status",
                        enabled: true,
                        // for table header
                        text: "Status",
                        align: "left",
                        value: "status",
                        sortable: false
                    },
                    {
                        // for filter menu
                        code: "node.serialNumber",
                        name: "Serial Number",
                        tag: "headers.serial_number",
                        enabled: true,
                        // for table header
                        text: "Serial Number",
                        align: "left",
                        value: "node.serialNumber",
                        sortable: false
                    },
                    {
                        // for filter menu
                        code: "node.brand",
                        name: "Brand",
                        tag: "headers.brand",
                        enabled: true,
                        // for table header
                        text: "Brand",
                        align: "left",
                        value: "node.brand",
                        sortable: false
                    },
                    {
                        // for filter menu
                        code: "node.packageNumber",
                        name: "Package Number",
                        tag: "headers.package_number",
                        enabled: true,
                        // for table header
                        text: "Package Number",
                        align: "right",
                        value: "node.packageNumber",
                        sortable: false
                    },
                    {
                        // for filter menu
                        code: "mainTracker",
                        name: "Main Tracker",
                        tag: "headers.main_tracker",
                        enabled: true,
                        // for table header
                        text: "Main Tracker",
                        align: "left",
                        value: "node.mainTracker",
                        sortable: false
                    },
                    {
                        // for filter menu
                        code: "node.stockLocation.name",
                        name: "Stock Location",
                        tag: "trackers.stock_location",
                        enabled: true,
                        // for table header
                        text: "Stock Location",
                        align: "center",
                        value: "node.stockLocation.name",
                        sortable: false
                    },
                    {
                        // for filter menu
                        code: "node.activatedDate",
                        name: "Activation Date",
                        tag: "headers.activation",
                        enabled: true,
                        // for table header
                        text: "Activation Date",
                        align: "left",
                        value: "node.activatedDate",
                        sortable: false
                    },
                    {
                        // for filter menu
                        code: "node.lastConnectionTime",
                        name: "Last Connection Date",
                        tag: "headers.last_date",
                        enabled: true,
                        // for table header
                        text: "Last Connection Date",
                        align: "left",
                        value: "node.lastConnectionTime",
                        sortable: false
                    },
                    {
                        // for filter menu
                        code: "node.expiryDate",
                        name: "Expiration Date",
                        tag: "headers.expiration",
                        enabled: true,
                        // for table header
                        text: "Expiration Date",
                        align: "left",
                        value: "node.expiryDate",
                        sortable: false
                    },
                    {
                        // for filter menu
                        code: "node.lastBatteryValue",
                        name: "Battery Level",
                        tag: "headers.battery",
                        enabled: true,
                        // for table header
                        text: "Battery Level",
                        align: "right",
                        value: "node.lastBatteryValue",
                        sortable: false
                    },
                    {
                        // for filter menu
                        code: "node.comment",
                        name: "Comment",
                        tag: "headers.comment",
                        enabled: false,
                        // for table header
                        text: "Comment",
                        align: "left",
                        value: "node.comment",
                        sortable: false
                    },
                    {
                        // for filter menu
                        code: "node.isArchived",
                        name: "Archived",
                        tag: "headers.archived",
                        enabled: false,
                        // for table header
                        text: "Archived",
                        align: "center",
                        value: "node.isArchived",
                        sortable: false
                    },
                    {
                        // for filter menu
                        code: "node.createdBy",
                        name: "Created By",
                        tag: "headers.create_by",
                        enabled: false,
                        // for table header
                        text: "Created By",
                        align: "center",
                        value: "node.createdBy",
                        sortable: false
                    },
                    {
                        // for filter menu
                        code: "node.createdAt",
                        name: "Created At",
                        tag: "headers.create_at",
                        enabled: false,
                        // for table header
                        text: "Created At",
                        align: "center",
                        value: "node.createdAt",
                        sortable: false
                    }
                ];
            }
            return _defaultHeaderChoices;
        }
    },
    watch: {
        selector: function() {
            if (this.selector == false) this.selectedTrackers = [];
        },
        selectedTrackers: function() {
            this.$emit("selectedTrackers", this.selectedTrackers);
        }
    },
    mounted() {
        this.loadMore();

        // watch filterChoices
        this.$watch(
            "headerChoices",
            function(value) {
                helper.updateMyPreferences({
                    trackersTableHeaders: value
                });
            },
            { deep: true }
        );
    },
    created() {
        this.formatDateTime = helper.formatDateTime;
        this.formatTemperature = helper.formatTemperature;
        if (this.isStaff) {
            this.defaultHeaderChoices.push({
                // for filter menu
                code: "node.tenant.name",
                name: "Tenant",
                tag: "trackers.tenant",
                enabled: true,
                // for table header
                text: "Tenant",
                align: "center",
                value: "node.tenant.name",
                sortable: false
            });
        }
        this.headerChoices = helper.getTableHeaders(
            "trackersTableHeaders",
            this.defaultHeaderChoices
        );
    },
    methods: {
        onResize() {
            this.$nextTick(() => {
                this.tableHeight = window.innerHeight - 180 + "px";
            });
        },
        startScrolling(direction) {
            this.scrollInterval = setInterval(() => {
                const tableContainer = document.querySelector(
                    ".v-data-table__wrapper"
                );
                const scrollAmount = direction === "left" ? -10 : 10;
                tableContainer.scrollLeft += scrollAmount;
            }, 10);
        },
        stopScrolling() {
            clearInterval(this.scrollInterval);
        },
        onFilterMenuChanged(newChoices) {
            this.headerChoices = newChoices;
        },
        onLoadMoreTriggerIntersect(entries) {
            this.shouldLoadMore = entries[0].isIntersecting;

            if (this.shouldLoadMore && this.trackers?.pageInfo?.hasNextPage) {
                this.loadMore();
            }
        },
        refetchDate() {
            // use timeout to debouce
            if (this.timeout) clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                this.$apollo.queries.trackers.refetch(this.filter);
            }, 800); // delay
        },
        loadMore() {
            if (this.trackers?.pageInfo?.hasNextPage) {
                this.$apollo.queries.trackers.fetchMore({
                    variables: {
                        after: this.trackers.pageInfo.endCursor
                    }
                });
            }
        },
        getTrackerStatus(tracker) {
            if (tracker.node.activatedDate == null) {
                return this.$t("general.not_activated");
            } else {
                return this.$t("general.activated");
            }
        }
    }
};
</script>
<style scoped lang="css">
.row-pointer >>> tbody tr :hover {
    cursor: pointer;
}
</style>

<template>
    <v-container fluid class="px-0 pt-0" style="background-color: transparent;">
        <v-form>
            <v-expansion-panels flat class="rounded-lg" v-model="panel" multiple>
                <v-expansion-panel>
                    <v-expansion-panel-header class="contentDisplay">
                        <div class="d-flex flex-column textTitle px-4">
                            <div class="">
                                {{ $t("general.sea_transport") }}
                            </div>
                            <span class="textBody">{{ $t("forms.sea_transport_warning") }}</span>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row dense class="pb-4">
                            <v-col v-for="(item, i) in tripSeaTransport" :key="i" cols="12" class="mt-2">
                                <v-row dense v-if="item">
                                    <v-col cols="12" class="contentDisplay">
                                        <v-row dense class="px-4">
                                            <v-col cols="auto" class="textTitle">
                                                <div>
                                                    {{ seaTitles(item.portTransportType) }}
                                                </div>
                                            </v-col>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                v-if="item.portTransportType === 'T/S'"
                                                icon
                                                small
                                                rounded
                                                class="float-right"
                                                @click="remove(i)"
                                            >
                                                <v-icon>
                                                    delete
                                                </v-icon>
                                            </v-btn>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="" lg="" xl="">
                                        <v-text-field
                                            v-show="false"
                                            dense
                                            :label="$t('forms.sequence')"
                                            v-model="item.sequence"
                                            outlined
                                            filled
                                            readonly
                                        />

                                        <v-row dense>
                                            <v-col cols="12" class="contentDisplay">
                                                <div class="px-4">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="textBody labelContent">
                                                            <span>
                                                                {{
                                                                    item.portTransportType === "POL" ||
                                                                    item.portTransportType === "POD"
                                                                        ? `${item.portTransportType}` +
                                                                          " " +
                                                                          $t("trips.port_code2")
                                                                        : `${item.portTransportType}` +
                                                                          " " +
                                                                          $t("trips.port_code")
                                                                }}
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-autocomplete
                                                        class="inputContent mb-n2"
                                                        v-model="item.portCode"
                                                        :items="seaPorts.edges"
                                                        :no-data-text="$t('loading.no_available')"
                                                        :item-value="item => item.node.code"
                                                        :filter="customFilter"
                                                        :item-text="item => item.node.code"
                                                        :loading="$apollo.queries.seaPorts.loading"
                                                        :search-input="item.portCode"
                                                        :return-object="false"
                                                        clearable
                                                        outlined
                                                        dense
                                                        :error-messages="
                                                            item.portCode === null ? 'Port Code is missing' : []
                                                        "
                                                    >
                                                        <template
                                                            v-slot:item="{
                                                                item
                                                            }"
                                                        >
                                                            <v-row class="ma-0 body-2">
                                                                <v-col cols="4" class="text-truncate pl-0 ml-0">
                                                                    {{ item.node.code }}
                                                                </v-col>
                                                                <v-col cols="8" class="d-flex justify-end">
                                                                    <span class="grey--text body-2 mr-1"
                                                                        ><i>{{ item.node.name }}</i></span
                                                                    >
                                                                </v-col>
                                                            </v-row>
                                                        </template>
                                                    </v-autocomplete>
                                                </div>
                                            </v-col>
                                            <v-col
                                                cols="6"
                                                class="contentDisplay2"
                                                v-if="item.portTransportType !== 'POD'"
                                            >
                                                <div class="px-4">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="textBody labelContent">
                                                            <span>
                                                                {{ $t("trips.vessel_input") }}
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-select
                                                        class="inputContent mb-n2"
                                                        dense
                                                        v-model="item.vesselInputType"
                                                        hide-details
                                                        :items="type"
                                                        outlined
                                                        :disabled="templateMode || item.portTransportType === 'POD'"
                                                        :filled="templateMode || item.portTransportType === 'POD'"
                                                        :error-messages="
                                                            item.vesselInputType === null && editMode
                                                                ? $t('rules.vessel_input_missing')
                                                                : []
                                                        "
                                                    ></v-select>
                                                </div>
                                            </v-col>
                                            <v-col
                                                cols="6"
                                                class="contentDisplay3"
                                                v-if="item.portTransportType !== 'POD'"
                                            >
                                                <div class="px-4">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="textBody labelContent">
                                                            <span>
                                                                {{
                                                                    item.vesselInputType
                                                                        ? `${getInputTypeText(item.vesselInputType)}` +
                                                                          " "
                                                                        : $t("trips.vessel_no")
                                                                }}
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <VesselAutoComplete
                                                        v-model="item.vesselInputValue"
                                                        :vesselType="item.vesselInputType"
                                                        :filled="templateMode || item.portTransportType === 'POD'"
                                                    ></VesselAutoComplete>
                                                </div>
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                class="contentDisplay"
                                                v-if="item.portTransportType !== 'POD'"
                                            >
                                                <div class="px-4">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="textBody labelContent">
                                                            <span>
                                                                {{ $t("trips.voyage") }}
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-text-field
                                                        dense
                                                        v-model="item.voyage"
                                                        class="inputContent"
                                                        outlined
                                                        :disabled="templateMode || item.portTransportType === 'POD'"
                                                        :filled="templateMode || item.portTransportType === 'POD'"
                                                        @change="onUpdateSeaTransport(tripSeaTransport)"
                                                        :error-messages="
                                                            item.voyage === null && editMode ? 'Voyage is missing' : []
                                                        "
                                                    />
                                                </div>
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                class="contentDisplay"
                                                v-if="item.portTransportType !== 'POD' && !editMode"
                                            >
                                                <div class="px-4">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="textBody labelContent">
                                                            <span>
                                                                {{ $t("headers.vgm_cut_off_date") }}
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <DateTimeComponent
                                                        v-model="item.vgmCutOffDate"
                                                        :icon="'calendar_month'"
                                                        :rules="[]"
                                                        :outlined="true"
                                                        :dense="true"
                                                        :disabled="templateMode"
                                                        :filled="templateMode"
                                                        :defaultTime="'00:00'"
                                                        class="inputContent mb-n2"
                                                    >
                                                    </DateTimeComponent>
                                                </div>
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                class="contentDisplay"
                                                v-if="item.portTransportType !== 'POD' && editMode"
                                            >
                                                <div class="px-4">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="textBody labelContent">
                                                            <span>
                                                                {{ $t("headers.booking_confirmation_number") }}
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-text-field
                                                        dense
                                                        v-model="item.bookingConfirmationNumber"
                                                        class="inputContent"
                                                        outlined
                                                        :disabled="templateMode || item.portTransportType === 'POD'"
                                                        :filled="templateMode || item.portTransportType === 'POD'"
                                                        @change="onUpdateSeaTransport(tripSeaTransport)"
                                                    />
                                                </div>
                                                <div class="px-4">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="textBody labelContent">
                                                            <span>
                                                                {{ $t("headers.vgm_cut_off_date") }}
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <DateTimeComponent
                                                        v-if="editMode"
                                                        v-model="item.vgmCutOffDate"
                                                        :icon="'calendar_month'"
                                                        :rules="[]"
                                                        :outlined="true"
                                                        :dense="true"
                                                        :disabled="templateMode"
                                                        :filled="templateMode"
                                                        :defaultTime="'00:00'"
                                                        class="inputContent mb-n2"
                                                    >
                                                    </DateTimeComponent>
                                                </div>
                                            </v-col>
                                            <!-- <v-col
                                                v-if="
                                                    item.portTransportType ===
                                                        'T/S' && editMode
                                                "
                                                cols="12"
                                                class="contentDisplay"
                                            >
                                                <div class="px-4">
                                                    <v-row
                                                        dense
                                                    >
                                                        <v-col
                                                            cols="auto"
                                                            class="textBody labelContent"
                                                        >
                                                            <span>
                                                                {{ "ATA" }}
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <DateTimeComponent
                                                        v-model="
                                                            item.actualArrivalDate
                                                        "
                                                        :icon="'calendar_month'"
                                                        :rules="[]"
                                                        :outlined="true"
                                                        :dense="true"
                                                        :disabled="templateMode"
                                                        :filled="templateMode"
                                                        :defaultTime="'00:00'"
                                                        class="inputContent mb-n2"
                                                    >
                                                    </DateTimeComponent>
                                                </div>
                                            </v-col> -->
                                        </v-row>
                                    </v-col>
                                    <v-card
                                        v-if="!isSmallScreen"
                                        class="d-flex justify-center align-center"
                                        width="20px"
                                        flat
                                        style="
                                            background-color: transparent;
                                        "
                                    >
                                    </v-card>
                                    <v-col cols="12" xs="12" sm="12" md="" lg="" xl="">
                                        <v-row dense>
                                            <v-col cols="12" class="contentDisplay">
                                                <div class="px-4" v-if="item.portTransportType === 'POD'">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="textBody labelContent">
                                                            <span>
                                                                {{ "Original ETA" }}
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <DateTimeComponent
                                                        v-model="item.originalEstimatedArrivalDate"
                                                        :icon="'calendar_month'"
                                                        :rules="[]"
                                                        :outlined="true"
                                                        :dense="true"
                                                        :disabled="templateMode"
                                                        :filled="templateMode"
                                                        :defaultTime="'00:00'"
                                                        class="inputContent mb-n2"
                                                    >
                                                    </DateTimeComponent>
                                                    <v-row dense>
                                                        <v-col cols="auto" class="textBody labelContent">
                                                            <span>
                                                                {{ "ETA" }}
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <DateTimeComponent
                                                        v-model="item.estimatedArrivalDate"
                                                        :icon="'calendar_month'"
                                                        :rules="[]"
                                                        :outlined="true"
                                                        :dense="true"
                                                        :disabled="templateMode"
                                                        :filled="templateMode"
                                                        :defaultTime="'00:00'"
                                                        class="inputContent mb-n2"
                                                    >
                                                    </DateTimeComponent>
                                                    <v-row dense v-if="editMode">
                                                        <v-col cols="auto" class="textBody labelContent">
                                                            <span>
                                                                {{ "ATA" }}
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <DateTimeComponent
                                                        v-if="editMode"
                                                        v-model="item.actualArrivalDate"
                                                        :icon="'calendar_month'"
                                                        :rules="[]"
                                                        :outlined="true"
                                                        :dense="true"
                                                        :disabled="templateMode"
                                                        :filled="templateMode"
                                                        :defaultTime="'00:00'"
                                                        class="inputContent mb-n2"
                                                    >
                                                    </DateTimeComponent>
                                                </div>
                                                <div class="px-4" v-else>
                                                    <v-row dense>
                                                        <v-col cols="auto" class="textBody labelContent">
                                                            <span>
                                                                {{ "Original ETD" }}
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <DateTimeComponent
                                                        v-model="item.originalEstimatedDepartureDate"
                                                        :icon="'calendar_month'"
                                                        :rules="[]"
                                                        :outlined="true"
                                                        :dense="true"
                                                        :disabled="templateMode"
                                                        :filled="templateMode"
                                                        :defaultTime="'00:00'"
                                                        class="inputContent mb-n2"
                                                    >
                                                    </DateTimeComponent>
                                                    <v-row dense>
                                                        <v-col cols="auto" class="textBody labelContent">
                                                            <span>
                                                                {{ "ETD" }}
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <DateTimeComponent
                                                        v-model="item.estimatedDepartureDate"
                                                        :icon="'calendar_month'"
                                                        :rules="[]"
                                                        :outlined="true"
                                                        :dense="true"
                                                        :disabled="templateMode"
                                                        :filled="templateMode"
                                                        :defaultTime="'00:00'"
                                                        class="inputContent mb-n2"
                                                    >
                                                    </DateTimeComponent>
                                                    <v-row dense v-if="editMode">
                                                        <v-col cols="auto" class="textBody labelContent">
                                                            <span>
                                                                {{ "ATD" }}
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <DateTimeComponent
                                                        v-if="editMode"
                                                        v-model="item.actualDepartureDate"
                                                        :icon="'calendar_month'"
                                                        :rules="[]"
                                                        :outlined="true"
                                                        :dense="true"
                                                        :disabled="templateMode"
                                                        :filled="templateMode"
                                                        :defaultTime="'00:00'"
                                                        class="inputContent mb-n2"
                                                    >
                                                    </DateTimeComponent>
                                                </div>
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                class="contentDisplay"
                                                v-if="item.portTransportType !== 'POD' && !editMode"
                                            >
                                                <div class="px-4">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="textBody labelContent">
                                                            <span>
                                                                {{ $t("headers.booking_confirmation_number") }}
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-text-field
                                                        dense
                                                        v-model="item.bookingConfirmationNumber"
                                                        class="inputContent"
                                                        outlined
                                                        :disabled="templateMode || item.portTransportType === 'POD'"
                                                        :filled="templateMode || item.portTransportType === 'POD'"
                                                        @change="onUpdateSeaTransport(tripSeaTransport)"
                                                    />
                                                </div>
                                                <div class="px-4">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="textBody labelContent">
                                                            <span>
                                                                {{ $t("headers.cut_off_date_time") }}
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <DateTimeComponent
                                                        v-model="item.cutOffDateTime"
                                                        :icon="'calendar_month'"
                                                        :rules="[]"
                                                        :outlined="true"
                                                        :dense="true"
                                                        :disabled="templateMode"
                                                        :filled="templateMode"
                                                        :defaultTime="'00:00'"
                                                        class="inputContent mb-n2"
                                                    >
                                                    </DateTimeComponent>
                                                </div>
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                class="contentDisplay"
                                                v-if="item.portTransportType !== 'POD' && editMode"
                                            >
                                                <div class="px-4">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="textBody labelContent">
                                                            <span>
                                                                {{ $t("headers.cut_off_date_time") }}
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <DateTimeComponent
                                                        v-model="item.cutOffDateTime"
                                                        :icon="'calendar_month'"
                                                        :rules="[]"
                                                        :outlined="true"
                                                        :dense="true"
                                                        :disabled="templateMode"
                                                        :filled="templateMode"
                                                        :defaultTime="'00:00'"
                                                        class="inputContent mb-n2"
                                                    >
                                                    </DateTimeComponent>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        class="pt-0 mb-4 contentDisplay"
                                        v-if="item.portTransportType === 'POL'"
                                    >
                                        <v-card flat class="px-4">
                                            <v-btn @click="add()" elevation="0" class="primary">
                                                <!--  <v-icon small color="white">add</v-icon> -->
                                                Add T/S
                                            </v-btn>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-form>
        <!--  <v-card>
            <v-form>
                <v-row class="ma-0 pa-0 d-flex">
                    <v-spacer></v-spacer>
                    <div
                        v-if="tripContainers.length > 0"
                        class="d-flex justify-end"
                    >
                        <v-autocomplete
                            class="mt-3 mr-3"
                            v-model="containerAutofill"
                            :items="tripContainers"
                            :no-data-text="$t('loading.no_available')"
                            :label="$t('general.container')"
                            :disabled="isLoadingAutoFill || templateMode"
                            :filled="templateMode"
                            outlined
                            dense
                            hide-details
                            attach
                        ></v-autocomplete>
                        <v-btn
                            rounded
                            color="primary"
                            @click="autoFill"
                            :loading="isLoadingAutoFill"
                            :disabled="!containerAutofill"
                            class="mt-3 mr-3"
                        >
                            <div class="mr-1">{{ $t("forms.auto_fill") }}</div>
                            <v-icon>drive_file_rename_outline</v-icon>
                        </v-btn>
                    </div>
                    
                </v-row>
            </v-form>
        </v-card> -->
    </v-container>
</template>
<script>
//import DateComponent from "@/components/base/DateComponent.vue";
import DateTimeComponent from "@/components/base/DateTimeComponent.vue";
import gql from "graphql-tag";
import VesselAutoComplete from "@/components/trips/other/VesselAutoComplete.vue";

export default {
    components: {
        //DateComponent,
        DateTimeComponent,
        VesselAutoComplete
    },
    props: {
        tripSeaTransport: {
            type: Array
        },
        value: {
            type: Object
        },
        tripContainers: {
            type: Array
        },
        tripPartnerCarrierId: {
            type: String
        },
        templateMode: {
            type: Boolean,
            default: false
        },
        editMode: {
            type: Boolean,
            default: false
        }
    },
    apollo: {
        seaPorts: {
            query() {
                return gql`
                    query SeaPorts {
                        seaPorts {
                            edges {
                                node {
                                    country
                                    code
                                    id
                                    name
                                }
                            }
                        }
                    }
                `;
            },
            variables() {
                return {
                    orderBy: "name"
                };
            },
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            update(data) {
                return data.seaPorts;
            }
        }
    },
    data() {
        return {
            validate: false,
            type: [
                { text: this.$t("trips.vessel_name"), value: "shipname" },
                { text: this.$t("trips.call_sign"), value: "callsign" },
                { text: "IMO", value: "imo" },
                { text: "MMSI", value: "mmsi" }
            ],
            panel: [0, 1],
            isLoadingAutoFill: false,
            transhipments: [],
            ldBtn: false,
            saved: false,
            voyage: "",
            vesselInputType: "",
            vesselInputValue: "",
            sequenceNo: 0,
            containerAutofill: null,
            searchPortCode: "",
            seaPorts: { edges: [] }

            //hasMissingFields: false,
        };
    },
    computed: {
        tripLoadPoint: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        },
        isSmallScreen() {
            return this.$vuetify.breakpoint.width < 960;
        },
        hasMissingFields() {
            let missingFields = false;
            missingFields = this.tripSeaTransport.some(
                x => x.portCode === null || x.portCode === undefined || x.portCode === ""
            );
            if (missingFields === true) {
                return missingFields;
            } else {
                missingFields = this.tripSeaTransport.some(
                    x =>
                        x.portTransportType !== "POD" &&
                        (x.vesselInputType === null ||
                            x.vesselInputType === undefined ||
                            x.vesselInputType === "" ||
                            x.vesselInputValue === null ||
                            x.vesselInputValue === undefined ||
                            x.vesselInputValue === "")
                );
                return missingFields;
            }
        }
    },
    mounted() {
        //this.areFieldsMissing(this.tripSeaTransport);
    },
    watch: {
        isSeaTransport: {
            immediate: true,
            handler: function(newVal) {
                if (newVal === false) {
                    this.transhipments = [];
                    this.pol = {
                        sequence: 0,
                        voyage: "",
                        portCode: "",
                        portTransportType: "POL",
                        vesselInputType: "",
                        vesselInputValue: "",
                        originalEstimatedDepartureDate: "",
                        estimatedDepartureDate: "",
                        actualDepartureDate: "",
                        vgmCutOffDate: "",
                        bookingConfirmationNumber: "",
                        cutOffDateTime: ""
                    };
                    this.pod = {
                        sequence: 0,
                        portCode: "",
                        portTransportType: "POD",
                        originalEstimatedArrivalDate: "",
                        estimatedArrivalDate: "",
                        actualArrivalDate: "",
                        voyage: ""
                    };
                    this.tripLoadPoint = {
                        trade: "",
                        logco: "",
                        loadPoint: "",
                        loadDate: ""
                    };
                }
            },
            deep: true
        }
    },
    methods: {
        customFilter(item, queryText, itemText) {
            const searchTerm = queryText.toLowerCase();
            const code = item.node?.code?.toLowerCase() || "";
            const country = item.node?.country?.toLowerCase() || "";
            const city = item.node?.city?.toLowerCase() || "";

            return code?.includes(searchTerm) || city?.includes(searchTerm) || country?.includes(searchTerm);
        },
        add() {
            let _seaTransport = [...this.tripSeaTransport];

            _seaTransport.splice(-1, 0, {
                sequence: null,
                id: null,
                voyage: "",
                portCode: "",
                portTransportType: "T/S",
                vesselInputType: "",
                vesselInputValue: "",
                estimatedDepartureDate: "",
                vgmCutOffDate: "",
                bookingConfirmationNumber: "",
                cutOffDateTime: ""
            });

            _seaTransport.forEach((element, i) => (element.sequence = i));

            this.onUpdateSeaTransport(_seaTransport);
        },
        remove(index) {
            let _seaTransport = [...this.tripSeaTransport];

            _seaTransport.splice(index, 1);

            _seaTransport.forEach((element, i) => (element.sequence = i));

            this.onUpdateSeaTransport(_seaTransport);
        },
        getInputTypeText(value) {
            let result = null;
            if (value === null || value === undefined || value.length === 0) {
                result = "";
            } else {
                let type = this.type.find(type => type.value === value);
                result = type?.text;
            }

            return result;
        },
        autoFill() {
            this.isLoadingAutoFill = true;

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation AutoFillTripSeaTransport($input: AutoFillTripSeaTransportInput!) {
                            autoFillTripSeaTransport(input: $input) {
                                response {
                                    containerNumber
                                    ports {
                                        ata
                                        atd
                                        eta
                                        etd
                                        code
                                        location
                                        sequence
                                        type
                                        vessel
                                        voyage
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            containerNumber: this.containerAutofill,
                            tripPartnerCarrier: this.tripPartnerCarrierId
                        }
                    }
                })
                .then(res => {
                    let _tripseatransport = [];
                    res.data.autoFillTripSeaTransport.response.ports.map((port, i) => {
                        if (port.type === "POL") {
                            _tripseatransport.push({
                                sequence: i,
                                voyage: port.voyage,
                                portCode: port.code,
                                portTransportType: "POL",
                                vesselInputType: "shipname",
                                vesselInputValue: port.vessel,
                                estimatedDepartureDate: port.etd
                            });
                        } else if (port.type === "T/S") {
                            _tripseatransport.push({
                                sequence: i,
                                voyage: port.voyage,
                                portCode: port.code,
                                portTransportType: "T/S",
                                vesselInputType: "shipname",
                                vesselInputValue: port.vessel,
                                estimatedDepartureDate: port.etd,
                                estimatedArrivalDate: port.eta
                            });
                        } else if (port.type === "POD") {
                            _tripseatransport.push({
                                sequence: i,
                                portCode: port.code,
                                portTransportType: "POD",
                                estimatedArrivalDate: port.eta
                            });
                        }
                    });
                    this.$emit("update:tripSeaTransport", _tripseatransport);
                })
                .catch(() => {
                    const payload = {
                        color: "error",
                        message: "Couldn't get the container data"
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .finally(() => {
                    this.isLoadingAutoFill = false;
                });
        },
        onUpdateSeaTransport(seaArray) {
            let _tripSeaTransport = [...seaArray];
            let updateTripSeaTransport = [];

            let _pol = _tripSeaTransport.find(x => x.portTransportType === "POL");

            let _pod = _tripSeaTransport.find(x => x.portTransportType === "POD");

            let _ts = _tripSeaTransport.filter(x => x.portTransportType === "T/S");

            if (_pol !== undefined || _pol !== null || _pol !== "") {
                _pod.voyage = _pol.voyage;
            }

            _ts.forEach(item => {
                if (item.voyage !== undefined || item.voyage !== null || item.voyage !== "") {
                    _pod.voyage = item.voyage;
                }
            });

            updateTripSeaTransport.push(_pol);
            updateTripSeaTransport.push(_pod);
            updateTripSeaTransport.push(..._ts);
            updateTripSeaTransport.sort((a, b) => a.sequence - b.sequence);

            this.$emit("update:tripSeaTransport", updateTripSeaTransport);
        },
        seaTitles(type) {
            if (type === "POL") {
                return this.$t("headers.pol");
            } else if (type === "POD") {
                return this.$t("headers.pod");
            } else if (type === "T/S") {
                return this.$t("headers.ts");
            }
        }
    }
};
</script>
<style scoped>
.textTitle {
    font-size: 18px;
    font-weight: 700;
    color: "#0e1e46" !important;
}
.textSubTitle {
    font-size: 21px;
    font-weight: 700;
    color: "#0e1e46" !important;
}

.textBody {
    font-size: 12px;
    font-weight: 400;
    color: "#868599" !important;
}

/* .inputContent {
    height: 52px;
} */
.labelContent {
    height: 30px;
}
.contentDisplay {
    padding-left: 20px;
    padding-right: 20px;
}
.contentDisplay2 {
    padding-left: 20px;
    padding-right: 0px;
}
.contentDisplay3 {
    padding-left: 0px;
    padding-right: 20px;
}
</style>

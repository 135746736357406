<template>
    <v-row v-if="list">
        <table style="width:100%"
            class="py-2 px-3"
        >
            <colgroup>
                <col span="1" style="width: 50%;" />
                <col span="1" style="width: 50%;" />
            </colgroup>
            <tr v-for="(info, i) in list" :key="i" >
                <td class="classHeader ">{{ info.title }}</td>
                <td v-if="!tooltip" class="classText ">{{ info.value }}</td>
                <td v-else>
                    <div :style="info.style">
                        <v-tooltip bottom v-if="info.tooltip">
                            <template
                                v-slot:activator="{
                                    on,
                                    attrs
                                }"
                            >
                                <table v-bind="attrs" v-on="on" class="classText" style="width: 100%;">
                                    <colgroup>
                                        <col style="width: 30%;" />
                                        <col style="width: 50%;" />
                                      
                                    </colgroup>
                                    <tr style="text-align: left;">
                                        <th class="">
                                            {{ info.value }}
                                        </th>
                                        <td :class="info.delayColor" v-if="info.delay && info.delay !== '0'">
                                            {{ info.tooltip.title }}
                                        </td>
                                    </tr>
                                </table>
                            </template>
                            <v-row>
                                <v-col cols="12">
                                    <table style="width: 100%;">
                                        <thead style="text-align:left;">
                                            <tr>
                                                <th colspan="2">
                                                    {{ info.tooltip.title }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="mb-1">
                                                <th class="classHeader2">
                                                    {{ info.tooltip.headerOET }}
                                                </th>
                                                <td class="classText2">
                                                    {{ info.tooltip.OET }}
                                                </td>
                                            </tr>
                                            <tr class="mb-1">
                                                <th class="classHeader2">
                                                    {{ info.tooltip.headerET }}
                                                </th>
                                                <td class="classText2">
                                                    {{ info.tooltip.ET }}
                                                </td>
                                            </tr>
                                            <tr class="mb-1">
                                                <th class="classHeader2">
                                                    {{ info.tooltip.headerACT }}
                                                </th>
                                                <td class="classText2">
                                                    {{ info.tooltip.ACT }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </v-col>
                            </v-row>
                        </v-tooltip>
                    </div>
                </td>
            </tr>
        </table>
    </v-row>
</template>

<script>
export default {
    props: {
        list: {
            type: Array,
            required: false,
            default: () => {
                return [];
            }
        },
        tooltip: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {},
    data() {
        return {};
    },
    computed: {},
    watch: {},
    mounted() {},
    methods: {}
};
</script>
<style scoped lang="css">
.row-pointer >>> tbody tr :hover {
    cursor: pointer;
}
.center-loading {
    position: sticky;
    left: 50% !important;
}
.textTruncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.classMainContent {
    padding-left: 4px !important;
    padding-right: 4px !important;
    margin-top: 1px !important;
    margin-bottom: 16px !important;
}
.classSecondaryContent {
    padding-left: 40px !important;
    padding-right: 40px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.secondaryRowSize {
    width: 100% !important;
}

.classHeader {
    color: #868599 !important;
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 3px;
    padding-bottom: 4px !important;
    padding-top: 4px !important;
}

.class-text-tooltip {
    /* color: #0e0d35 !important; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 3px;
}

.classText {
    color: #0e0d35 !important;
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 3px;
    padding-bottom: 4px !important;
    padding-top: 4px !important;
}

.textTitle {
    font-size: 32px !important;
    color: "#0e1e46" !important;
    font-weight: bold;
    word-break: normal !important;
    line-height: inherit !important;
    text-align: center !important;
}
.textSubtitle {
    font-size: 13px !important;
    color: "#868599" !important;
    height: 23px !important;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.outlineD {
    border: 1px solid #e6e9f5;
    border-radius: 4px;
    padding: 0px 0px;
    font-size: 12px;
    font-weight: 600;
}

.backgroundColor {
    background-color: #f9fafe !important;
}

.textLeft {
    text-align: left !important;
}

.tabItemCl {
    background-color: var(--v-background-base);
}

.disabledItem {
    pointer-events: none;
    opacity: 0.4;
}

.classHeader2 {
    color: #fff !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
}

.classText2 {
    color: #fff !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 3px;
}
</style>
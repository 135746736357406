import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.list)?_c(VRow,[_c('table',{staticClass:"py-2 px-3",staticStyle:{"width":"100%"}},[_c('colgroup',[_c('col',{staticStyle:{"width":"50%"},attrs:{"span":"1"}}),_c('col',{staticStyle:{"width":"50%"},attrs:{"span":"1"}})]),_vm._l((_vm.list),function(info,i){return _c('tr',{key:i},[_c('td',{staticClass:"classHeader"},[_vm._v(_vm._s(info.title))]),(!_vm.tooltip)?_c('td',{staticClass:"classText"},[_vm._v(_vm._s(info.value))]):_c('td',[_c('div',{style:(info.style)},[(info.tooltip)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('table',_vm._g(_vm._b({staticClass:"classText",staticStyle:{"width":"100%"}},'table',attrs,false),on),[_c('colgroup',[_c('col',{staticStyle:{"width":"30%"}}),_c('col',{staticStyle:{"width":"50%"}})]),_c('tr',{staticStyle:{"text-align":"left"}},[_c('th',{},[_vm._v(" "+_vm._s(info.value)+" ")]),(info.delay && info.delay !== '0')?_c('td',{class:info.delayColor},[_vm._v(" "+_vm._s(info.tooltip.title)+" ")]):_vm._e()])])]}}],null,true)},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('table',{staticStyle:{"width":"100%"}},[_c('thead',{staticStyle:{"text-align":"left"}},[_c('tr',[_c('th',{attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(info.tooltip.title)+" ")])])]),_c('tbody',[_c('tr',{staticClass:"mb-1"},[_c('th',{staticClass:"classHeader2"},[_vm._v(" "+_vm._s(info.tooltip.headerOET)+" ")]),_c('td',{staticClass:"classText2"},[_vm._v(" "+_vm._s(info.tooltip.OET)+" ")])]),_c('tr',{staticClass:"mb-1"},[_c('th',{staticClass:"classHeader2"},[_vm._v(" "+_vm._s(info.tooltip.headerET)+" ")]),_c('td',{staticClass:"classText2"},[_vm._v(" "+_vm._s(info.tooltip.ET)+" ")])]),_c('tr',{staticClass:"mb-1"},[_c('th',{staticClass:"classHeader2"},[_vm._v(" "+_vm._s(info.tooltip.headerACT)+" ")]),_c('td',{staticClass:"classText2"},[_vm._v(" "+_vm._s(info.tooltip.ACT)+" ")])])])])])],1)],1):_vm._e()],1)])])})],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
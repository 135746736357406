<template>
    <v-card outlined class="rounded-lg pa-3">
        <v-card-title class="textTitle">
            <div class="mr-2">
                <v-icon class="iconColor">travel_explore</v-icon>
            </div>
            <span>{{ $t("general.overview") }}</span>
        </v-card-title>
        <v-card-text>
            <v-row class="my-3">
                <v-col cols="6">
                    <FormTableTemplate :list="tripTemplateResults.leftCol" :tooltip="false" />
                </v-col>
                <v-col cols="6">
                    <FormTableTemplate :list="tripTemplateResults.rightCol" :tooltip="false" />
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-card color="transparent" flat height="12px"></v-card>
            <v-row v-for="(f, i) in freightProp" :key="i"
                class="my-2 outlineD contentBg"
            >
                <v-col cols="4">
                    <FormTableTemplate :list="freightDetails(f).colLeft" :tooltip="false" />
                </v-col>
                <v-col cols="4">
                    <FormTableTemplate :list="freightDetails(f).colCenter" :tooltip="false" />
                </v-col>
                <v-col cols="4">
                    <FormTableTemplate :list="freightDetails(f).colRight" :tooltip="false" />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import helper from "@/utils/helper.js";
import FormTableTemplate from "@/components/triptemplates/formTemplate/FormTableTemplate.vue";

export default {
    props: {
        tripTemplate: {
            type: Object,
            required: false
        }
    },
    components: {
        FormTableTemplate
    },
    apollo: {},
    data() {
        return {
            // tripPartnersResult: { edges: [] },
            addPartnerDialog: false,
            deletePartnerDialog: false,
            saveLoadingBtn: false,
            deleteItem: {},
            openEditPartnerDialog: false,
            editPartner: {},
            updatedPartner: null,
            loadingDeleteBtn: false,
            selectedPartner: null,
            partnerDetailDrawer: false,
            partnerDetailsDialog: false,
            // partnersOnBoard: [],
            partners: [],
            headers: [
                {
                    text: this.$t("headers.reference_number"),
                    align: "left",
                    value: "node.tenantPartner.referenceNumber"
                },
                {
                    text: this.$t("headers.partner_name"),
                    align: "left",
                    value: "node.tenantPartner.name"
                },
                {
                    text: this.$t("headers.partner_type"),
                    align: "left",
                    value: "node.tenantPartner.partnerType"
                },
                {
                    text: this.$t("headers.enable_notification"),
                    align: "left",
                    value: "node.tenantPartner.notificationEnabled"
                },
                {
                    text: this.$t("headers.actions"),
                    align: "center",
                    value: "action"
                }
            ]
        };
    },
    beforeCreate() {
        this.hasPermission = helper.hasPermission;
        this.hasOrganisationPermission = helper.hasOrganisationPermission;
        this.isEmpty = helper.isEmpty;
    },
    computed: {
        me() {
            return helper.me();
        },
        tripTemplateResults() {
            let local = {
                leftCol: [],
                rightCol: []
            };

            if (this.tripTemplate !== null) {
                local.leftCol = [
                    {
                        title: this.$t("trips.origin"),
                        tag: "origin",
                        icon: null,
                        iconSize: 8,
                        colorIcon: "",
                        style: "",
                        align: "left",
                        value: this.tripTemplate?.origin?.name,
                        col: {
                            xs: 12,
                            sm: this.screenWidth < 700 ? 6 : 4,
                            md: this.screenWidth < 1295 ? 4 : 2,
                            lg: 2,
                            xl: 2
                        }
                    },
                    {
                        title: this.$t("trips.destination"),
                        tag: "destination",
                        icon: null,
                        iconSize: 8,
                        colorIcon: "",
                        style: "",
                        align: "left",
                        value: this.tripTemplate.destination.name,
                        col: {
                            xs: 12,
                            sm: this.screenWidth < 700 ? 6 : 4,
                            md: this.screenWidth < 1295 ? 4 : 2,
                            lg: 2,
                            xl: 2
                        }
                    },
                    {
                        title: this.$t("templates.template_description"),
                        tag: "description",
                        icon: null,
                        iconSize: 8,
                        colorIcon: "",
                        style: "",
                        align: "left",
                        value: this.tripTemplate.description,
                        col: {
                            xs: 12,
                            sm: this.screenWidth < 700 ? 6 : 4,
                            md: this.screenWidth < 1295 ? 4 : 2,
                            lg: 2,
                            xl: 2
                        }
                    }
                ];

                local.rightCol = [
                    {
                        title: this.$t("headers.carrier"),
                        tag: "carrier",
                        icon: null,
                        iconSize: 8,
                        colorIcon: "",
                        style: "",
                        align: "left",
                        value: this.tripTemplate.carrier.name,
                        col: {
                            xs: 12,
                            sm: this.screenWidth < 700 ? 6 : 4,
                            md: this.screenWidth < 1295 ? 4 : 2,
                            lg: 2,
                            xl: 2
                        }
                    },
                    {
                        title: this.$t("headers.partners"),
                        tag: "partners",
                        icon: null,
                        iconSize: 8,
                        colorIcon: "",
                        style: "",
                        align: "left",
                        value: this.tripTemplate?.partners?.map(partner => partner).join(", ") || "",
                        col: {
                            xs: 12,
                            sm: this.screenWidth < 700 ? 6 : 4,
                            md: this.screenWidth < 1295 ? 4 : 2,
                            lg: 2,
                            xl: 2
                        }
                    }
                ];
            }

            return local;
        },
        freightProp() {
            return this.tripTemplate?.freights || [];
        },
        primaryColor() {
            return this.$vuetify.theme.themes.light.primary;
        },
        isSmallScreen() {
            return this.$vuetify.breakpoint.width < 1300;
        }
    },
    mounted() {},
    methods: {
        textTruncate(text, length) {
            if (text === null || text === undefined) {
                return;
            } else if (text.length > length) {
                return text.substring(0, length) + "...";
            } else {
                return text;
            }
        },
        updateScrollbarColor(color) {
            document.documentElement.style.setProperty("--scrollbar-color", color);
        },
        freightDetails(freight) {
            let details = {
                colLeft: [
                    {
                        // for filter menu
                        title: this.$t("headers.goods"),
                        tag: "goods",
                        icon: null,
                        iconSize: 8,
                        colorIcon: "",
                        style: "",
                        align: "left",
                        value: freight.goods,
                        col: {
                            xs: 12,
                            sm: this.screenWidth < 700 ? 6 : 4,
                            md: this.screenWidth < 1295 ? 4 : 2,
                            lg: 2,
                            xl: 2
                        }
                    },
                    //terms
                    {
                        // for filter menu
                        title: this.$t("headers.terms"),
                        tag: "terms",
                        icon: null,
                        iconSize: 8,
                        colorIcon: "",
                        style: "",
                        align: "left",
                        value: freight.term,
                        col: {
                            xs: 12,
                            sm: this.screenWidth < 700 ? 6 : 4,
                            md: this.screenWidth < 1295 ? 4 : 2,
                            lg: 2,
                            xl: 2
                        }
                    }
                ],
                colRight: [
                    {
                        // for filter menu
                        title: this.$t("headers.trade"),
                        tag: "trade",
                        icon: null,
                        iconSize: 8,
                        colorIcon: "",
                        style: "",
                        align: "left",
                        value: freight.trade,
                        col: {
                            xs: 12,
                            sm: this.screenWidth < 700 ? 6 : 4,
                            md: this.screenWidth < 1295 ? 4 : 2,
                            lg: 2,
                            xl: 2
                        }
                    }
                ],
                colCenter: [
                    {
                        // for filter menu
                        title: this.$t("headers.load_points"),
                        tag: "loadPoint",
                        icon: null,
                        iconSize: 8,
                        colorIcon: "",
                        style: "",
                        align: "left",
                        value: freight.loadPoint,
                        col: {
                            xs: 12,
                            sm: this.screenWidth < 700 ? 6 : 4,
                            md: this.screenWidth < 1295 ? 4 : 2,
                            lg: 2,
                            xl: 2
                        }
                    },
                    //alerts
                    {
                        // for filter menu
                        title: this.$t("headers.alerts"),
                        tag: "alertRules",
                        icon: null,
                        iconSize: 8,
                        colorIcon: "",
                        style: "",
                        align: "left",
                        value: freight.alertRules?.map(alert => alert).join(", ") || "",
                        col: {
                            xs: 12,
                            sm: this.screenWidth < 700 ? 6 : 4,
                            md: this.screenWidth < 1295 ? 4 : 2,
                            lg: 2,
                            xl: 2
                        }
                    }
                ]
            };

            return details;
        },
        statusColor(el) {
            let color = {};
            if (el !== null) {
                if (el) {
                    color = {
                        color1: "#C8E6C9",
                        color2: "#1B5E20",
                        text: this.$t("general.enable")
                    };
                } else {
                    color = {
                        color1: "#FFCDD2",
                        color2: "#F44336",
                        text: this.$t("general.not_enable")
                    };
                }
            } else {
                color = {
                    color1: "#FFCDD2",
                    color2: "#F44336",
                    text: this.$t("general.not_enable")
                };
            }
            return color;
        }
    }
};
</script>
<style scoped>
/* hide the scrollbar of the v-dialog */
.outlineD {
    border: 1px solid #e6e9f5;
    border-radius: 4px;
    padding: 0px 0px;
    font-size: 12px;
    font-weight: 600;
    color: #e6e9f5;
}
.contentBg {
    background-color: #f9fafe;
}

.cardContainer::-webkit-scrollbar {
    width: 6px;
    border-radius: 5px;
}
.cardContainer::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #cfd0d5ed;
    border-radius: 5px;
}
.cardContainer::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color);
    border-radius: 5px;
}
.cardContainer::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-color);
}
.iconColor {
    color: #0e0d35;
}
.textTitle {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
}
.textTitle2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
}
.textHeader {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #555464;
}
.textBody {
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
}
.textDetails {
    font-size: 10px;
    font-weight: 400;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
}
.dialogCardContainer {
    max-width: 100%;
    max-height: 500px;
    overflow-y: auto;
}
</style>

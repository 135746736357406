<template>
    <v-card fill-height flat width="100%" v-if="template" color="background">
        <!-- top bar -->
        <v-toolbar flat color="transparent">
            <v-btn icon @click="$emit('close')">
                <v-icon>arrow_back_ios_new</v-icon>
            </v-btn>
            <v-btn v-if="shouldShowFullScreenButton" icon @click="changeFullScreen">
                <v-icon>{{ isFullScreen ? "fullscreen_exit" : "crop_free" }}</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <div>
                <!--Button to edit template-->
                <v-btn icon @click="editTripTemplate" >
                    <v-icon>edit</v-icon>
                </v-btn> 
                <!--Button to delete template-->
                <v-btn icon @click="openDeleteDialog = true">
                    <v-icon>delete</v-icon>
                </v-btn>
                
                <!-- <v-btn icon>
                    <v-icon> share </v-icon>
                </v-btn> -->
            </div>
        </v-toolbar>

        <!-- trip header -->
        <v-sheet
            class="
                pt-0 mb-5 px-5
                background
            "
        >
            <div class="textBody">
                {{ $t("headers.name") }}
            </div>
            <h3>{{ template ? template.name : "" }}</h3>
        </v-sheet>

        <!-- tab view -->
        <PreviewDetailTabView
            ref="tabView"
            v-if="viewType === 'tab'"
            :template="template"
            :selectedFilters="selectedFilters"
            :title="title"
            @updateTemplateTable="updateTemplateTable"
        />

        <DeleteDialog
            :dialog="openDeleteDialog"
            @confirmed="confiemDelete"
            :item="template"/>

    </v-card>
</template>

<script>
import gql from "graphql-tag";
import PreviewDetailTabView from "@/components/triptemplates/PreviewDetailTabView.vue";
import helper from "../../utils/helper";
import Config from "@/utils/config.json";
import DeleteDialog from "@/components/base/DeleteDialog.vue";

export default {
    props: {
        template: {
            type: Object,
            required: true
        },
        isFullScreen: {
            type: Boolean,
            required: false,
            default: false
        },
        title: {
            type: String,
            default: "templates"
        }
    },
    components: {
        // FilterMenu,
        PreviewDetailTabView,
        DeleteDialog
    },
    data() {
        return {
            viewType: "tab",
            viewTypeChoices: [{ code: "tab", name: this.$t("general.tab") }],
            openDeleteDialog: false,
            enableEditing: false,
            center: {
                lat: 40.73061,
                lng: -73.935242
            },

            defaultFilterChoices: [
                {
                    // for filter menu
                    code: "map",
                    name: "Details",
                    tag: "headers.details",
                    enabled: true
                }
            ],
            isDeleting: false,
            filterChoices: []
        };
    },
    computed: {
        selectedFilters() {
            // Return array only with enabled filters
            let _filters = [];
            _filters = this.filterChoices.filter(filter => filter.enabled);
            _filters.forEach(filter => {
                filter.name = this.$t(filter.tag);
            });
            return _filters;
        },
        shouldShowFullScreenButton() {
            return this.$vuetify.breakpoint.lgAndUp;
        },
        disabledButton() {
            if (this.title === "myLocations") {
                return false;
            } else if (this.title === "partnerLocations") {
                return false;
            } else {
                return true;
            }
        }
    },
    watch: {},
    mounted() {
        // watch viewType
        this.$watch(
            "viewType",
            function(value) {
                helper.updateMyPreferences({
                    templateDetailViewType: value
                });
            },
            { deep: true }
        );
        // watch filterChoices
        this.$watch(
            "filterChoices",
            function(value) {
                helper.updateMyPreferences({
                    templateDetailFilterChoices: value
                });
            },
            { deep: true }
        );
        //Gtag
        this.$gtag.screenview({
            app_name: Config.VUE_APP_APPLICATION_NAME,
            screen_name: "template_details_svw"
        });
        this.setUserProperties();
    },
    created() {
        this.viewType = helper.getViewType("templateDetailViewType");
        this.filterChoices = helper.getFilterChoices(this.defaultFilterChoices, "templateDetailFilterChoices");
    },
    methods: {
        setUserProperties() {
            if (this.me?.isPartnerUser) {
                this.me?.allTenantPartners.forEach(c => {
                    this.$gtag.event("template_details_svw", {
                        tenant_id: this.me?.tenant?.id,
                        tenant_name: this.me?.tenant?.name,
                        partner_id: c.id,
                        user_id: this.me?.id
                    });
                });
            } else {
                this.$gtag.event("template_details_svw", {
                    tenant_id: this.me?.tenant?.id,
                    tenant_name: this.me?.tenant?.name,
                    partner_id: null,
                    user_id: this.me?.id
                });
            }
        },
        updateTemplateTable() {
            this.$emit("refreshDataTable");
        },
        editTripTemplate() {
            //update vuex
            this.$store.dispatch("trip/updateCreatedTrip", {
                type: "update",
                trip: this.template
            });
            this.$router.push({
                path: "/newtrips",
                query: {
                    type: "editTemplate"
                }
            });
        },
        onFilterMenuChanged(choice) {
            this.filterChoices = choice;
        },
        changeFullScreen() {
            this.$emit("changeFullScreen", !this.isFullScreen);
        },
        confiemDelete() {
            // this.$emit("deleteTemplate", this.template);
            
            console.log("delete");
            this.isDeleting = true;

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation deleteTripTemplate($input: DeleteTripTemplateInput!) {
                            deleteTripTemplate(input: $input) {
                                success
                            }
                        }
                    `,
                    variables: {
                        input: {
                            ids: [this.template.id]
                        }
                    }
                })
                .then(() => {
                    this.openDeleteDialog = false;
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.isDeleting = false;
                    this.$emit("close");
                    this.$emit("refreshDataTable");
                });
        }
    }
};
</script>
<style scoped>
.v-select.fit {
    max-width: min-content;
    padding: 10px;
}
.v-select.fit .v-select__selection--comma {
    text-overflow: unset;
    padding-right: 1rem;
}

.v-select.fit .v-select__selections input {
    display: none;
}
.textTitle {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
}
.textBody {
    font-size: 12px !important;
    font-weight: 400 !important;
    color: "#868599" !important;
}
</style>

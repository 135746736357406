import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"outlined":"","rouned-lg":""}},[_c(VCardTitle,[_c(VRow,{attrs:{"dense":""}},[(!_vm.isMobile)?_c(VCard,{attrs:{"width":"220","color":"transparent","flat":""}}):_vm._e(),_c(VCol,{staticClass:"d-flex flex-row",attrs:{"cols":"12","xs":"12","sm":"12","md":"6","lg":"3","xl":"3"}},[_c('div',[_c(VIcon,{staticClass:"mr-2",attrs:{"size":"24","color":'#0e0d35'}},[_vm._v(_vm._s(_vm.title.icon))]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t(_vm.title.header)))])],1)])],1)],1),_c(VCardText,[_c(VTabs,{attrs:{"vertical":!_vm.isMobile},model:{value:(_vm.verticalTab),callback:function ($$v) {_vm.verticalTab=$$v},expression:"verticalTab"}},[_vm._l((_vm.personalTabs),function(personalTab,i){return _c(VTab,{key:i,staticClass:"d-flex justify-start",attrs:{"value":personalTab.value}},[_c('div',{staticClass:"tabsHeader text-capitalize"},[_vm._v(" "+_vm._s(_vm.$t(personalTab.name))+" ")])])}),_c(VTabItem,{attrs:{"value":_vm.verticalTab}},[_c(VRow,[(_vm.verticalTab === 0)?_c(VCol,{attrs:{"cols":"12"}},[_c('ProfileForm',{attrs:{"tenant":_vm.tenant},on:{"loading":_vm.onLoading}})],1):_vm._e(),(_vm.verticalTab === 1)?_c(VCol,{attrs:{"cols":"12"}},[_c('PreferencesForm',{attrs:{"tenant":_vm.tenant}}),_c('NotificationForm',{attrs:{"user":_vm.me},on:{"loading":_vm.onLoading}})],1):_vm._e(),(_vm.verticalTab === 2)?_c(VCol,{attrs:{"cols":"12"}},[_c('PreviewPasswordForm',{attrs:{"tenant":_vm.tenant},on:{"loading":_vm.onLoading}})],1):_vm._e()],1)],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
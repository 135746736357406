import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"outlined":"","rouned-lg":""}},[_c(VCardTitle,[_c(VRow,{attrs:{"dense":""}},[(!_vm.isMobile)?_c(VCard,{attrs:{"width":"190","color":"transparent","flat":""}}):_vm._e(),_c(VCol,{staticClass:"d-flex flex-row",attrs:{"cols":"12","xs":"12","sm":"12","md":"auto","lg":"auto","xl":"auto"}},[_c('div',[_c(VIcon,{staticClass:"mr-2",attrs:{"size":"24","color":'#0e0d35'}},[_vm._v("local_shipping")]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("general.trip_preferences")))])],1)]),_c(VCol,{attrs:{"cols":"auto"}},[_c(VRow,{attrs:{"dense":"","align":"center"}},[_c(VCol,{attrs:{"cols":"auto"}},[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("info")])],1),_c(VCol,{attrs:{"cols":"auto"}},[_c('div',{staticClass:"warningMessage"},[_vm._v(" "+_vm._s(_vm.$t("general.only_you_as_admin_user_can_see"))+" ")]),_c('div',{staticClass:"warningMessage"},[_vm._v(" "+_vm._s(_vm.$t("general.and_make_changes_in_these_settings"))+" ")])])],1)],1)],1)],1),_c(VCardText,[_c(VTabs,{attrs:{"vertical":!_vm.isMobile}},[_c(VTab,[_c('div',{staticClass:"tabsHeader text-capitalize"},[_vm._v(" "+_vm._s(_vm.$t("general.trip_preferences"))+" ")])]),_c(VTabItem,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('PreviewSettingsDetails',{attrs:{"tenant":_vm.tenant}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VCardTitle,{staticClass:"title"},[_c(VIcon,{staticClass:"mr-2",attrs:{"color":"error"}},[_vm._v("warning")]),_vm._t("title",function(){return [_vm._v(_vm._s(_vm.$t("general.delete_item")))]})],2),_c(VCardText,[_vm._t("content",function(){return [_vm._v(_vm._s(_vm.$t("general.sure_item")))]})],2),_c(VCardActions,{staticClass:"pb-5 pr-5"},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","rounded":""},on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.$t("general.cancel")))]),_c(VBtn,{attrs:{"color":"error","outlined":"","rounded":"","loading":_vm.isDeleting},on:{"click":_vm.confirmed}},[_vm._v(_vm._s(_vm.$t("general.delete")))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-card elevation="0" outlined v-resize="onResize" color="transparent">
        <v-card-text class="pa-0">
            <v-row dense align="center">
                <!-- <v-col cols="auto" class="pt-2">
                    <SortBy></SortBy>
                </v-col> -->
                <v-col cols="12" class="d-flex align-center justify-center" v-if="isLoading && !hasTemplates">
                    <LoadingBar></LoadingBar>
                </v-col>
                <v-col cols="12" v-else>
                    <div cols="12" v-if="hasTemplates">
                        <v-card
                            v-for="template in tableItems"
                            :key="template.id"
                            class="mb-4 rounded-lg px-2"
                            outlined
                            flat
                            @click.stop="detailsPage(template)"
                        >
                            <v-card-text>
                                <v-row dense>
                                    <v-col cols="auto" class="mr-3">
                                        <v-icon
                                            :color="transport(template.transportMode).colorIcon"
                                            :size="transport(template.transportMode).iconSize"
                                            :style="transport(template.transportMode).style"
                                        >
                                            {{ transport(template.transportMode).icon }}
                                        </v-icon>
                                    </v-col>
                                    <v-col class="mr-3">
                                        <v-row>
                                            <v-col
                                                v-for="(item, idx) in main(template)"
                                                :key="idx"
                                                :cols="item.col.xs"
                                                :xl="item.col.xl"
                                                :lg="item.col.lg"
                                                :md="item.col.md"
                                                :sm="item.col.sm"
                                                :xs="item.col.xs"
                                            >
                                                <div v-if="item.tag !== 'partners'">
                                                    <div class="textHeader">
                                                        {{ $t(item.title) }}
                                                    </div>
                                                    <div>
                                                        <span class="textBody">{{ item.value }}</span>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <div class="textHeader">
                                                        {{ $t(item.title) }}
                                                    </div>
                                                    <v-chip
                                                        v-for="partner in item.partners"
                                                        :key="partner"
                                                        class="mr-1 my-1"
                                                        small
                                                        label
                                                    >
                                                        {{ partner }}
                                                    </v-chip>
                                                </div>
                                            </v-col>
                                        </v-row>
                                        <v-row
                                            ref="freightInfo"
                                            v-for="(freight, ids) in template.freights"
                                            :key="ids"
                                            class="rounded-lg outlineD contentBg mb-2"
                                        >
                                            <v-col
                                                v-for="(item, idx) in secondary(freight, template.transportMode)"
                                                :key="idx"
                                                :cols="item.col.xs"
                                                :xl="item.col.xl"
                                                :lg="item.col.lg"
                                                :md="item.col.md"
                                                :sm="item.col.sm"
                                                :xs="item.col.xs"
                                            >
                                                <div class="textHeader">
                                                    {{ $t(item.title) }}
                                                </div>
                                                <div>
                                                    <span class="textBody">{{ item.value }}</span>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="auto">
                                        <v-menu
                                            offset-y
                                            transition="slide-x-reverse-transition"
                                            width="250px"
                                            attach
                                            left
                                            bottom
                                            close-on-click
                                            nudge-top="-3"
                                        >
                                            <template
                                                v-slot:activator="{
                                                    on,
                                                    attrs
                                                }"
                                            >
                                                <v-btn icon v-bind="attrs" v-on="on">
                                                    <v-icon>more_vert</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list class="white">
                                                <v-list-item>
                                                    <v-btn plain @click="deleteSelected(template.id)">
                                                        <v-icon left>
                                                            delete
                                                        </v-icon>
                                                        <span>{{ $t("general.delete") }}</span>
                                                    </v-btn>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </div>
                    <div v-else>
                        <v-row class="d-flex align-center justify-center">
                            <v-col cols="4" class="text-center">
                                <v-img class="mt-3" :src="require('@/assets/empty_data_small.png')"></v-img>
                                <v-card flat color="transparent">
                                    <v-card-title>
                                        <div class="textTitle">
                                            {{ $t("general.empty_data_title") }}
                                        </div>
                                    </v-card-title>
                                    <v-card-text class="px-0">
                                        <div class="textSubtitle">
                                            {{ $t("general.empty_data_subtitle") }}
                                        </div>
                                    </v-card-text>
                                    <v-card-actions class="px-10">
                                        <v-btn color="primary" @click="createTemplate()" block>
                                            {{ $t("templates.create_new_template") }}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="justify-center">
            <v-btn
                v-if="hasMoreData"
                v-intersect="onLoadMoreTriggerIntersect"
                :disabled="!hasMoreData"
                :loading="$apollo.queries.tripTemplates && $apollo.queries. tripTemplates.loading"
                plain
                class="mb-5"
                @click="loadMore"
            >
                Load more
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import gql from "graphql-tag";
import helper from "../../utils/helper";
import LoadingBar from "@/components/base/LoadingBar.vue";
//import SortBy from "@/components/base/SortBy.vue";

export default {
    name: "TripTemplatesTable",
    components: {
        LoadingBar
        //SortBy
        //FilterMenu,
        //AddTrip
    },
    props: {
        tripTemplatesFilter: {
            type: Object,
            required: false,
            default: () => {
                return {};
            }
        },
        selectMode: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    apollo: {
        tripTemplates: {
            query: gql`
                query tripTemplates(
                    $first: Int
                    $last: Int
                    $before: String
                    $after: String
                    $orderBy: String
                    $nameIcontains: String
                    $descriptionIcontains: String
                    $transportMode: String
                    $carrier: String
                    $goods: String
                    $loadPoint: String
                    $term: String
                    $partner: String
                ) {
                    tripTemplates(
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                        orderBy: $orderBy
                        name_Icontains: $nameIcontains
                        description_Icontains: $descriptionIcontains
                        transportMode: $transportMode
                        carrier: $carrier
                        goods: $goods
                        loadPoint: $loadPoint
                        term: $term
                        partner: $partner
                    ) {
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                        edges {
                            node {
                                id
                                lockTemplate
                                name
                                tripJson
                                description
                                origin
                                destination
                                carrier
                                partners
                                alertRules
                            }
                        }
                    }
                }
            `,
            variables() {
                return this.tripTemplatesFilter;
            },
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            debounce: 800,
            update: response => response.tripTemplates,
            watchLoading(isLoading) {
                this.isLoading = isLoading;
            },
            skip: false
        }
    },
    data() {
        return {
            tripTemplates: { edges: [] },
            selected: [],
            isLoading: true,
            openEditDialog: false,
            templatetoEdit: null,
            tableHeight: 800,
            searchInput: "",
            headerChoices: [],
            screenWidth: ""
        };
    },
    mounted() {
        this.$watch(
            "headerChoices",
            function(value) {
                helper.updateMyPreferences({
                    tripTemplatesTableHeaders: value
                });
            },
            { deep: true }
        );
    },
    created() {
        this.headerChoices = helper.getTableHeaders("tripTemplatesTableHeaders", this.defaultHeaderChoices);
        this.formatDateTime = helper.formatDateTime;
    },
    watch: {
        tripTemplatesFilter: {
            handler(newValue, oldValue) {
                let newValueString = JSON.parse(JSON.stringify(newValue));
                let oldValueString = JSON.parse(JSON.stringify(oldValue));
                if (newValueString !== oldValueString) {
                    this.isLoading = true;
                    this.tripTemplates = { edges: [] };
                }
            },
            deep: true
        }
    },
    computed: {
        hasTemplates() {
            return this.tripTemplates.edges.length > 0;
        },
        screenSize() {
            let _resposiveView = this.$vuetify.breakpoint.width;
            if (_resposiveView < 650) {
                return "xs";
            } else if (_resposiveView < 1000) {
                return "sm";
            } else if (_resposiveView < 1400) {
                return "md";
            } else if (_resposiveView < 1900) {
                return "lg";
            } else {
                return "xl";
            }
        },
        hasMoreData() {
            return this.tripTemplates?.pageInfo?.hasNextPage;
        },
        tableItems() {
            let items = [];
            if (!this.tripTemplates.edges) return items;
            this.tripTemplates.edges.forEach(({node}) => {
                let template = JSON.parse(node.tripJson);
               
                let goods = [];
                let terms = [];
                let loadPoint = [];
                template.freights.forEach(freight => {
                    if (freight.goods != null && freight.goods != "") goods.push(freight.goods);
                    if (freight.term != null && freight.term != "") terms.push(freight.term);
                    if (freight.loadPoint != null && freight.loadPoint != "") loadPoint.push(freight.loadPoint);
                });
                let departureTime = template.plannedDepartureTime;
                let arrivalTime = template.plannedArrivalTime;

                let item = {
                    id: node.id,
                    name: node.name,
                    description: node.description,
                    transportMode: template.transportMode,
                    carrier: {
                        id: template.carrier,
                        name: node.carrier
                    },
                    origin: { id: template.origin, name: node.origin },
                    departureTime: departureTime,
                    destination: { id: template.destination, name: node.destination },
                    arrivalTime: arrivalTime,
                    goods: goods,
                    terms: terms,
                    loadPoint: node.partners,
                    partnersIds: template.partners,
                    alertRules: node.alertRules,
                    trade: template.trade,
                    freights: template.freights,
                    seaTransport: template.seaTransports
                };
                items.push(item);
            });
            return items;
        }
    },
    methods: {
        detailsPage(item) {
            this.$emit("click:row", item);
        },
        editTemplate(item) {
            this.openEditDialog = true;
            this.templatetoEdit = item;
            this.$refs.addTrip.editTemplate(item);
        },
        transport(transportMode) {
            const iconRotate = mode => {
                return mode && (mode === "AIR"|| mode === "air" ) ? "transform: rotate(45deg);" : "";
            };

            return {
                title: null,
                tag: "icon",
                icon: this.getIcon(transportMode),
                iconSize: 20,
                colorIcon: "",
                style: iconRotate(transportMode),
                align: "left",
                value: null,
                col: {
                    xs: "auto",
                    sm: "auto",
                    md: "auto",
                    lg: "auto",
                    xl: "auto"
                }
            };
        },
        getIcon(name) {
            let icon = "";
            if (name !== null) {
                switch (name) {
                    case "air":
                    case "AIR":
                        icon = "flight";
                        break;
                    case "sea":
                    case "SEA":
                        icon = "directions_boat";
                        break;
                    case "road":
                    case "ROAD":
                        icon = "local_shipping";
                        break;
                    case "rail":
                    case "RAIL":
                        icon = "directions_railway";
                        break;
                    default:
                        icon = "help_outline";
                        break;
                }
            }
            return icon;
        },
        deleteSelected(id) {
            this.isLoading = true;
            let selectedIds = [];
            if (id) {
                selectedIds.push(id);
            }

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation deleteTripTemplate($input: DeleteTripTemplateInput!) {
                            deleteTripTemplate(input: $input) {
                                success
                            }
                        }
                    `,
                    variables: {
                        input: {
                            ids: selectedIds
                        }
                    }
                })
                .then(() => {
                    this.$apollo.queries.tripTemplates.refetch();
                    this.selected = [];
                })
                .finally(() => {
                    this.$emit("deleted-templates", false);
                });
        },
        onResize() {
            this.$nextTick(() => {
                this.tableHeight = window.innerHeight - 170 + "px";
            });
        },
        startScrolling(direction) {
            this.scrollInterval = setInterval(() => {
                const tableContainer = document.querySelector(".v-data-table__wrapper");
                const scrollAmount = direction === "left" ? -10 : 10;
                tableContainer.scrollLeft += scrollAmount;
            }, 10);
        },
        stopScrolling() {
            clearInterval(this.scrollInterval);
        },
        loadMore() {
            if (this.tripTemplates?.pageInfo?.hasNextPage) {
                this.$apollo.queries.tripTemplates.fetchMore({
                    variables: {
                        after: this.tripTemplates.pageInfo.endCursor
                    }
                });
            }
        },
        onLoadMoreTriggerIntersect(entries) {
            this.shouldLoadMore = entries[0].isIntersecting;

            if (this.shouldLoadMore && this.tripTemplates?.pageInfo?.hasNextPage) {
                this.loadMore();
            }
        },
        getCarrier() {
            this.$apollo.query({});
        },
        refreshTable() {
            this.isLoading = true;
            this.$apollo.queries.tripTemplates.refetch().finally(() => {
                this.isLoading = false;
            });
        },
        main(item) {
            /* block code */
            const containerStyle = tag => {
                return `background-color: ${
                    this.statusColor(tag).color1
                }; border-radius: 4px; width: 70px; display: flex; justify-content: center; align-items: center;`;
            };

            const formatDate = date => {
                return date ? this.formatDateTime(date) : "";
            };

            /*  const filterConsigneePartner = tripPartnerSet.edges.filter(
                ({ node }) => node.tenantPartner.partnerType === "consignee"
            ); */

            let _main = [
                {
                    title: "templates.template_name",
                    tag: "name",
                    icon: null,
                    iconSize: 8,
                    colorIcon: "",
                    style: "",
                    align: "left",
                    value: item.name,
                    col: {
                        xs: 12,
                        sm: this.screenWidth < 700 ? 6 : 4,
                        md: this.screenWidth < 1295 ? 4 : 2,
                        lg: 2,
                        xl: 2
                    }
                },
                {
                    title: "templates.template_description",
                    tag: "description",
                    icon: null,
                    iconSize: 8,
                    colorIcon: "",
                    style: "",
                    align: "left",
                    value: item.description,
                    col: {
                        xs: 12,
                        sm: this.screenWidth < 700 ? 6 : 4,
                        md: this.screenWidth < 1295 ? 4 : 2,
                        lg: 2,
                        xl: 2
                    }
                },

                {
                    title: "trips.origin",
                    tag: "origin",
                    icon: null,
                    iconSize: 8,
                    colorIcon: "",
                    style: "",
                    align: "left",
                    value: item.origin.name,
                    col: {
                        xs: 12,
                        sm: this.screenWidth < 700 ? 6 : 4,
                        md: this.screenWidth < 1295 ? 4 : 2,
                        lg: 2,
                        xl: 2
                    }
                },
                {
                    title: "trips.destination",
                    tag: "destination",
                    icon: null,
                    iconSize: 8,
                    colorIcon: "",
                    style: "",
                    align: "left",
                    value: item.destination.name,
                    col: {
                        xs: 12,
                        sm: this.screenWidth < 700 ? 6 : 4,
                        md: this.screenWidth < 1295 ? 4 : 2,
                        lg: 2,
                        xl: 2
                    }
                },
                {
                    title: "headers.carrier",
                    tag: "carrier",
                    icon: null,
                    iconSize: 8,
                    colorIcon: "",
                    style: "",
                    align: "left",
                    value: item.carrier.name,
                    col: {
                        xs: 12,
                        sm: this.screenWidth < 700 ? 6 : 4,
                        md: this.screenWidth < 1295 ? 4 : 2,
                        lg: 2,
                        xl: 2
                    }
                },
                {
                    title: "headers.partners",
                    tag: "partners",
                    icon: null,
                    iconSize: 8,
                    colorIcon: "",
                    style: "",
                    align: "left",
                    value: item.partners ? item.partners : [],
                    col: {
                        xs: 12,
                        sm: this.screenWidth < 700 ? 6 : 4,
                        md: this.screenWidth < 1295 ? 4 : 2,
                        lg: 2,
                        xl: 2
                    }
                }
            ];

            return _main;
        },
        secondary(item, transportMode) {
            
            let _secondary = [
                //goods
                {
                    // for filter menu
                    title: "headers.goods",
                    tag: "goods",
                    icon: null,
                    iconSize: 8,
                    colorIcon: "",
                    style: "",
                    align: "left",
                    value: item.goods,
                    col: {
                        xs: 12,
                        sm: this.screenWidth < 700 ? 6 : 4,
                        md: this.screenWidth < 1295 ? 4 : 2,
                        lg: 2,
                        xl: 2
                    }
                },
                //terms
                {
                    // for filter menu
                    title: "headers.terms",
                    tag: "terms",
                    icon: null,
                    iconSize: 8,
                    colorIcon: "",
                    style: "",
                    align: "left",
                    value: item.term,
                    col: {
                        xs: 12,
                        sm: this.screenWidth < 700 ? 6 : 4,
                        md: this.screenWidth < 1295 ? 4 : 2,
                        lg: 2,
                        xl: 2
                    }
                },
                //load point
                {
                    // for filter menu
                    title: "forms.load_point",
                    tag: "loadPoint",
                    icon: null,
                    iconSize: 8,
                    colorIcon: "",
                    style: "",
                    align: "left",
                    value: item.loadPoint,
                    col: {
                        xs: 12,
                        sm: this.screenWidth < 700 ? 6 : 4,
                        md: this.screenWidth < 1295 ? 4 : 2,
                        lg: 2,
                        xl: 2
                    }
                },
                //alerts
                {
                    // for filter menu
                    title: "headers.alerts",
                    tag: "alertRules",
                    icon: null,
                    iconSize: 8,
                    colorIcon: "",
                    style: "",
                    align: "left",
                    value: item.alertRules,
                    col: {
                        xs: 12,
                        sm: this.screenWidth < 700 ? 6 : 4,
                        md: this.screenWidth < 1295 ? 4 : 2,
                        lg: 2,
                        xl: 2
                    }
                },
                //trade
                {
                    // for filter menu
                    title: "headers.trade",
                    tag: "trade",
                    icon: null,
                    iconSize: 8,
                    colorIcon: "",
                    style: "",
                    align: "left",
                    value: item.trade,
                    col: {
                        xs: 12,
                        sm: this.screenWidth < 700 ? 6 : 4,
                        md: this.screenWidth < 1295 ? 4 : 2,
                        lg: 2,
                        xl: 2
                    }
                }
            ];

            return _secondary;
        },
        createTemplate() {
            this.$router.push({
                path: "/newtrips",
                query: {
                    type: "template"
                }
            });
        }
    }
};
</script>
<style scope>
.v-expansion-panel-content__wrap {
    padding: 0% !important;
}
.v-expansion-panel:not(:first-child)::after {
    border-top: none !important;
}
.outlined {
    outline: 1px solid #e6e9f5; /* Blue outline, 2px thick */
    outline-offset: 0px; /* Distance between the outline and the element */
}
.txtIndex {
    max-height: 19px;
    font-size: 12.4px;
    font-weight: 720;
    color: #a4a4a8;
}
.txtBody {
    max-height: 19px;
    font-size: 12.3px;
    font-weight: 650;
}
.maxHeight {
    max-height: 18px;
}
.outlineD {
    border: 1px solid #e6e9f5;
    border-radius: 4px;
    padding: 0px 0px;
    font-size: 12px;
    font-weight: 600;
}
.contentBg {
    background-color: #f9fafe;
}
.cardContainer {
    margin-bottom: 12px;
}
.cardContainer::-webkit-scrollbar {
    width: 6px;
    border-radius: 5px;
}
.cardContainer::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #cfd0d5ed;
    border-radius: 5px;
}
.cardContainer::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color);
    border-radius: 5px;
}
.cardContainer::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-color);
}
.textTitle {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
}
.textTitle2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
}
.textHeader {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #555464;
    margin-bottom: 1px;
}
.textBody {
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
}
.textDetails {
    font-size: 10px;
    font-weight: 400;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0d35;
}
.dialogCardContainer {
    max-width: 100%;
    max-height: 500px;
    overflow-y: auto;
}
.selectCursor {
    cursor: pointer;
}
</style>

<template>
    <v-sheet class="mt-5 mb-15 px-5">
        <div>
            <!-- No data  -->
            <LoadingBar v-if="!booking"></LoadingBar>
            <v-sheet v-else class="pa-0 " elevation="0">
                <v-card flat>
                    <v-card-text class="px-0 pb-0 pt-0">
                        <v-row class="px-3 py-1 d-flex justify-space-between" align="stretch">
                            <!-- container number -->
                            <v-col xl="6" lg="6" md="6" sm="12" xs="12" cols="12" class="elevation-0 py-0">
                                <v-row dense>
                                    <v-col cols="12">
                                        <div
                                            class="subtitle-1 primary--text d-flex align-start justify-start "
                                            :style="rounded"
                                        >
                                            <div>
                                                Shipping Details
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col cols="6">
                                        <table
                                            style="width:100%"
                                            v-for="(item, i) in sumaryDetailsOne.shippingDet"
                                            :key="i"
                                        >
                                            <colgroup>
                                                <col span="1" style="width: 50%;" />
                                                <col span="1" style="width: 50%;" />
                                            </colgroup>
                                            <tr>
                                                <td class="txtIndex text-start">
                                                    {{ item.key }}
                                                </td>
                                                <td class="txtBody text-end text-no-wrap text-truncate">
                                                    {{ truncate(item.value) }}
                                                </td>
                                            </tr>
                                        </table>
                                    </v-col>
                                    <v-col cols="6">
                                        <table
                                            style="width:100%"
                                            v-for="(item, i) in sumaryDetailsTwo.shippingDet"
                                            :key="i"
                                        >
                                            <colgroup>
                                                <col span="1" style="width: 50%;" />
                                                <col span="1" style="width: 50%;" />
                                            </colgroup>
                                            <tr>
                                                <td class="txtIndex text-start">
                                                    {{ item.key }}
                                                </td>
                                                <td class="txtBody text-end text-no-wrap text-truncate">
                                                    {{ truncate(item.value) }}
                                                </td>
                                            </tr>
                                        </table>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <!-- product details -->
                            <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12" class="elevation-0 py-0">
                                <v-row dense>
                                    <v-col cols="12">
                                        <div
                                            class="subtitle-1 primary--text d-flex align-start justify-start "
                                            :style="rounded"
                                        >
                                            <div>
                                                Product Details
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col cols="6">
                                        <table
                                            style="width:100%"
                                            v-for="(item, i) in sumaryDetailsOne.producSpec"
                                            :key="i"
                                        >
                                            <colgroup>
                                                <col span="1" style="width: 60%;" />
                                                <col span="1" style="width: 40%;" />
                                            </colgroup>
                                            <tr>
                                                <th rowspan="6" class="txtIndex text-start">
                                                    {{ item.key }}
                                                </th>
                                                <td class="txtBody text-end text-no-wrap">
                                                    <div>{{ truncate(item.value) }}</div>
                                                    <div class="caption" v-if="item.description">
                                                        {{ item.description }}
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </v-col>
                                    <v-col cols="6">
                                        <table
                                            style="width:100%"
                                            v-for="(item, i) in sumaryDetailsTwo.producSpec"
                                            :key="i"
                                        >
                                            <colgroup>
                                                <col span="1" style="width: 60%;" />
                                                <col span="1" style="width: 40%;" />
                                            </colgroup>
                                            <tr>
                                                <th rowspan="6" class="txtIndex text-start">
                                                    {{ item.key }}
                                                </th>
                                                <td class="txtBody text-end text-no-wrap ">
                                                    <div>{{ truncate(item.value) }}</div>
                                                    <div class="caption" v-if="item.description">
                                                        {{ item.description }}
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-expansion-panels full-width multiple class="elevation-0 mt-6" :style="borderTop" tile flat>
                            <v-row class="my-2 pr-14 pl-3 py-4">
                                <div class="subtitle-1 primary--text d-flex align-center ">
                                    <v-badge :color="transportOrderBadge.color" :content="transportOrderBadge.value"
                                        >Transport Orders</v-badge
                                    >
                                </div>
                                <v-spacer></v-spacer>
                                <TransportOrderForm
                                    ref="transForm"
                                    :booking="booking"
                                    :editMode="false"
                                    v-on:addTransportOrder="addOrUpdateTransportOrder"
                                ></TransportOrderForm>
                            </v-row>

                            <v-expansion-panel
                                v-for="item in booking.bookingTransportOrderQxSet.edges"
                                :key="item.node.id"
                                :style="border"
                                class="my-1 rounded-lg"
                            >
                                <v-expansion-panel-header class="py-0 px-2">
                                    <v-row dense>
                                        <v-col cols="2">
                                            <v-chip
                                                class="ma-2"
                                                :color="colorSchema(item.node.orderStatus)"
                                                label
                                                small
                                            >
                                                <v-icon left :color="colorIcon(item.node.orderStatus)" size="8">
                                                    circle
                                                </v-icon>
                                                <span class="text-capitalize">
                                                    {{ item.node.orderStatus }}
                                                </span>
                                            </v-chip>
                                        </v-col>
                                        <v-col cols="3" class="d-flex align-center">
                                            <div class="d-flex flex-row">
                                                <span class="txtIndex text-start mr-2">
                                                    Exporter Ref. :
                                                </span>
                                                <span class="txtBody text-end">
                                                    {{ item.node.exporterReferenceNumber }}
                                                </span>
                                            </div>
                                        </v-col>
                                        <v-col cols="3" class="d-flex align-center">
                                            <div class="d-flex flex-row">
                                                <span class="txtIndex text-start mr-2">
                                                    Booking Ref. :
                                                </span>
                                                <span class="txtBody text-end">
                                                    {{ item.node.bookingReference }}
                                                </span>
                                            </div>
                                        </v-col>
                                        <v-col cols="3" class="d-flex align-center">
                                            <div class="d-flex flex-row">
                                                <span class="txtIndex text-start mr-2">
                                                    CTO number:
                                                </span>
                                                <span class="txtBody text-end">
                                                    {{ item.node.ctoNumber }}
                                                </span>
                                            </div>
                                        </v-col>
                                        <v-spacer></v-spacer>
                                        <v-col cols="auto" class="px-0">
                                            <!-- delete dialog -->
                                            <v-dialog v-model="deleteDialog" max-width="500px">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        icon
                                                        v-on="on"
                                                        @click="deleteDialog = true"
                                                        :disabled="
                                                            booking.orderStatus !== 'draft' &&
                                                                (!me.isTenantUser ||
                                                                    booking.orderStatus === 'confirmed')
                                                        "
                                                    >
                                                        <v-icon>delete</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-card>
                                                    <v-card-title class="title primary">
                                                        <v-icon color="white">delete</v-icon>
                                                        <span class="ml-2 white--text">
                                                            Delete
                                                        </span>
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <div class="pt-4">
                                                            Are you sure you want to delete this transport order?
                                                        </div>
                                                    </v-card-text>
                                                    <v-card-actions class="py-1">
                                                        <v-spacer></v-spacer>
                                                        <v-btn
                                                            color="primary"
                                                            text
                                                            rounded
                                                            @click="deleteDialog = false"
                                                        >
                                                            Close
                                                        </v-btn>
                                                        <v-btn
                                                            rounded
                                                            color="primary"
                                                            :loading="loadingDeleteBtn"
                                                            @click="deleteOrder(item.node.id)"
                                                        >
                                                            OK
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-dialog>
                                        </v-col>
                                        <v-col cols="auto">
                                            <TransportOrderForm
                                                ref="transForm"
                                                :order="item.node"
                                                :booking="booking"
                                                :editMode="true"
                                                v-on:updateBookingOrder="addOrUpdateTransportOrder"
                                            ></TransportOrderForm>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="my-2 px-5 py-2">
                                    <table style="width:100%">
                                        <v-row dense>
                                            <!-- Transport Specification -->
                                            <v-col cols="12">
                                                <div
                                                    class="subtitle-1 primary--text d-flex align-start justify-start"
                                                    :style="rounded"
                                                >
                                                    <div>
                                                        Loading Details
                                                    </div>
                                                </div>
                                            </v-col>

                                            <v-col cols="6" class="py-0">
                                                <colgroup>
                                                    <col span="1" style="width: 50%;" />
                                                    <col span="1" style="width: 50%;" />
                                                </colgroup>
                                                <tr>
                                                    <td class="txtIndex text-start">
                                                        Container number
                                                    </td>
                                                    <v-spacer></v-spacer>
                                                    <td class="txtBody text-end text-no-wrap">
                                                        {{ item.node.containerNumber }}
                                                    </td>
                                                </tr>
                                                <colgroup>
                                                    <col span="1" style="width: 60%;" />
                                                    <col span="1" style="width: 40%;" />
                                                </colgroup>
                                                <tr>
                                                    <td class="txtIndex text-start">
                                                        Empty Load Point
                                                    </td>
                                                    <v-spacer></v-spacer>
                                                    <td class="txtBody text-end text-no-wrap">
                                                        {{ item.node.emptyLoadPoint }}
                                                    </td>
                                                </tr>
                                                <colgroup>
                                                    <col span="1" style="width: 50%;" />
                                                    <col span="1" style="width: 50%;" />
                                                </colgroup>
                                                <tr>
                                                    <td class="txtIndex text-start">
                                                        Load point 1
                                                    </td>
                                                    <v-spacer></v-spacer>
                                                    <td class="txtBody text-end text-no-wrap">
                                                        {{ item.node.loadPoint1 }}
                                                    </td>
                                                </tr>
                                                <colgroup v-if="item.node.loadPoint2">
                                                    <col span="1" style="width: 50%;" />
                                                    <col span="1" style="width: 50%;" />
                                                </colgroup>
                                                <tr>
                                                    <td class="txtIndex text-start">
                                                        Load point 2
                                                    </td>
                                                    <v-spacer></v-spacer>
                                                    <td
                                                        class="txtBody text-end text-no-wrap"
                                                        v-if="item.node.loadPoint2"
                                                    >
                                                        {{ item.node.loadPoint2 }}
                                                    </td>
                                                </tr>
                                                <colgroup>
                                                    <col span="1" style="width: 60%;" />
                                                    <col span="1" style="width: 40%;" />
                                                </colgroup>
                                                <tr>
                                                    <td class="txtIndex text-start">
                                                        Genset is required
                                                    </td>
                                                    <v-spacer></v-spacer>
                                                    <td class="txtBody text-end text-no-wrap">
                                                        {{ item.node.gensetIsRequired }}
                                                    </td>
                                                </tr>
                                                <colgroup>
                                                    <col span="1" style="width: 50%;" />
                                                    <col span="1" style="width: 50%;" />
                                                </colgroup>
                                                <tr v-if="me.isTenantUser">
                                                    <td class="txtIndex text-start">
                                                        Transport type
                                                    </td>
                                                    <v-spacer></v-spacer>
                                                    <td class="txtBody text-end text-no-wrap">
                                                        {{ item.node.transportType }}
                                                    </td>
                                                </tr>
                                            </v-col>

                                         

                                            <!-- primary Details -->
                                            <v-col cols="6" class="py-0">
                                                <colgroup>
                                                    <col span="1" style="width: 50%;" />
                                                    <col span="1" style="width: 50%;" />
                                                </colgroup>
                                                <tr>
                                                    <td class="txtIndex text-start">
                                                        Load date & time
                                                    </td>
                                                    <v-spacer></v-spacer>
                                                    <td class="txtBody text-end text-no-wrap">
                                                        {{ qxFormatDateTime(item.node.loadDate) }}
                                                    </td>
                                                </tr>
                                                <colgroup>
                                                    <col span="1" style="width: 50%;" />
                                                    <col span="1" style="width: 50%;" />
                                                </colgroup>
                                                <tr>
                                                    <td class="txtIndex text-start">
                                                        Solas method
                                                    </td>
                                                    <v-spacer></v-spacer>
                                                    <td class="txtBody text-end text-no-wrap">
                                                        {{ item.node.solasMethod }}
                                                    </td>
                                                </tr>
                                                <colgroup>
                                                    <col span="1" style="width: 50%;" />
                                                    <col span="1" style="width: 50%;" />
                                                </colgroup>
                                                <tr>
                                                    <td class="txtIndex text-start">
                                                        Weigh bridge
                                                    </td>
                                                    <v-spacer></v-spacer>
                                                    <td class="txtBody text-end text-no-wrap">
                                                        {{ item.node.weighBridge }}
                                                    </td>
                                                </tr>
                                                <colgroup>
                                                    <col span="1" style="width: 50%;" />
                                                    <col span="1" style="width: 50%;" />
                                                </colgroup>
                                                <tr>
                                                    <td class="txtIndex text-start">
                                                        Seal number
                                                    </td>
                                                    <v-spacer></v-spacer>
                                                    <td class="txtBody text-end text-no-wrap">
                                                        {{ item.node.sealNumber }}
                                                    </td>
                                                </tr>
                                                <colgroup>
                                                    <col span="1" style="width: 50%;" />
                                                    <col span="1" style="width: 50%;" />
                                                </colgroup>
                                                <tr>
                                                    <td class="txtIndex text-start">
                                                        Temp Device Description
                                                    </td>
                                                    <v-spacer></v-spacer>
                                                    <td class="txtBody text-end text-no-wrap">
                                                        <span v-if="$apollo.queries.tempDevices.loading"
                                                            >Loading...</span
                                                        >
                                                        <span v-else>
                                                            {{
                                                                gettempDevices(item.node.tempRecorderTypeId).Description
                                                            }}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <colgroup>
                                                    <col span="1" style="width: 50%;" />
                                                    <col span="1" style="width: 50%;" />
                                                </colgroup>
                                                <tr>
                                                    <td class="txtIndex text-start">
                                                        Temp Device Number
                                                    </td>
                                                    <v-spacer></v-spacer>
                                                    <td class="txtBody text-end">
                                                        <span v-if="$apollo.queries.tempDevices.loading"
                                                            >Loading...</span
                                                        >
                                                        <span v-else>
                                                            {{ gettempDevices(item.node.tempRecorderTypeId).Code }}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </v-col>
                                        </v-row>
                                    </table>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <v-expansion-panels full-width multiple class="elevation-0 mt-3" :style="borderTop" tile flat>
                            <v-row class="my-2 py-4">
                                <v-col class="subtitle-1 py-0 primary--text d-flex align-center">
                                    Change Request
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="auto" class="py-0">
                                    <ChangeRequest
                                        ref="requestForm"
                                        :booking="booking"
                                        :editMode="false"
                                        v-on:changeRequestAdded="addOrUpdateTransportOrder"
                                    ></ChangeRequest>
                                </v-col>
                            </v-row>

                            <v-expansion-panel v-if="booking.orderStatus === 'draft'">
                                <v-row class="my-2">
                                    <v-col cols="12" class="text-center text-bold">
                                        Order is in draft state
                                    </v-col>
                                </v-row>
                            </v-expansion-panel>
                            <v-expansion-panel
                                v-if="
                                    booking.orderStatus !== 'draft' &&
                                        booking.bookingOrderChangeRequestQxSet.edges.length < 1
                                "
                            >
                                <v-row class="my-2">
                                    <v-col cols="12" class="text-center text-bold">
                                        No change request
                                    </v-col>
                                </v-row>
                            </v-expansion-panel>
                            <v-expansion-panel
                                v-else
                                v-for="request in booking.bookingOrderChangeRequestQxSet.edges"
                                :key="request.node.id"
                                :style="border"
                                class="my-1 rounded-lg"
                            >
                                <v-expansion-panel-header class="py-0 px-2">
                                    <v-row>
                                        <v-col cols="2">
                                            <v-chip class="ma-2" :color="colorSchema(request.node.status)" label small>
                                                <v-icon left :color="colorIcon(request.node.status)" size="8">
                                                    circle
                                                </v-icon>
                                                <span class="text-capitalize">
                                                    {{ request.node.status }}
                                                </span>
                                            </v-chip>
                                        </v-col>
                                        <v-col cols="3" class="d-flex align-center">
                                            <div class="d-flex flex-row ">
                                                <div class="txtIndex text-start mr-2">
                                                    Title:
                                                </div>

                                                <span class="txtBody text-end">
                                                    {{ request.node.title }}
                                                </span>
                                            </div>
                                        </v-col>
                                        <v-col cols="3" class="d-flex align-center">
                                            <div class="d-flex flex-row ">
                                                <span class="txtIndex text-start mr-2">
                                                    Requested Date:
                                                </span>
                                                <v-spacer></v-spacer>
                                                <span class="txtBody text-end">
                                                    {{ formatDateTime(request.node.createdAt) }}
                                                </span>
                                            </div>
                                        </v-col>
                                        <v-col cols="3" class="d-flex align-center">
                                            <div class="d-flex flex-row ">
                                                <span class="txtIndex text-start mr-2">
                                                    Last Modified Date:
                                                </span>
                                                <v-spacer></v-spacer>
                                                <span class="txtBody text-end">
                                                    {{ formatDateTime(request.node.modifiedAt) }}
                                                </span>
                                            </div>
                                        </v-col>
                                        <v-spacer></v-spacer>
                                        <v-col cols="auto" class="pl-0">
                                            <ChangeRequest
                                                ref="requestForm"
                                                :booking="booking"
                                                :editMode="true"
                                                :changeRequestObj="request.node"
                                                v-on:changeRequestAdded="addOrUpdateTransportOrder"
                                            ></ChangeRequest>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="my-2 px-5 py-2">
                                    <table style="width:100%">
                                        <v-row dense>
                                            <!-- Transport Specification -->
                                            <v-col cols="4" class="py-0">
                                                <div class="subtitle-1 primary--text ">
                                                    Request details
                                                </div>
                                                <colgroup>
                                                    <col span="1" style="width: 50%;" />
                                                    <col span="1" style="width: 50%;" />
                                                </colgroup>
                                                <tr>
                                                    <td class="txtIndex text-start">
                                                        Requested By
                                                    </td>
                                                    <v-spacer></v-spacer>
                                                    <td class="txtBody text-start text-no-wrap">
                                                        {{
                                                            request.node.createdBy
                                                                ? `${request.node.createdBy.firstName} ${request.node.createdBy.lastName}`
                                                                : ""
                                                        }}
                                                    </td>
                                                </tr>
                                                <colgroup>
                                                    <col span="1" style="width: 50%;" />
                                                    <col span="1" style="width: 50%;" />
                                                </colgroup>
                                                <tr>
                                                    <td class="txtIndex text-start">
                                                        Request
                                                    </td>
                                                    <v-spacer></v-spacer>
                                                    <td class="txtBody text-start text-no-wrap">
                                                        {{ request.node.content }}
                                                    </td>
                                                </tr>
                                            </v-col>
                                        </v-row>
                                    </table>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card-text>
                </v-card>
                <!-- freight info -->
            </v-sheet>
        </div>
    </v-sheet>
</template>

<script>
import helper from "@/utils/helper.js";
import LoadingBar from "@/components/base/LoadingBar.vue";
import TransportOrderForm from "../forms/TransportOrderForm.vue";
import ChangeRequest from "@/components/bookings/create/ChangeRequest.vue";
import gql from "graphql-tag";
import moment from "moment";

export default {
    props: {
        booking: {
            type: Object,
            required: true
        },
        isNewlyCreated: {
            type: Boolean,
            default: false
        }
    },
    components: {
        TransportOrderForm,
        ChangeRequest,
        LoadingBar
    },
    apollo: {
        tempCodes: {
            query: gql`
                query QxTempCodes($tempCodeId: String) {
                    qxTempCodes(tempCodeId: $tempCodeId) {
                        Code
                        Description
                        Temperature
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    tempCodeId: this.booking.ppecbTempCodeId
                };
            },
            // Additional options here
            update: data => data.qxTempCodes,
            fetchPolicy: "no-cache",
            result({ loading, data }) {
                if (!loading) {
                    this.tempCodes = [...data.qxTempCodes];
                }
            }
        },
        tempDevices: {
            query: gql`
                query QxTempDevices($tempDeviceId: String) {
                    qxTempDevices(tempDeviceId: $tempDeviceId) {
                        Code
                        Description
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    // tempDeviceId: ""
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxTempDevices,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            }
        }
    },
    data() {
        return {
            tempDevicesMap: {},
            panel: [0, 1],
            panelFreight: [],
            tripFreights: {},
            createDialog: false,
            deleteDialog: false,
            item: {},
            deletedItem: {},
            validateForm: false,
            toggleTitle: false,
            loadingDeleteBtn: false,
            loadingCreateEditBtn: false,
            carousel: 0,
            tripFreight: { edges: [] },
            currentTrackers: [],
            openOderDialog: false,
            requestDialog: false,
            tempCodes: []
        };
    },
    beforeCreate() {},
    created() {
        this.formatDateTime = helper.formatDateTime;
        this.qxFormatDateTime = helper.qxFormatDateTime;
    },
    watch: {},
    mounted() {
        // if (this.$route.query.code && this.booking?.id) {
        if (this.isNewlyCreated) {
            this.$nextTick(() => {
                this.$refs?.transForm?.create();
            });
            // this.$refs?.transForm?.create();
        }
    },
    computed: {
        me() {
            return helper.me();
        },
        hasTripFreight() {
            return this.tripFreights?.edges?.length > 0;
        },
        primaryTheme() {
            if (this.$vuetify.theme.themes.light.primary) {
                return this.$vuetify.theme.themes.light.primary;
            } else {
                return "#1976d2";
            }
        },
        borderRight() {
            return `border-right: 1px solid ${this.primaryTheme};`;
        },
        borderBottom() {
            return `border-bottom: 1px solid ${this.primaryTheme};`;
        },
        borderTop() {
            return `border-top: 1px solid ${this.primaryTheme};`;
        },
        border() {
            return `border: 1px solid ${this.primaryTheme};`;
        },
        rounded() {
            return ` width: 140px;`;
        },

        sumaryDetailsOne() {
            let obj = {
                shippingDet: [],
                producSpec: []
            };

            const shippingMappings = {
                vesselName: { index: 1, key: "Vessel name" },
                voyageNo: { index: 2, key: "Voyage number" },
                pol: { index: 3, key: "Pol" },
                pod: { index: 4, key: "Pod" },
                finalDestination: { index: 5, key: "Final destination" },
                etd: { index: 6, key: "ETD" },
                eta: { index: 7, key: "ETA" }
            };

            const productMappings = {
                numberOfShipments: { index: 1, key: "Number of Containers" },
                commodity: { index: 2, key: "Commodity" },
                ppecbTempCodeId: { index: 3, key: "PPECB Temp. Code" },
                vents: { index: 4, key: "Vents" },
                numberOfProbes: { index: 6, key: "Number of probes" }
            };
            
            Object.entries(this.booking).forEach(([key, value]) => {
                // More robust datetime check
                
                if (value && typeof value === 'string' && helper.isDateTimeValid(value) && value.length > 10) {
                    value = this.qxFormatDateTime(value);
                }

                if (key !== "ppecbTempCodeId" && shippingMappings[key]) {
                    obj.shippingDet.push({
                        ...shippingMappings[key],
                        value
                    });
                } else if (key === "ppecbTempCodeId") {
                    obj.producSpec.push({
                        ...productMappings[key],
                        description: this.tempCodes[0]?.Description,
                        value
                    });
                } else if (productMappings[key]) {
                    obj.producSpec.push({
                        ...productMappings[key],
                        value
                    });
                }
            });

            obj.shippingDet.sort((a, b) => a.index - b.index);
            obj.producSpec.sort((a, b) => a.index - b.index);
            return obj;
        },
        sumaryDetailsTwo() {
            let obj = {
                shippingDet: [],
                producSpec: []
            };

            const shippingMappings = {
                shippingLine: { index: 1, key: "Shipping line" },
                contractNumber: { index: 2, key: "Contract number" },
                freightPayLocation: { index: 3, key: "Freight pay location" },
                shippingLineComment: { index: 4, key: "Shipping line comment" },
                shippedOnBoard: { index: 5, key: "Shipped on board" },
                agentCode: { index: 6, key: "Agent code" },
                logisticContact: { index: 7, key: "Logistic contact" }
            };

            const productMappings = {
                steri: { index: 1, key: "Steri" },
                steriProtocol: { index: 2, key: "Steri protocol" },
                approxGrossWeight: { index: 3, key: "Approx. gross weight" },
                importPermitNumber: { index: 4, key: "Import permit number" }
            };

            Object.entries(this.booking).forEach(([key, value]) => {
                // More robust datetime check
                if (value && typeof value === 'string' && helper.isDateTimeValid(value) && value.length > 10) {
                    value = this.qxFormatDateTime(value);
                }
                
                if (shippingMappings[key]) {
                    obj.shippingDet.push({
                        ...shippingMappings[key],
                        value
                    });
                } else if (productMappings[key]) {
                    obj.producSpec.push({
                        ...productMappings[key],
                        value
                    });
                }
            });

            obj.shippingDet.sort((a, b) => a.index - b.index);
            obj.producSpec.sort((a, b) => a.index - b.index);
            return obj;
        },
        transportOrderBadge() {
            let demonimator = this.booking.numberOfShipments,
                numerator = this.booking.bookingTransportOrderQxSet?.edges.length;
            return {
                value: `${numerator}/${demonimator}`,
                color: demonimator != numerator ? "error" : "success"
            };
        }
    },
    methods: {
        createRequest() {
            this.requestDialog = true;
            this.$refs.requestForm.create();
        },
        colorSchema(status) {
            switch (status) {
                case "pending":
                    return "#D6E4EE";
                case "approved":
                    return "#C8E6C9";
                case "denied":
                    return "#FFCDD2";
                default:
                    return "#FFECB3";
            }
        },
        colorIcon(status) {
            switch (status) {
                case "pending":
                    return "#1E88E5";
                case "approved":
                    return "#43A047";
                case "denied":
                    return "#E53935";
                default:
                    return "#FFB300";
            }
        },
        truncate(value) {
            if (value) {
                return value.length > 30 ? value.substring(0, 30) + "..." : value;
            }
        },
        gettempDevices(id) {
            let temperatureDevices = this.tempDevices || [];
            let findObject = temperatureDevices.find(x => {
                return x.Code === id;
            });

            return (
                temperatureDevices.find(x => x.Code === id) || {
                    Code: "",
                    Description: ""
                }
            );
        },
        addOrUpdateTransportOrder(item) {
            this.$emit("onBookingUpdated");
        },
        fixedNumber(x) {
            let fix = x.toFixed(2);
            return fix;
        },
        addTransportOrder(value) {
            console.log(value);
        },
        deleteItem(e) {
            this.deletedItem = {
                id: e.id,
                referenceNumber: e.referenceNumber
            };
            this.deleteDialog = true;
        },
        cancelDeleteDialog() {
            this.deleteDialog = false;
            this.deletedItem = {};
        },
        deleteOrder(id) {
            this.loadingDeleteBtn = true;
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation deleteBookingTransportOrderQx($input: DeleteBookingTransportOrderQxInput!) {
                            deleteBookingTransportOrderQx(input: $input) {
                                success
                            }
                        }
                    `,
                    variables: {
                        input: {
                            tenantId: this.me?.tenant?.id || "",
                            bookingTransportOrderQxId: id
                        }
                    }
                })
                .then(() => {
                    this.$emit("onBookingUpdated");
                })
                .catch(error => {
                    console.log("error", error.message);
                    const payload = {
                        color: "error",
                        message: error.message
                        // message: this.$t('errors.user_no_permission')
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .finally(() => {
                    this.deleteDialog = false;
                    this.loadingDeleteBtn = false;
                });
        },
        closeFreightForm() {
            this.createDialog = false;
            this.$refs.freightForm.resetForm();
        },
        getDate(date) {
            return date?.slice(0, 10);
        },
        getTime(date) {
            return this.formatDateTime(date)?.slice(11, 16);
        }
    }
};
</script>

<style scope>
.v-expansion-panel-content__wrap {
    padding: 0% !important;
}
.v-expansion-panel:not(:first-child)::after {
    border-top: none !important;
}
.txtIndex {
    max-height: 16px;
    font-size: 11px;
    font-weight: 600;
    color: #000000;
}
.txtBody {
    max-height: 16px;
    font-size: 10px;
    font-weight: 600;
}
.maxHeight {
    max-height: 18px;
}
</style>

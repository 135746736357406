<template>
    <v-card outlined rouned-lg>
        <v-card-title>
            <v-row dense>
                <v-card v-if="!isMobile" width="190" color="transparent" flat></v-card>
                <v-col cols="12" xs="12" sm="12" md="auto" lg="auto" xl="auto" class="d-flex flex-row">
                    <div>
                        <v-icon class="mr-2" size="24" :color="'#0e0d35'">local_shipping</v-icon>
                        <span class="title">{{ $t("general.trip_preferences") }}</span>
                    </div>
                </v-col>
                <v-col cols="auto">
                    <v-row dense align="center">
                        <v-col cols="auto">
                            <v-icon color="primary">info</v-icon>
                        </v-col>
                        <v-col cols="auto">
                            <div class="warningMessage">
                                {{ $t("general.only_you_as_admin_user_can_see") }}
                            </div>
                            <div class="warningMessage">
                                {{ $t("general.and_make_changes_in_these_settings") }}
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text>
            <v-tabs :vertical="!isMobile">
                <v-tab>
                    <div class="tabsHeader text-capitalize">
                        {{ $t("general.trip_preferences") }}
                    </div>
                </v-tab>
                <v-tab-item>
                    <v-row>
                        <v-col cols="12">
                            <PreviewSettingsDetails :tenant="tenant"></PreviewSettingsDetails>
                        </v-col>
                    </v-row>
                </v-tab-item>
            </v-tabs>
        </v-card-text>
    </v-card>
</template>

<script>
import PreviewSettingsDetails from "@/components/adminsettings/PreviewSettingsDetails.vue";

export default {
    title: "Global Settings",
    components: {
        PreviewSettingsDetails
    },
    props: {
        tenant: {
            type: Object,
            default: null
        }
    },
    computed: {
        tab() {
            return this.$route.query.tab;
        },
        isMobile() {
            return this.$vuetify.breakpoint.smAndDown;
        }
    }
};
</script>

<style scoped>
.max-width-settings-details {
    max-width: 800px;
    margin: 0 auto;
}

.tabsHeader {
    font-size: 16px;
    font-weight: 700;
    line-height: 19.2px;
    text-align: left;
}

.textHeader {
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    width: 291px;
    height: 58px;
    top: 141px;
    left: 376px;
}
.title {
    color: #0e0d35 !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 21.6px !important;
    text-align: left !important;
}
.warningMessage {
    color: #868599;
    font-size: 10px;
    font-weight: 700;
    line-height: 12px;
    text-align: left;
}
</style>

<template>
    <main>
        <v-card elevation="5" outlined v-resize="onResize">
            <v-card-text class="pb-3 pr-3">
                <div class="d-flex justify-end">
                    <div>
                        <v-btn icon>
                            <v-icon
                                @mouseenter="startScrolling('left')"
                                @mouseleave="stopScrolling"
                                >arrow_back</v-icon
                            >
                        </v-btn>
                        <v-btn icon>
                            <v-icon
                                @mouseenter="startScrolling('right')"
                                @mouseleave="stopScrolling"
                                >arrow_forward</v-icon
                            >
                        </v-btn>
                    </div>
                    <FilterMenu
                        :choices="headerChoices"
                        :title="$t('dashboard.customize')"
                        :description="$t('dashboard.display')"
                        :open-on-hover="false"
                        height="500px"
                        width="250px"
                        v-on:changed="onFilterMenuChanged"
                    >
                    </FilterMenu>
                </div>

                <!-- TABLE -->
                <v-data-table
                    v-model="selected"
                    :headers="dynamicHeaders"
                    :items="tableItems"
                    item-key="id"
                    :loading-text="$t('loading.loading')"
                    :loading="$apollo.queries.tripTemplates.loading"
                    hide-default-footer
                    disable-pagination
                    :show-select="selectMode"
                    disable-sort
                    loader-height="2"
                    :mobile-breakpoint="
                        screenSize === 'xs' || screenSize === 'sm' ? 800 : 0
                    "
                    :no-data-text="$t('loading.no_data')"
                    fixed-header
                    :height="tableHeight"
                    @click:row="item => editTemplate(item)"
                    class="row-pointer"
                >
                    <template slot="no-data"> <div>{{$t('loading.no_data')}}</div> </template>""

                    <template v-slot:item.name="{ item }">
                        <div>
                            <span style="white-space: nowrap;">
                                {{ item.name }}
                            </span>
                        </div>
                    </template>
                    <template v-slot:item.origin="{ item }">
                        <div>
                            <span style="white-space: nowrap;">{{
                                item.origin.name
                            }}</span>
                        </div>
                    </template>
                    <template v-slot:item.destination="{ item }">
                        <div>
                            <span style="white-space: nowrap;">{{
                                item.destination.name
                            }}</span>
                        </div>
                    </template>
                    <template v-slot:item.loadPoint="{ item }">
                        <div>
                            <v-chip
                                v-for="lp in item.loadPoint"
                                :key="lp"
                                class="mr-1 my-1"
                                small
                                >{{ lp }}</v-chip
                            >
                        </div>
                    </template>

                    <template v-slot:item.transportMode="{ item }">
                        <div>
                            {{ item.transportMode ? item.transportMode.toUpperCase() : "" }}
                        </div>
                    </template>

                    <template v-slot:item.carrier="{ item }">
                        <div>
                            <v-chip v-if="item.carrier.id" class="mr-1 my-1" small>
                                {{ item.carrier.name }}
                            </v-chip>
                        </div>
                    </template>

                    <template v-slot:item.partners="{ item }">
                        <div>
                            <v-chip
                                v-for="partner in item.partners"
                                :key="partner"
                                class="mr-1 my-1"
                                small
                            >
                                {{ partner }}
                            </v-chip>
                        </div>
                    </template>
                    <template v-slot:item.goods="{ item }">
                        <div>
                            <v-chip
                                v-for="good in item.goods"
                                :key="good"
                                class="mr-1 my-1"
                                small
                            >
                                {{ good }}
                            </v-chip>
                        </div>
                    </template>
                    <template v-slot:item.terms="{ item }">
                        <div>
                            <v-chip
                                v-for="term in item.terms"
                                :key="term"
                                class="mr-1 my-1"
                                small
                            >
                                {{ term }}
                            </v-chip>
                        </div>
                    </template>
                    <template v-slot:item.alertRules="{ item }">
                        <div>
                            <v-chip
                                v-for="alertRule in item.alertRules"
                                :key="alertRule"
                                class="mr-1 my-1"
                                small
                            >
                                {{ alertRule }}
                            </v-chip>
                        </div>
                    </template>
                    <!-- infinit loading trigger -->
                    <template slot="body.append">
                        <tr>
                            <td
                                :colspan="dynamicHeaders.length"
                                class="text-center"
                            >
                                <v-btn
                                    v-if="hasMoreData"
                                    v-intersect="onLoadMoreTriggerIntersect"
                                    :disabled="!hasMoreData"
                                    :loading="isLoading"
                                    plain
                                    class="mb-5"
                                    @click="loadMore"
                                >
                                    {{ $t('loading.load_more') }}
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>
            <!-- LOADING TRIGGER -->
            <!-- <v-card-actions class="justify-center">
                <v-btn
                    v-if="hasMoreData"
                    v-intersect="onLoadMoreTriggerIntersect"
                    :disabled="!hasMoreData"
                    :loading="$apollo.queries.templates.loading"
                    plain
                    class="mb-5"
                    @click="loadMore"
                >
                    Load more
                </v-btn>
            </v-card-actions> -->
        </v-card>
        <AddTrip
            ref="addTrip"
            :templateMode="true"
            :editMode="true"
            :openDialog="openEditDialog"
            :tripObj="templatetoEdit"
            @template-edited="refreshTable"
            @close-dialog="openEditDialog = false"
        ></AddTrip>
    </main>
</template>
<script>
import gql from "graphql-tag";
import FilterMenu from "@/components/base/FilterMenu.vue";
import AddTrip from "@/components/trips/create/AddTrip.vue";

import helper from "../../utils/helper";

export default {
    name: "TripTemplatesTable",
    components: { FilterMenu, AddTrip },
    props: {
        tripTemplatesFilter: {
            type: Object,
            required: false,
            default: () => {
                return {};
            }
        },
        selectMode: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    apollo: {
        tripTemplates: {
            query: gql`
                query tripTemplates(
                    $first: Int
                    $last: Int
                    $before: String
                    $after: String
                    $orderBy: String
                    $nameIcontains: String
                    $descriptionIcontains: String
                    $transportMode: String
                    $carrier: String
                    $goods: String
                    $loadPoint: String
                    $term: String
                    $partner: String
                ) {
                    tripTemplates(
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                        orderBy: $orderBy
                        name_Icontains: $nameIcontains
                        description_Icontains: $descriptionIcontains
                        transportMode: $transportMode
                        carrier: $carrier
                        goods: $goods
                        loadPoint: $loadPoint
                        term: $term
                        partner: $partner
                    ) {
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                        edges {
                            node {
                                id
                                lockTemplate
                                name
                                tripJson
                                description
                                origin
                                destination
                                carrier
                                partners
                                alertRules
                            }
                        }
                    }
                }
            `,
            variables() {
                return this.tripTemplatesFilter;
            },
            fetchPolicy: "network-only",
            debounce: 800,
            update: response => response.tripTemplates,
            watchLoading(isLoading) {
                this.isLoading = isLoading;
                this.$emit("loading", isLoading);
            },
            skip: false
        }
    },
    data() {
        return {
            tripTemplates: { edges: [] },
            selected: [],
            isLoading: false,
            openEditDialog: false,
            templatetoEdit: null,
            tableHeight: 800,
            searchInput: "",
            headerChoices: []
        };
    },
    mounted() {
        this.$watch(
            "headerChoices",
            function(value) {
                helper.updateMyPreferences({
                    tripTemplatesTableHeaders: value
                });
            },
            { deep: true }
        );
    },
    created() {
        this.headerChoices = helper.getTableHeaders(
            "tripTemplatesTableHeaders",
            this.defaultHeaderChoices
        );
    },
    watch: {},
    computed: {
        screenSize() {
            let _resposiveView = this.$vuetify.breakpoint.width;
            if (_resposiveView < 650) {
                return "xs";
            } else if (_resposiveView < 1000) {
                return "sm";
            } else if (_resposiveView < 1400) {
                return "md";
            } else if (_resposiveView < 1900) {
                return "lg";
            } else {
                return "xl";
            }
        },
        hasMoreData() {
            return this.tripTemplates?.pageInfo?.hasNextPage;
        },
        tableItems() {
            let items = [];
            this.tripTemplates.edges.forEach(edge => {
                let seatransport = JSON.parse(edge.node.tripJson);
                let goods = [];
                let terms = [];
                let loadPoint = [];
                seatransport.freights.forEach(freight => {
                    if (freight.goods != null && freight.goods != "")
                        goods.push(freight.goods);
                    if (freight.term != null && freight.term != "")
                        terms.push(freight.term);
                    if (freight.loadPoint != null && freight.loadPoint != "")
                        loadPoint.push(freight.loadPoint);
                });
                let departureTime =
                    seatransport.plannedDepartureTime
                let arrivalTime =
                    seatransport.plannedArrivalTime 

                let item = {
                    id: edge.node.id,
                    name: edge.node.name,
                    description: edge.node.description,
                    transportMode: seatransport.transportMode,
                    carrier: {
                        id: seatransport.carrier,
                        name: edge.node.carrier
                    },
                    origin: {id:seatransport.origin, name: edge.node.origin},
                    departureTime: departureTime,
                    destination: {id:seatransport.destination, name: edge.node.destination},
                    arrivalTime: arrivalTime,
                    goods: goods,
                    terms: terms,
                    loadPoint: loadPoint,
                    partners: edge.node.partners,
                    partnersIds: seatransport.partners,
                    alertRules: edge.node.alertRules,
                    trade: seatransport.trade,
                    freights: seatransport.freights,
                    seaTransport: seatransport.seaTransports
                };
                items.push(item);
            });
            return items;
        },
        dynamicHeaders() {
            let _headers = this.headerChoices.filter(header => header.enabled);

            _headers = _headers.map(header => {
                return {
                    ...header,
                    text: this.$t(header.tag)
                };
            });

            // put header filter in the last column
            _headers.push({
                text: "",
                align: "right",
                value: "headerFilter",
                sortable: false
            });
            return _headers;
        },
        defaultHeaderChoices: function() {
            let _defaultHeaderChoices = [
                {
                    code: "name",
                    name: "Template Name",
                    tag: "templates.template_name",
                    enabled: true,

                    text: "Template Name",
                    align: "left",
                    value: "name"
                },
                {
                    code: "description",
                    name: "Description",
                    tag: "templates.template_description",
                    enabled: false,

                    text: "Description",
                    align: "left",
                    value: "description"
                },
                {
                    // for filter menu
                    code: "transportMode",
                    name: "Transport Mode",
                    tag: "headers.transport_mode",
                    enabled: true,
                    // for table header
                    text: "Transport Mode",
                    align: "left",
                    value: "transportMode"
                },

                //carrier
                {
                    // for filter menu
                    code: "carrier",
                    name: "Carrier",
                    tag: "headers.carrier",
                    enabled: true,
                    // for table header
                    text: "Carrier",
                    align: "left",
                    value: "carrier.name"
                },
                //origin
                {
                    // for filter menu
                    code: "origin",
                    name: "Origin",
                    tag: "trips.origin",
                    enabled: true,
                    // for table header
                    text: "Origin",
                    align: "left",
                    value: "origin"
                },
                //destination
                {
                    // for filter menu
                    code: "destination",
                    name: "Destination",
                    tag: "trips.destination",
                    enabled: true,
                    // for table header
                    text: "Destination",
                    align: "left",
                    value: "destination"
                },
                //departure time
                {
                    // for filter menu
                    code: "departureTime",
                    name: "Departure Time",
                    tag: "date.departure_time",
                    enabled: false,
                    // for table header
                    text: "Departure Time",
                    align: "left",
                    value: "departureTime"
                },
                //arrival time
                {
                    // for filter menu
                    code: "arrivalTime",
                    name: "Arrival Time",
                    tag: "date.arrival_time",
                    enabled: false,
                    // for table header
                    text: "Arrival Time",
                    align: "left",
                    value: "arrivalTime"
                },
                //partners
                {
                    // for filter menu
                    code: "partners",
                    name: "Partners",
                    tag: "headers.partners",
                    enabled: false,
                    // for table header
                    text: "Partners",
                    align: "left",
                    value: "partners"
                },
                {
                    // for filter menu
                    code: "goods",
                    name: "Goods",
                    tag: "headers.goods",
                    enabled: true,
                    // for table header
                    text: "Goods",
                    align: "left",
                    value: "goods"
                },
                //terms
                {
                    // for filter menu
                    code: "terms",
                    name: "Terms",
                    tag: "headers.terms",
                    enabled: false,
                    // for table header
                    text: "Terms",
                    align: "left",
                    value: "terms"
                },
                //load point
                {
                    // for filter menu
                    code: "loadPoint",
                    name: "Load Points",
                    tag: "headers.load_points",
                    enabled: false,
                    // for table header
                    text: "Load Points",
                    align: "left",
                    value: "loadPoint"
                },
                //alerts
                {
                    // for filter menu
                    code: "alertRules",
                    name: "Alerts Rules",
                    tag: "home.alert_rules",
                    enabled: true,
                    // for table header
                    text: "Alerts Rules",
                    align: "left",
                    value: "alertRules"
                },
                //trade
                {
                    // for filter menu
                    code: "trade",
                    name: "Trade",
                    tag: "forms.trade",
                    enabled: false,
                    // for table header
                    text: "Trade",
                    align: "left",
                    value: "trade"
                }
            ]
            return _defaultHeaderChoices;
        }
    },
    methods: {
        editTemplate(item) {
            this.openEditDialog = true;
            this.templatetoEdit = item;
            this.$refs.addTrip.editTemplate(item);
        },
        deleteSelected() {
            this.isLoading = true;
            let selectedIds = this.selected.map(selected => selected.id);
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation deleteTripTemplate(
                            $input: DeleteTripTemplateInput!
                        ) {
                            deleteTripTemplate(input: $input) {
                                success
                            }
                        }
                    `,
                    variables: {
                        input: {
                            ids: selectedIds
                        }
                    }
                })
                .then(() => {
                    this.$apollo.queries.tripTemplates.refetch();
                    this.selected = [];
                })
                .finally(() => {
                    this.$emit("deleted-templates", false);
                });
        },
        onResize() {
            this.$nextTick(() => {
                this.tableHeight = window.innerHeight - 170 + "px";
            });
        },
        startScrolling(direction) {
            this.scrollInterval = setInterval(() => {
                const tableContainer = document.querySelector(
                    ".v-data-table__wrapper"
                );
                const scrollAmount = direction === "left" ? -10 : 10;
                tableContainer.scrollLeft += scrollAmount;
            }, 10);
        },
        stopScrolling() {
            clearInterval(this.scrollInterval);
        },
        onFilterMenuChanged(newChoices) {
            this.headerChoices = newChoices.map(choice => choice);
            if (this.isSharedTrip) {
                helper.updateMyPreferences({
                    sharedTripTableHeaders: newChoices
                });
            } else {
                helper.updateMyPreferences({
                    tripTableHeaders: newChoices
                });
            }
        },
        loadMore() {
            if (this.tripTemplates?.pageInfo?.hasNextPage) {
                this.$apollo.queries.tripTemplates.fetchMore({
                    variables: {
                        after: this.tripTemplates.pageInfo.endCursor
                    }
                });
            }
        },
        onLoadMoreTriggerIntersect(entries) {
            this.shouldLoadMore = entries[0].isIntersecting;

            if (
                this.shouldLoadMore &&
                this.tripTemplates?.pageInfo?.hasNextPage
            ) {
                this.loadMore();
            }
        },
        getCarrier() {
            this.$apollo.query({});
        },
        refreshTable() {
            this.isLoading = true;
            this.$apollo.queries.tripTemplates.refetch().finally(() => {
                this.isLoading = false;
            })
        }
    }
};
</script>
<style scoped lang="css">
.row-pointer >>> tbody tr :hover {
    cursor: pointer;
}
</style>

import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.template.id},[_c(VTabs,{ref:"tabs",attrs:{"color":"primary","show-arrows":"","background-color":"transparent"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.selectedFilters),function(t){return _c(VTab,{key:t.code,attrs:{"href":("#tab-" + (t.code))},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(t.name)+" ")])]},proxy:true}],null,true)})}),1),_c(VDivider),_c(VTabsItems,{staticClass:"overflow-y-auto background",style:(("height: " + _vm.heightScreen + "px;")),model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabItem,{attrs:{"value":"tab-map","transition":false,"active-class":"maxHeight"}},[_c(VContainer,{staticClass:"px-0",attrs:{"fluid":""}},[_c('TabDetails',{attrs:{"template":_vm.template}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"rounded-lg pa-3",attrs:{"outlined":""}},[_c(VCardTitle,{staticClass:"textTitle"},[_c('div',{staticClass:"mr-2"},[_c(VIcon,{staticClass:"iconColor"},[_vm._v("travel_explore")])],1),_c('span',[_vm._v(_vm._s(_vm.$t("general.overview")))])]),_c(VCardText,[_c(VRow,{staticClass:"my-3"},[_c(VCol,{attrs:{"cols":"6"}},[_c('FormTableTemplate',{attrs:{"list":_vm.tripTemplateResults.leftCol,"tooltip":false}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c('FormTableTemplate',{attrs:{"list":_vm.tripTemplateResults.rightCol,"tooltip":false}})],1)],1),_c(VDivider),_c(VCard,{attrs:{"color":"transparent","flat":"","height":"12px"}}),_vm._l((_vm.freightProp),function(f,i){return _c(VRow,{key:i,staticClass:"my-2 outlineD contentBg"},[_c(VCol,{attrs:{"cols":"4"}},[_c('FormTableTemplate',{attrs:{"list":_vm.freightDetails(f).colLeft,"tooltip":false}})],1),_c(VCol,{attrs:{"cols":"4"}},[_c('FormTableTemplate',{attrs:{"list":_vm.freightDetails(f).colCenter,"tooltip":false}})],1),_c(VCol,{attrs:{"cols":"4"}},[_c('FormTableTemplate',{attrs:{"list":_vm.freightDetails(f).colRight,"tooltip":false}})],1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
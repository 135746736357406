<template>
    <v-container fluid>
        <v-row class="d-flex align-center justify-center px-10 pt-4">
            <v-col xl="11" lg="12" md="12" sm="12" xs="12" cols="12">
                <div class="textHeader">
                    {{ $t("home.settings") }}
                </div>
            </v-col>
            <v-col xl="11" lg="12" md="12" sm="12" xs="12" cols="12">
                <!-- Custom tabs -->
                <CustomTabs v-model="tab" :tabs="tabsToShow" :droggable="false"></CustomTabs>
                <LoadingBar v-if="!tenantDetail && $apollo.queries.tenant.loading"></LoadingBar>
                <v-tabs-items v-model="tab" v-else>
                    <v-tab-item
                        v-for="(item, i) in tabsToShow"
                        :key="i"
                        :value="item.value"
                        class="background"
                        :transition="false"
                    >
                        <v-row class="py-6">
                            <v-col cols="12">
                                <router-view :tenant="tenantDetail" @loading="onLoading"></router-view>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import helper from "@/utils/helper.js";
import gql from "graphql-tag";
import LoadingBar from "@/components/base/LoadingBar.vue";
import CustomTabs from "@/components/base/CustomTabs.vue";

export default {
    title: "Profile",
    components: {
        LoadingBar,
        CustomTabs
    },
    apollo: {
        tenant: {
            query: gql`
                query Tenant($tenantId: ID!) {
                    tenant(id: $tenantId) {
                        id
                        name
                        logo
                        logoText
                        organisationPermissions
                        organisationPreferences
                        tenantPartnerSet {
                            totalCount
                        }
                        tenantUserSet {
                            totalCount
                        }
                        tenantAddressSet {
                            edges {
                                node {
                                    id
                                    street
                                    houseNumber
                                    suffix
                                    zipCode
                                    city
                                    aptSuiteBldg
                                    state
                                    country
                                    phone
                                    mobile
                                    website
                                    tag
                                    alias
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return { tenantId: this.tenantId };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.tenant,
            result(data) {
                if (data.data.tenant) {
                    this.tenantDetail = data.data.tenant;
                }
            },
            skip() {
                return !this.tenantId ? true : false;
            }
        }
    },
    data() {
        return {
            isLoading: false,
            tenantDetail: null,
            tabs: [
                {
                    id: 0,
                    text: this.$t("general.personal_settings"),
                    value: "personal"
                },
                {
                    id: 1,
                    text: this.$t("general.global_settings"),
                    value: "global"
                }
            ],
            partnerTabs: [
                {
                    id: 0,
                    text: this.$t("general.personal_settings"),
                    value: "personal"
                }
            ],

            tab: "personal"
        };
    },
    computed: {
        me() {
            return helper.me();
        },
        tenantId() {
            return this.me?.tenant?.id;
        },

        tabsToShow() {
            return this.me?.isPartnerUser ? this.partnerTabs : this.tabs;
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
    },
    mounted() {
        this.pushTo("profilesettings");
    },
    watch: {
        $route(to) {
            if (to.query.tab) {
                this.$router.push({ name: to.query.tab, params: to.params });
            }
        },
        tab(val) {
            switch (val) {
                case "personal":
                    this.pushTo("profilesettings");
                    break;
                case "global":
                    this.pushTo("globalsettings");
                    break;
            }
        }
    },
    methods: {
        onLoading(val) {
            this.isLoading = val;
        }
    }
};
</script>

<style scoped>
.max-width-settings-details {
    max-width: 800px;
    margin: 0 auto;
}

.tabsHeader {
    font-size: 16px;
    font-weight: 700;
    line-height: 19.2px;
    text-align: left;
}

.textHeader {
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    width: 291px;
    height: 58px;
    top: 141px;
    left: 376px;
}
.title {
    color: #0e0d35 !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 21.6px !important;
    text-align: left !important;
}
.warningMessage {
    color: #868599;
    font-size: 10px;
    font-weight: 700;
    line-height: 12px;
    text-align: left;
}
</style>

<template>
    <v-container fluid>
        <v-row class="d-flex align-center justify-center px-10">
            <v-col xl="11" lg="12" md="12" sm="12" xs="12" cols="12">
                <PreviewTripTemplatesFilter class="mx-3" ref="tripTemplateFilter" v-on:filter-changed="onFilterChanged">
                </PreviewTripTemplatesFilter>
                <v-btn
                    v-scroll="onScroll"
                    v-show="goTopShow"
                    fab
                    dark
                    fixed
                    bottom
                    right
                    small
                    style="z-index: 1"
                    color="primary"
                    @click="toTop"
                >
                    <v-icon>keyboard_arrow_up</v-icon>
                </v-btn>
            </v-col>
            <v-col xl="11" lg="12" md="12" sm="12" xs="12" cols="12">
                <v-row class="ma-0 px-0">
                    <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                        <h2 class="modelTitle">
                            {{ $t("templates.trips_templates") }}
                        </h2>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="createTemplate">
                        {{ $t("templates.create_new_template") }}
                    </v-btn>
                </v-row>
            </v-col>
            <v-col xl="11" lg="12" md="12" sm="12" xs="12" cols="12">
                <PreviewTripTemplatesTable
                    ref="tripTemplateTable"
                    :tripTemplatesFilter="tripTemplateFilter"
                    class="mx-3 mt-3 mb-3"
                    v-on:click:row="onTripTemplateSelected"
                    :selectMode="selectMode"
                    @deleted-templates="deleteFinished"
                ></PreviewTripTemplatesTable>
            </v-col>
        </v-row>
        <v-navigation-drawer
            v-model="tripTemplateDetailDrawer"
            :width="templateDetailDrawerWidth"
            :style="{ top: '46px', zIndex: 3 }"
            right
            app
            temporary
        >
            <div>
                <PreviewTripTemplateDetails
                    v-if="selectedTripTemplate"
                    :key="selectedTripTemplate.id"
                    :template="selectedTripTemplate"
                    :isFullScreen="isTemplateDetailDrawerFullScreen"
                    v-on:changeFullScreen="onTemplateDetailDrawerFullScreenChange"
                    v-on:close="tripTemplateDetailDrawer = false"
                    v-on:refreshDataTable="onTemplateCreated"
                    v-on:onLocationDeleted="onTemplateDeleted"
                >
                </PreviewTripTemplateDetails>
            </div>
        </v-navigation-drawer>
    </v-container>
</template>

<script>
import helper from "@/utils/helper.js";
import PreviewTripTemplatesFilter from "@/components/triptemplates/PreviewTripTemplatesFilter.vue";
import PreviewTripTemplatesTable from "@/components/triptemplates/PreviewTripTemplatesTable.vue";
import PreviewTripTemplateDetails from "@/components/triptemplates/PreviewTripTemplateDetails.vue";
import { eventBus } from "@/main.js";
export default {
    title: "Trip Templates",
    components: {
        PreviewTripTemplatesFilter,
        PreviewTripTemplatesTable,
        PreviewTripTemplateDetails
        // AddTrip
    },
    data() {
        return {
            selectMode: false,
            tripTemplateDetailDrawer: false,
            tripTemplateFilter: {
                first: 10
            },
            goTopShow: false,
            isLoading: false,
            selectedTripTemplate: null,
            tripTemplateDetailFullScreen: false,
            openAddTripTemplate: false
        };
    },
    computed: {
        me() {
            return helper.me();
        },
        isLoggedInAsOtherUser() {
            return this.$store.state.user.loggedInAsOtherUser;
        },
        templateDetailDrawerWidth() {
            if (this.tripTemplateDetailFullScreen) {
                return "100%";
            }

            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "90%";
                case "sm":
                    return "80%";
                case "md":
                    return "70%";
                case "lg":
                    return "70%";
                case "xl":
                    return "70%";
                default:
                    return "100%";
            }
        },
        isTemplateDetailDrawerFullScreen() {
            return this.templateDetailDrawerWidth === "100%";
        }
    },
    watch: {},
    created() {
        //TODO: Permission check
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
    },
    mounted() {
        if (this.$route.query.code) this.filterCode = this.$route.query.code;
        let _createTrip = this.$store.getters["trip/getCreatedTrip"];
        let _cancelTripChanges = this.$store.getters["trip/getCancelTrip"];

        if (_createTrip) {
            this.onTripTemplateCreated(_createTrip);
        } else if (_cancelTripChanges) {
            this.onTripTemplateCancel(_cancelTripChanges);
        }
    },
    methods: {
        deleteFinished() {
            this.isLoading = false;
            this.selectMode = false;
        },
        createTemplate() {
            this.$router.push({
                path: "/newtrips",
                query: {
                    type: "createTemplate"
                }
            });
        },
        onTemplateDetailDrawerFullScreenChange(val) {
            this.tripTemplateDetailFullScreen = val;
            if (val) {
                eventBus.$emit("closeMenu");
            }
        },
        onDeleteSelectedTemplates() {
            this.isLoading = false;
            this.$refs.tripTemplateTable.deleteSelected();
            this.changeSelectMode();
        },
        changeSelectMode() {
            this.selectMode = !this.selectMode;
        },
        onLoading(val) {
            this.isLoading = val;
        },
        onFilterChanged(filter) {
            this.tripTemplateFilter = filter;
        },
        toTop() {
            this.$vuetify.goTo(0);
        },
        onScroll(e) {
            if (typeof window === "undefined") return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.goTopShow = top > 20;
        },
        loadMore() {
            this.isLoading = false;
        },
        onTripTemplateSelected(tripTemplate) {
            this.selectedTripTemplate = tripTemplate;
            this.tripTemplateDetailDrawer = true;
        },
        onTemplateCreated() {
            this.$refs.tripTemplateTable.refreshTable();
        },
        onTemplateDeleted() {
            this.tripTemplateDetailDrawer = null;
        },
        onTripTemplateCreated(tripTemplate) {
            let _templateJson = null;
            let _template = null;
            if (tripTemplate?.tripJson) {
                _templateJson = JSON.parse(tripTemplate.tripJson);
                _template = {
                    id: tripTemplate.id,
                    name: tripTemplate.name,
                    ..._templateJson
                };
            } else {
                _template = tripTemplate;
            }

            this.selectedTripTemplate = _template;
            this.tripTemplateDetailDrawer = true;
            if (tripTemplate) {
                this.$store.dispatch("trip/destroyStorage");
            }
        },
        onTripTemplateCancel(tripTemplate) {
            this.selectedTripTemplate = tripTemplate;
            this.tripTemplateDetailDrawer = true;

            if (tripTemplate) {
                this.$store.dispatch("trip/destroyStorage");
            }
        }
    }
};
</script>
<style scoped>
::v-deep .v-navigation-drawer__content::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}
.modelTitle {
    font-size: 32px;
    font-weight: 700;
    line-height: 38.4px;
    text-align: left;
    color: #000000;
}
</style>

<template>
    <v-card elevation="5" outlined v-resize="onResize">
        <v-card-text class="pb-3 pr-3">
            <div class="d-flex justify-end">
                <div>
                    <v-btn icon>
                        <v-icon
                            @mouseenter="startScrolling('left')"
                            @mouseleave="stopScrolling"
                            >arrow_back</v-icon
                        >
                    </v-btn>
                    <v-btn icon>
                        <v-icon
                            @mouseenter="startScrolling('right')"
                            @mouseleave="stopScrolling"
                            >arrow_forward</v-icon
                        >
                    </v-btn>
                </div>
                <!-- filter menu -->
                <FilterMenu
                    :title="$t('dashboard.customize')"
                    :description="$t('dashboard.display')"
                    :choices="dynamicHeaders"
                    :open-on-hover="false"
                    height="500px"
                    width="250px"
                    v-on:changed="onFilterMenuChanged"
                >
                </FilterMenu>
            </div>
            <v-data-table
                :headers="dynamicHeaders"
                :items="freights.edges"
                item-key="node.id"
                :loading-text="$t('loading.loading1')"
                :loading="$apollo.queries.freights.loading"
                hide-default-footer
                disable-pagination
                disable-sort
                loader-height="2"
                :mobile-breakpoint="
                    screenSize === 'xs' || screenSize === 'sm' ? 800 : 0
                "
                :no-data-text="$t('loading.no_data')"
                fixed-header
                :height="tableHeight"
                @click:row="item => this.$emit('click:row', item)"
                class="row-pointer"
            >
                <!-- is pinned -->
                <template v-slot:item.isFavorite="{ item, index }">
                    <!-- <v-icon small v-if="item.node.isUserPinned">push_pin</v-icon> -->
                    <v-icon
                        small
                        v-if="item.node.isUserFavorite"
                        color="warning"
                    >
                        star
                    </v-icon>
                </template>
                <template v-slot:loading>
                    <tr >
                        <td
                            :colspan="2"
                            :style="centralizeLoadBtnClass"
                        >
                            <div>
                                {{ $t('loading.loading1') }}
                            </div>
                        </td>
                    </tr>
                </template>
                <!-- alerts -->
                <template v-slot:item.node.numberOfUnresolvedAlerts="{ item }">
                    <span v-if="item.node.numberOfUnresolvedAlerts > 0">
                        <v-row dense class="mx-0 px-0">
                            <v-icon class="mr-2" color="warning" small>
                                warning
                            </v-icon>
                            {{ item.node.numberOfUnresolvedAlerts }}
                        </v-row>
                    </span>
                </template>

                <!-- containers -->
                <template v-slot:item.node.containerNumber="{ item }">
                    <div v-if="item.node.containerNumber">
                        <v-chip class="mr-1 my-1" small>
                            {{ item.node.containerNumber }}
                        </v-chip>
                    </div>
                </template>

                <!-- airwaybill  -->
                <template v-slot:item.node.airWaybillNumber="{ item }">
                    <div v-if="item.node.airWaybillNumber">
                        <v-chip class="mr-1 my-1" small>
                            {{ formatAirwaybillNumber(item.node.airWaybillNumber)}} | {{ item.node.airWaybillNumber }} 
                        </v-chip>
                    </div>
                </template>

                <!-- trackers -->
                <template v-slot:item.trackers="{ item }">
                    <v-tooltip
                        bottom
                        v-for="freightTracker in item.node.tripFreightTrackerSet
                            .edges"
                        :key="freightTracker.node.id"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip
                                v-bind="attrs"
                                v-on="on"
                                class="mr-1 my-1"
                                :color="
                                    freightTracker.node.tracker.activatedDate
                                        ? 'primary'
                                        : ''
                                "
                                small
                                outlined
                            >
                                {{ freightTracker.node.tracker.serialNumber }}
                            </v-chip>
                        </template>
                        <span>
                            {{
                                freightTracker.node.tracker.activatedDate
                                    ? `Activated on ${formatDateTime(
                                          freightTracker.node.tracker
                                              .activatedDate
                                      )}`
                                    : "Not Activated"
                            }}
                        </span>
                    </v-tooltip>
                    <v-tooltip
                        bottom
                        v-if="
                            getExternalTrackerAdditionalInfo(
                                item.node.additionalInfo
                            )
                        "
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip
                                v-bind="attrs"
                                v-on="on"
                                class="mr-1 my-1"
                                small
                                outlined
                            >
                                {{
                                    getExternalTrackerAdditionalInfo(
                                        item.node.additionalInfo
                                    ).serialNumber
                                }}
                            </v-chip>
                        </template>
                        <span>
                            {{
                                getExternalTrackerAdditionalInfo(
                                    item.node.additionalInfo
                                ).type
                            }}
                        </span>
                    </v-tooltip>
                </template>

                <!-- Planned Departure -->
                <template v-slot:item.node.trip.plannedDepartureDate="{ item }">
                    <span>{{
                        formatDateTime(item.node.trip.plannedDepartureDate)
                    }}</span>
                </template>

                <!-- Planned Arrival -->
                <template v-slot:item.node.trip.plannedArrivalDate="{ item }">
                    <span>{{
                        formatDateTime(item.node.trip.plannedArrivalDate)
                    }}</span>
                </template>

                <!-- Last Connection Time -->
                <template v-slot:item.node.trackerLastConnectionTime="{ item }">
                    <span v-if="item.node.trackerLastConnectionTime">{{
                        formatDateTime(item.node.trackerLastConnectionTime)
                    }}</span>
                </template>

                <!-- Last Connection Time -->
                <template v-slot:item.node.trackerLastLocation="{ item }">
                    <div v-if="item.node.trackerLastLocation">
                        <v-chip small>{{
                            item.node.trackerLastLocation
                        }}</v-chip>
                        <v-chip
                            small
                            v-if="item.node.trackerLastTemperatureValue"
                            class="ml-3"
                            ><b
                                >{{
                                    formatTemperature(
                                        item.node.trackerLastTemperatureValue
                                    ).toFixed(0)
                                }}
                                {{ getTemperatureSymbol() }}</b
                            ></v-chip
                        >
                    </div>
                </template>

                <!-- Vessel -->
                <template v-slot:item.node.vessel="{ item }">
                    <v-chip
                        v-for="vessel in getAllVessels(
                            item.node.trip.tripSeaTransportSet
                        )"
                        :key="vessel.id"
                        class="mr-1 my-1"
                        small
                        >{{ vessel.name }}</v-chip
                    >
                </template>

                <!-- consignors -->
                <template v-slot:item.consignor="{ item }">
                    <div
                        v-for="partner in item.node.trip.tripPartnerSet.edges"
                        :key="partner.node.id"
                    >
                        <span
                            class="text-no-wrap"
                            v-if="
                                partner.node.tenantPartner.partnerType ===
                                    'consignor'
                            "
                        >
                            <v-chip class="mr-1 my-1" small>
                                {{ partner.node.tenantPartner.name }}
                            </v-chip>
                        </span>
                    </div>
                </template>
                <!-- consignees -->
                <template v-slot:item.consignee="{ item }">
                    <div
                        v-for="partner in item.node.trip.tripPartnerSet.edges"
                        :key="partner.node.id"
                    >
                        <span
                            class="text-no-wrap"
                            v-if="
                                partner.node.tenantPartner.partnerType ===
                                    'consignee'
                            "
                        >
                            <v-chip class="mr-1 my-1" small>
                                {{ partner.node.tenantPartner.name }}
                            </v-chip>
                        </span>
                    </div>
                </template>
                <!-- forwarders -->
                <template v-slot:item.forwarder="{ item }">
                    <div
                        v-for="partner in item.node.trip.tripPartnerSet.edges"
                        :key="partner.node.id"
                    >
                        <span
                            class="text-no-wrap"
                            v-if="
                                partner.node.tenantPartner.partnerType ===
                                    'forwarder'
                            "
                        >
                            <v-chip class="mr-1 my-1" small>
                                {{ partner.node.tenantPartner.name }}
                            </v-chip>
                        </span>
                    </div>
                </template>
                <!-- carriers -->
                <template v-slot:item.carrier="{ item }">
                    <div v-if="item.node.trip.tenantCarrier">
                        <v-chip class="mr-1 my-1" small>
                            {{ item.node.trip.tenantCarrier.name }}
                        </v-chip>
                    </div>
                </template>
                <!-- others -->
                <template v-slot:item.other="{ item }">
                    <div
                        v-for="partner in item.node.trip.tripPartnerSet.edges"
                        :key="partner.node.id"
                    >
                        <span
                            class="text-no-wrap"
                            v-if="
                                partner.node.tenantPartner.partnerType ===
                                    'other'
                            "
                        >
                            <v-chip class="mr-1 my-1" small>
                                {{ partner.node.tenantPartner.name }}
                            </v-chip>
                        </span>
                    </div>
                </template>
                <template v-slot:item.POL="{ item }">
                    <div
                        v-for="e in item.node.trip.tripSeaTransportSet.edges"
                        :key="e.node.id"
                    >
                        <span
                            class="text-no-wrap"
                            v-if="e.node.portTransportType === 'POL'"
                        >
                            <v-chip
                                v-if="e.node.portCode.length > 0"
                                class="mr-1 my-1"
                                small
                            >
                                {{ e.node.portCode }}
                            </v-chip>
                        </span>
                    </div>
                </template>
                <template v-slot:item.transshipment="{ item }">
                    <div
                        v-for="e in item.node.trip.tripSeaTransportSet.edges"
                        :key="e.node.id"
                    >
                        <span
                            class="text-no-wrap"
                            v-if="
                                e.node.portTransportType === 'Transshipment' ||
                                    e.node.portTransportType === 'T/S'
                            "
                        >
                            <v-chip
                                v-if="e.node.portCode.length > 0"
                                class="mr-1 my-1"
                                small
                            >
                                {{ e.node.portCode }}
                            </v-chip>
                        </span>
                    </div>
                </template>
                <template v-slot:item.POD="{ item }">
                    <div
                        v-for="e in item.node.trip.tripSeaTransportSet.edges"
                        :key="e.node.id"
                    >
                        <span
                            class="text-no-wrap"
                            v-if="e.node.portTransportType === 'POD'"
                        >
                            <v-chip
                                v-if="e.node.portCode.length > 0"
                                class="mr-1 my-1"
                                small
                            >
                                {{ e.node.portCode }}
                            </v-chip>
                        </span>
                    </div>
                </template>

                <!-- goods -->
                <template v-slot:item.node.goods="{ item }">
                    <div v-if="item.node.goods">
                        <v-chip class="mr-1 my-1" small>
                            {{ item.node.goods.toLowerCase() }}
                        </v-chip>
                    </div>
                </template>

                <!-- Bill of Lading -->
                <template v-slot:item.node.billOfLadingNumber="{ item }">
                    <div v-if="item.node.billOfLadingNumber">
                        <v-chip class="mr-1 my-1" small>
                            {{ item.node.billOfLadingNumber }}
                        </v-chip>
                    </div>
                </template>

                <!-- infinit loading trigger -->
                <template slot="body.append">
                    <tr>
                        <td
                            :colspan="2"
                            class="text-center"
                            :style="centralizeLoadBtnClass"
                        >
                            <v-btn
                                v-if="hasMoreData"
                                v-intersect="onLoadMoreTriggerIntersect"
                                :disabled="!hasMoreData"
                                :loading="$apollo.queries.freights.loading"
                                plain
                                class="mb-5"
                                @click="loadMore"
                            >
                                {{ $t("loading.load_more") }}
                            </v-btn>
                        </td>
                    </tr>
                </template>
                <!-- numberOfFreights
                numberOfPartners
                numberOfDocuments
                numberOfComments
                numberOfUnresolvedAlerts -->
                <template v-slot:item.node.trip.numberOfFreights="{item}">
                    <div>
                        {{ item.node.trip.numberOfFreights }}
                    </div>
                </template>
                <template v-slot:item.node.trip.numberOfPartners="{item}">
                    <div>
                        {{ item.node.trip.numberOfPartners }}
                    </div>
                </template>
                <template v-slot:item.node.trip.numberOfDocuments="{item}">
                    <div>
                        {{ item.node.trip.numberOfDocuments }}
                    </div>
                </template>
                <template v-slot:item.node.trip.numberOfComments="{item}">
                    <div>
                        {{ item.node.trip.numberOfComments }}
                    </div>
                </template>
                <template v-slot:item.node.trip.numberOfUnresolvedAlerts="{item}">
                    <div>
                       {{ item.node.trip.numberOfUnresolvedAlerts }} 
                    </div>
                </template>
                <!-- freights -->
                <!-- <template v-slot:item.freights="{ item }">
                    <div>
                        <v-chip
                            v-for="freight in item.node.trip.tripFreightSet.edges.filter(
                                item => item.node.referenceNumber
                            )"
                            :key="freight.node.id"
                            class="mr-1 my-1"
                            small
                        >
                            {{ freight.node.referenceNumber }}
                        </v-chip>
                    </div>
                </template> -->
            </v-data-table>
        </v-card-text>

        <!-- infinite loading trigger -->
        <!-- <v-card-actions class="justify-center">
            <v-btn
                v-if="hasMoreData"
                v-intersect="onLoadMoreTriggerIntersect"
                :disabled="!hasMoreData"
                :loading="$apollo.queries.freights.loading"
                plain
                class="mb-5"
                @click="loadMore"
            >
                Load more
            </v-btn>
        </v-card-actions> -->
    </v-card>
</template>

<script>
import gql from "graphql-tag";
import FilterMenu from "@/components/base/FilterMenu.vue";
import helper from "../../utils/helper";
import _default from "vuex";

export default {
    props: {
        freightFilter: {
            type: Object,
            required: false,
            default: () => {
                return {};
            }
        }
    },
    components: { FilterMenu },

    apollo: {
        freights: {
            query: gql`
                query tripFreights(
                    $search: String
                    $first: Int
                    $after: String
                    $before: String
                    $referenceNumberIcontains: String
                    $orderBy: String
                    $goodsIcontains: String
                    $containerNumberIcontains: String
                    $billOfLadingNumberIcontains: String
                    $airWaybillNumberIcontains: String
                    $orderNumberIcontains: String
                    $qualityCodeIcontains: String
                    $termIcontains: String
                    $tripFreightTrackerSetTrackerSerialNumberIcontains: String
                    $tripTransportModeIn: [String]
                    $tripPlannedDepartureDateGte: DateTime
                    $tripPlannedDepartureDateLte: DateTime
                    $tripPlannedArrivalDateGte: DateTime
                    $tripPlannedArrivalDateLte: DateTime
                    $tripStatusIn: [String]
                    $tripTripSeaTransportSetVesselId: ID
                    $isFavorite: Boolean
                    $tripTripAlertSetIsResolved: Boolean
                    $tripReferenceNumberIcontains: String
                    $tripTenantCarrier: ID
                    $freightAlertCode: String
                    $freightConsignee: String
                    $freightConsignor: String
                    $freightForwarder: String
                    $freightPod: String
                    $freightPol: String
                    $freightTransshipment: String
                    $trackerLastLocation_Icontains: String
                ) {
                    tripFreights(
                        search: $search
                        first: $first
                        after: $after
                        before: $before
                        referenceNumber_Icontains: $referenceNumberIcontains
                        orderBy: $orderBy
                        goods_Icontains: $goodsIcontains
                        containerNumber_Icontains: $containerNumberIcontains
                        billOfLadingNumber_Icontains: $billOfLadingNumberIcontains
                        airWaybillNumber_Icontains: $airWaybillNumberIcontains
                        orderNumber_Icontains: $orderNumberIcontains
                        qualityCode_Icontains: $qualityCodeIcontains
                        term_Icontains: $termIcontains
                        tripFreightTrackerSet_Tracker_SerialNumber_Icontains: $tripFreightTrackerSetTrackerSerialNumberIcontains
                        trip_TransportMode_In: $tripTransportModeIn
                        trip_PlannedDepartureDate_Gte: $tripPlannedDepartureDateGte
                        trip_PlannedDepartureDate_Lte: $tripPlannedDepartureDateLte
                        trip_PlannedArrivalDate_Gte: $tripPlannedArrivalDateGte
                        trip_PlannedArrivalDate_Lte: $tripPlannedArrivalDateLte
                        trip_Status_In: $tripStatusIn
                        trip_TripSeaTransportSet_VesselId: $tripTripSeaTransportSetVesselId
                        isFavorite: $isFavorite
                        trip_TripAlertSet_IsResolved: $tripTripAlertSetIsResolved
                        trip_ReferenceNumber_Icontains: $tripReferenceNumberIcontains
                        trip_TenantCarrier: $tripTenantCarrier
                        freightAlertCode: $freightAlertCode
                        freightConsignee: $freightConsignee
                        freightConsignor: $freightConsignor
                        freightForwarder: $freightForwarder
                        freightPod: $freightPod
                        freightPol: $freightPol
                        freightTransshipment: $freightTransshipment
                        trackerLastLocation_Icontains: $trackerLastLocation_Icontains
                    ) {
                        pageInfo {
                            endCursor
                            hasNextPage
                            hasPreviousPage
                            startCursor
                        }
                        edges {
                            node {
                                id
                                referenceNumber
                                containerNumber
                                airWaybillNumber
                                billOfLadingNumber
                                goods
                                additionalInfo
                                trackerLastConnectionTime
                                trackerLastLocation
                                trackerLastTemperatureValue
                                isUserFavorite
                                tripFreightTrackerSet {
                                    edges {
                                        node {
                                            id
                                            tracker {
                                                id
                                                serialNumber
                                                activatedDate
                                            }
                                        }
                                    }
                                }
                                trip {
                                    id
                                    createdAt
                                    tenantCarrier {
                                        id
                                        name
                                    }
                                    originLocation {
                                        id
                                        name
                                        city
                                        country
                                    }
                                    destinationLocation {
                                        id
                                        name
                                        city
                                        country
                                    }
                                    tripFreightSet {
                                        edges {
                                            node {
                                                id
                                                referenceNumber
                                                containerNumber
                                                airWaybillNumber
                                                billOfLadingNumber
                                                goods
                                                tripFreightTrackerSet {
                                                    edges {
                                                        node {
                                                            id
                                                            tracker {
                                                                id
                                                                serialNumber
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    referenceNumber
                                    transportMode
                                    plannedDepartureDate
                                    plannedArrivalDate
                                    status
                                    tenant {
                                        id
                                        organisationPermissions
                                    }
                                    isArchived
                                    isPrivate
                                    numberOfFreights
                                    numberOfPartners
                                    numberOfDocuments
                                    numberOfComments
                                    numberOfUnresolvedAlerts
                                    tripPartnerSet {
                                        edges {
                                            node {
                                                id
                                                tenantPartner {
                                                    id
                                                    name
                                                    partnerType
                                                }
                                            }
                                        }
                                    }
                                    tripSeaTransportSet {
                                        edges {
                                            node {
                                                id
                                                sequence
                                                portCode
                                                portTransportType
                                                portName
                                                vessel {
                                                    id
                                                    name
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                let request = {
                    ...this.freightFilter
                };
                if (this.isSharedTrip) {
                    request = {
                        ...this.freightFilter,
                        sharedtrips: "sharedtrips",
                        userId: this.me.id
                    };
                }
                if(request?.airWaybillNumberIcontains){
                    request.airWaybillNumberIcontains = this.removeHifenFromAirwaybill(request.airWaybillNumberIcontains);
                }
                return request;
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.tripFreights,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            debounce: 800
        }
    },

    data() {
        return {
            shouldLoadMore: false,
            isInitialLoad: true,
            selectedHeaders: ["node.referenceNumber", "node.status"],
            freights: { edges: [] },
            tableHeight: 800,
            headerChoices: null,
            tableHeaderFilter: {
                referenceNumber: true,
                status: true,
                containerNumber: true,
                alerts: true
            }

            // defaultHeaderChoices: [
            //     {
            //         // for filter menu
            //         code: "node.referenceNumber",
            //         name: this.$t("headers.reference_number"),
            //         enabled: true,
            //         // for table header
            //         text: this.$t("headers.reference_number"),
            //         align: "left",
            //         value: "node.referenceNumber"
            //     },
            //     {
            //         // for filter menu
            //         code: "node.trip.referenceNumber",
            //         name: this.$t("headers.trip_number"),
            //         enabled: true,
            //         // for table header
            //         text: this.$t("headers.trip_number"),
            //         align: "left",
            //         value: "node.trip.referenceNumber"
            //     },
            //     {
            //         // for filter menu
            //         code: "node.trip.transportMode",
            //         name: this.$t("headers.transport_mode"),
            //         enabled: true,
            //         // for table header
            //         text: this.$t("headers.transport_mode"),
            //         align: "left",
            //         value: "node.trip.transportMode"
            //     },
            //     {
            //         // for filter menu
            //         code: "node.vessel",
            //         name: this.$t("headers.vessel"),
            //         enabled: true,
            //         // for table header
            //         text: this.$t("headers.vessel"),
            //         align: "left",
            //         value: "node.vessel"
            //     },
            //     {
            //         // for filter menu
            //         code: "node.trip.status",
            //         name: this.$t("headers.status"),
            //         enabled: true,
            //         // for table header
            //         text: this.$t("headers.status"),
            //         align: "left",
            //         value: "node.trip.status"
            //     },
            //     {
            //         // for filter menu
            //         code: "carrier",
            //         name: this.$t("headers.carrier"),
            //         enabled: true,
            //         // for table header
            //         text: this.$t("headers.carrier"),
            //         align: "left",
            //         value: "carrier"
            //     },
            //     {
            //         // for filter menu
            //         code: "node.trip.plannedDepartureDate",
            //         name: this.$t("trips.planned_departure"),
            //         enabled: true,
            //         // for table header
            //         text: this.$t("trips.planned_departure"),
            //         align: "left",
            //         value: "node.trip.plannedDepartureDate"
            //     },
            //     {
            //         // for filter menu
            //         code: "node.trip.plannedArrivalDate",
            //         name: this.$t("trips.planned_arrival"),
            //         enabled: true,
            //         // for table header
            //         text: this.$t("trips.planned_arrival"),
            //         align: "left",
            //         value: "node.trip.plannedArrivalDate"
            //     },
            //     {
            //         // for filter menu
            //         code: "node.containerNumber",
            //         name: this.$t("headers.container_number"),
            //         enabled: true,
            //         // for table header
            //         text: this.$t("headers.container_number"),
            //         align: "left",
            //         value: "node.containerNumber"
            //     },
            //     {
            //         // for filter menu
            //         code: "node.airWaybillNumber",
            //         name: this.$t("headers.awb_number"),
            //         enabled: true,
            //         // for table header
            //         text: this.$t("headers.awb_number"),
            //         align: "left",
            //         value: "node.airWaybillNumber"
            //     },
            //     {
            //         // for filter menu
            //         code: "node.trackerLastConnectionTime",
            //         name: this.$t("headers.last_connection"),
            //         enabled: true,
            //         // for table header
            //         text: this.$t("headers.last_connection"),
            //         align: "left",
            //         value: "node.trackerLastConnectionTime"
            //     },
            //     {
            //         // for filter menu
            //         code: "node.trackerLastLocation",
            //         name: this.$t("headers.last_location"),
            //         enabled: true,
            //         // for table header
            //         text: this.$t("headers.last_location"),
            //         align: "left",
            //         value: "node.trackerLastLocation"
            //     },
            //     {
            //         // for filter menu
            //         code: "node.billOfLadingNumber",
            //         name: this.$t("headers.bill"),
            //         enabled: true,
            //         // for table header
            //         text: this.$t("headers.bill"),
            //         align: "left",
            //         value: "node.billOfLadingNumber"
            //     },
            //     {
            //         // for filter menu
            //         code: "trackers",
            //         name: this.$t("home.trackers"),
            //         enabled: true,
            //         // for table header
            //         text: this.$t("home.trackers"),
            //         align: "left",
            //         value: "trackers"
            //     },
            //     {
            //         // for filter menu
            //         code: "consignor",
            //         name: this.$t("headers.consignor"),
            //         enabled: false,
            //         // for table header
            //         text: this.$t("headers.consignor"),
            //         align: "left",
            //         value: "consignor"
            //     },
            //     {
            //         // for filter menu
            //         code: "consignee",
            //         name: this.$t("headers.consignee"),
            //         enabled: false,
            //         // for table header
            //         text: this.$t("headers.consignee"),
            //         align: "left",
            //         value: "consignee"
            //     },
            //     {
            //         // for filter menu
            //         code: "forwarder",
            //         name: this.$t("headers.forwarder"),
            //         enabled: false,
            //         // for table header
            //         text: this.$t("headers.forwarder"),
            //         align: "left",
            //         value: "forwarder"
            //     },
            //     {
            //         // for filter menu
            //         code: "other",
            //         name: this.$t("home.other"),
            //         enabled: false,
            //         // for table header
            //         text: this.$t("home.other"),
            //         align: "left",
            //         value: "other"
            //     },
            //     {
            //         // for filter menu
            //         code: "node.goods",
            //         name: this.$t("forms.goods"),
            //         enabled: false,
            //         // for table header
            //         text: this.$t("forms.goods"),
            //         align: "left",
            //         value: "node.goods"
            //     },
            //     {
            //         // for filter menu
            //         code: "POL",
            //         name: "POL",
            //         enabled: false,
            //         // for table header
            //         text: "POL",
            //         align: "left",
            //         value: "POL"
            //     },
            //     {
            //         // for filter menu
            //         code: "transshipment",
            //         name: "T/S",
            //         enabled: false,
            //         // for table header
            //         text: "T/S",
            //         align: "left",
            //         value: "transshipment"
            //     },
            //     {
            //         // for filter menu
            //         code: "POD",
            //         name: "POD",
            //         enabled: false,
            //         // for table header
            //         text: "POD",
            //         align: "left",
            //         value: "POD"
            //     },
            //     {
            //         // for filter menu
            //         code: "node.numberOfPartners",
            //         name: this.$t("headers.partners1"),
            //         enabled: true,
            //         // for table header
            //         text: this.$t("headers.partners1"),
            //         align: "right",
            //         value: "node.numberOfPartners"
            //     },
            //     {
            //         // for filter menu
            //         code: "node.numberOfDocuments",
            //         name: this.$t("headers.documents1"),
            //         enabled: true,
            //         // for table header
            //         text: this.$t("headers.documents1"),
            //         align: "right",
            //         value: "node.numberOfDocuments"
            //     },
            //     {
            //         // for filter menu
            //         code: "node.numberOfUnresolvedAlerts",
            //         name: this.$t("headers.alerts1"),
            //         enabled: true,
            //         // for table header
            //         text: this.$t("headers.alerts1"),
            //         align: "right",
            //         value: "node.numberOfUnresolvedAlerts"
            //     }
            // ],
        };
    },
    beforeCreate() {
        this.formatAirwaybillNumber = helper.formatAirwaybillNumber;
        this.removeHifenFromAirwaybill = helper.removeHifenFromAirwaybill;
    },
    created() {
        this.getTemperatureSymbol = helper.getTemperatureSymbol;
        this.headerChoices = helper.getTableHeaders(
            "freightTableHeaders",
            this.defaultHeaderChoices
        );
    },
    computed: {
        screenSize() {
            let _resposiveView = this.$vuetify.breakpoint.width;
            if (_resposiveView < 650) {
                return "xs";
            } else if (_resposiveView < 1000) {
                return "sm";
            } else if (_resposiveView < 1400) {
                return "md";
            } else if (_resposiveView < 1900) {
                return "lg";
            } else {
                return "xl";
            }
        },
        me() {
            return helper.me();
        },
        // infinite
        hasMoreData() {
            return this.freights?.pageInfo?.hasNextPage;
        },
        centralizeLoadBtnClass() {
            let _centralizeLoadBtnClass = {
                position: "sticky",
                left: "50%",
            };
            return _centralizeLoadBtnClass;
        }, 
        isTripsRoute() {
            let route = this.$route;
            return route.name == "freights" && route?.query?.id !== null;
        },

        dynamicHeaders() {
            let _headers = this.headerChoices.filter(header => header.enabled);
            _headers = _headers.map(header => {
                return {
                    ...header,
                    text: this.$t(header.tag)
                };
            });
            // put favorite in the first column

            _headers.unshift({
                text: "",
                align: "left",
                value: "isFavorite",
                width: "64px",
                sortable: false
            });

            // put header filter in the last column
            // _headers.push({
            //     text: "",
            //     align: "right",
            //     value: "headerFilter",
            //     sortable: false
            // });

            return _headers;
        },
        defaultHeaderChoices: function() {
            let _defaultHeaderChoices = [
                {
                    // for filter menu
                    code: "node.referenceNumber",
                    name: "reference Number",
                    tag: "headers.reference_number",
                    enabled: true,
                    // for table header
                    text: "Reference Number",
                    align: "left",
                    value: "node.referenceNumber"
                },
                {
                    // for filter menu
                    code: "node.trip.referenceNumber",
                    name: "Trip Number",
                    tag: "headers.trip_number",
                    enabled: true,
                    // for table header
                    text: "Trip Number",
                    align: "left",
                    value: "node.trip.referenceNumber"
                },
                {
                    // for filter menu
                    code: "node.trip.transportMode",
                    name: "Transport Mode",
                    tag: "headers.transport_mode",
                    enabled: true,
                    // for table header
                    text: "Transport Mode",
                    align: "left",
                    value: "node.trip.transportMode"
                },
                {
                    // for filter menu
                    code: "node.vessel",
                    name: "Vessel",
                    tag: "headers.vessel",
                    enabled: true,
                    // for table header
                    text: "Vessel",
                    align: "left",
                    value: "node.vessel"
                },
                {
                    // for filter menu
                    code: "node.trip.status",
                    name: "Status",
                    tag: "headers.status",
                    enabled: true,
                    // for table header
                    text: "Status",
                    align: "left",
                    value: "node.trip.status"
                },
                {
                    // for filter menu
                    code: "carrier",
                    name: "Carrier",
                    tag: "headers.carrier",
                    enabled: true,
                    // for table header
                    text: "Carrier",
                    align: "left",
                    value: "carrier"
                },
                {
                    // for filter menu
                    code: "node.trip.plannedDepartureDate",
                    name: "Planned Departure Date",
                    tag: "trips.planned_departure",
                    enabled: true,
                    // for table header
                    text: "Planned Departure Date",
                    align: "left",
                    value: "node.trip.plannedDepartureDate"
                },
                {
                    // for filter menu
                    code: "node.trip.plannedArrivalDate",
                    name: "Planned Arrival Date",
                    tag: "trips.planned_arrival",
                    enabled: true,
                    // for table header
                    text: "Planned Arrival Date",
                    align: "left",
                    value: "node.trip.plannedArrivalDate"
                },
                {
                    // for filter menu
                    code: "node.containerNumber",
                    name: "Container Number",
                    tag: "headers.container_number",
                    enabled: true,
                    // for table header
                    text: "Container Number",
                    align: "left",
                    value: "node.containerNumber"
                },
                {
                    // for filter menu
                    code: "node.airWaybillNumber",
                    name: "AWB Number",
                    tag: "headers.awb_number",
                    enabled: true,
                    // for table header
                    text: "AWB Number",
                    align: "left",
                    value: "node.airWaybillNumber"
                },
                {
                    // for filter menu
                    code: "node.trackerLastConnectionTime",
                    name: "Last Connection",
                    tag: "headers.last_connection",
                    enabled: true,
                    // for table header
                    text: "Last Connection",
                    align: "left",
                    value: "node.trackerLastConnectionTime"
                },
                {
                    // for filter menu
                    code: "node.trackerLastLocation",
                    name: "Last Location",
                    tag: "headers.last_location",
                    enabled: true,
                    // for table header
                    text: "Last Location",
                    align: "left",
                    value: "node.trackerLastLocation"
                },
                {
                    // for filter menu
                    code: "node.billOfLadingNumber",
                    name: "Bill Of Lading",
                    tag: "headers.bill",
                    enabled: true,
                    // for table header
                    text: "Bill Of Lading",
                    align: "left",
                    value: "node.billOfLadingNumber"
                },
                {
                    // for filter menu
                    code: "trackers",
                    name: "Trackers",
                    tag: "home.trackers",
                    enabled: true,
                    // for table header
                    text: "Trackers",
                    align: "left",
                    value: "trackers"
                },
                {
                    // for filter menu
                    code: "consignor",
                    name: "Consignor",
                    tag: "headers.consignor",
                    enabled: false,
                    // for table header
                    text: "Consignor",
                    align: "left",
                    value: "consignor"
                },
                {
                    // for filter menu
                    code: "consignee",
                    name: "Consignee",
                    tag: "headers.consignee",
                    enabled: false,
                    // for table header
                    text: "Consignee",
                    align: "left",
                    value: "consignee"
                },
                {
                    // for filter menu
                    code: "forwarder",
                    name: "Forwarder",
                    tag: "headers.forwarder",
                    enabled: false,
                    // for table header
                    text: "Forwarder",
                    align: "left",
                    value: "forwarder"
                },
                {
                    // for filter menu
                    code: "other",
                    name: "Other",
                    tag: "home.other",
                    enabled: false,
                    // for table header
                    text: "Other",
                    align: "left",
                    value: "other"
                },
                {
                    // for filter menu
                    code: "node.goods",
                    name: "Goods",
                    tag: "forms.goods",
                    enabled: false,
                    // for table header
                    text: "Goods",
                    align: "left",
                    value: "node.goods"
                },
                {
                    // for filter menu
                    code: "POL",
                    name: "POL",
                    tag: "others.pol",
                    enabled: false,
                    // for table header
                    text: "POL",
                    align: "left",
                    value: "POL"
                },
                {
                    // for filter menu
                    code: "transshipment",
                    name: "T/S",
                    tag: "others.ts",
                    enabled: false,
                    // for table header
                    text: "T/S",
                    align: "left",
                    value: "transshipment"
                },
                {
                    // for filter menu
                    code: "POD",
                    name: "POD",
                    tag: "others.pod",
                    enabled: false,
                    // for table header
                    text: "POD",
                    align: "left",
                    value: "POD"
                },
               {
                    // for filter menu
                    code: "numberOfPartners",
                    name: "#" + "Partners",
                    tag: "headers.partners1",
                    enabled: true,
                    // for table header
                    text: "#" + "Partners",
                    align: "right",
                    value: "node.trip.numberOfPartners"
                }, 
               {
                    // for filter menu
                    code: "numberOfDocuments",
                    name: "#" + "Documents",
                    tag: "headers.documents1",
                    enabled: true,
                    // for table header
                    text: "#" + "Documents",
                    align: "right",
                    value: "node.trip.numberOfDocuments"
                }, 
               {
                    // for filter menu
                    code: "numberOfUnresolvedAlerts",
                    name: "#" + "Alerts",
                    tag: "headers.alerts1",
                    enabled: true,
                    // for table header
                    text: "#" + "Alerts",
                    align: "right",
                    value: "node.trip.numberOfUnresolvedAlerts"
                } 
            ];
            return _defaultHeaderChoices;
        }
    },
    watch: {},
    mounted() {
        this.loadMore();

        // watch headerChoices
        this.$watch(
            "headerChoices",
            function(value) {
                helper.updateMyPreferences({
                    freightTableHeaders: value
                });
            },
            { deep: true }
        );
    },
    methods: {
        onResize() {
            this.$nextTick(() => {
                this.tableHeight = window.innerHeight - 180 + "px";
            });
        },
        startScrolling(direction) {
            this.scrollInterval = setInterval(() => {
                const tableContainer = document.querySelector(
                    ".v-data-table__wrapper"
                );
                const scrollAmount = direction === "left" ? -10 : 10;
                tableContainer.scrollLeft += scrollAmount;
            }, 10);
        },
        stopScrolling() {
            clearInterval(this.scrollInterval);
        },
        formatDateTime(datetime) {
            return helper.formatDateTime(datetime);
        },
        formatTemperature(temperature) {
            return helper.formatTemperature(temperature);
        },
        onFilterMenuChanged(newChoices) {
            this.headerChoices = newChoices;
        },
        onLoadMoreTriggerIntersect(entries) {
            this.shouldLoadMore = entries[0].isIntersecting;

            if (this.shouldLoadMore && this.freights?.pageInfo?.hasNextPage) {
                this.loadMore();
            }
        },
        refetchDate() {
            // use timeout to debouce
            if (this.timeout) clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                this.$apollo.queries.freights.refetch(this.filter);
            }, 800); // delay
        },
        loadMore() {
            if (this.freights?.pageInfo?.hasNextPage) {
                this.$apollo.queries.freights.fetchMore({
                    variables: {
                        after: this.freights.pageInfo.endCursor
                    }
                });
            }
        },
        getAllVessels(tripSeaTransportSet) {
            let vessels = [];
            let uniqueVessels = new Set();
            tripSeaTransportSet?.edges.forEach(edge => {
                const vessel = edge.node?.vessel;
                if (vessel && !uniqueVessels.has(vessel.id)) {
                    uniqueVessels.add(vessel.id);
                    vessels.push(vessel);
                }
            });
            return vessels;
        },
        getAllGoods(tripFreightSet) {
            let goodsToReturn = [];
            let uniqueGoods = new Set();
            tripFreightSet?.edges.forEach(edge => {
                const goods = edge.node?.goods;
                if (goods && !uniqueGoods.has(goods.toLowerCase())) {
                    uniqueGoods.add(goods.toLowerCase());
                    goodsToReturn.push(goods);
                }
            });
            return goodsToReturn;
        },
        getExternalTrackerAdditionalInfo(additionalInfo) {
            let additionalInfoToReturn = null;
            if (additionalInfo) {
                let additionalInfoObj = JSON.parse(additionalInfo);
                if (additionalInfoObj?.externalTracker) {
                    additionalInfoToReturn = additionalInfoObj?.externalTracker;
                }
            }
            return additionalInfoToReturn;
        }
    }
};
</script>
<style scoped lang="css">
.row-pointer >>> tbody tr :hover {
    cursor: pointer;
}
</style>

<template>
    <v-container fluid class="px-0 mt-8" style="background-color: transparent;">
        <v-form v-model="valid" ref="locationForm">
            <v-row>
                <v-col cols="12">
                    <v-card flat class="rounded-lg contentDisplay ">
                        <v-card-title class="mb-4">
                            <div class="textTitle">
                                {{ $t("general.select_transport_mode") }}
                            </div>
                            <v-spacer></v-spacer>
                            <div>
                                <slot name="templateSlot"></slot>
                            </div>
                        </v-card-title>
                        <v-card-text>
                            <v-row class="mb-2">
                                <v-col
                                    cols="12"
                                    xs="12"
                                    sm="6"
                                    md="3"
                                    lg="3"
                                    xl="3"
                                    v-for="transport in transportModeChoices"
                                    :key="transport.value"
                                >
                                    <v-card
                                        height="180px"
                                        class="d-flex justify-center align-center flex-column rounded-lg"
                                        outlined
                                        :color="trip.transportMode === transport.value ? 'primary' : ''"
                                        @click="selectTransportMode(transport.value)"
                                    >
                                        <div class="pa-2">
                                            <v-icon
                                                x-large
                                                :color="trip.transportMode === transport.value ? 'white' : '#0e1e46'"
                                                :style="'transform: rotate(' + transport.iconRotate + ');'"
                                                >{{ transport.icon }}</v-icon
                                            >
                                        </div>
                                        <div class="textSubTitle">
                                            <span
                                                :style="
                                                    trip.transportMode === transport.value
                                                        ? 'color: white'
                                                        : 'color:#0e1e46'
                                                "
                                                class="d-flex align-center text-center"
                                            >
                                                {{ transport.text }}
                                                {{ $t("trips.freight") }}
                                            </span>
                                        </div>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions class="px-5 py-0 ma-0">
                            <slot name="alertSlot"></slot>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="" lg="" xl="" class="pr-0 rounded-lg" v-if="trip.transportMode">
                    <v-card flat class="rounded-lg contentDisplay">
                        <v-card-title>
                            <span class="textTitle">{{ $t("trips.from") }}</span>
                        </v-card-title>
                        <v-card-text>
                            <v-row dense :class="isSmallScreen ? 'pr-4' : ''">
                                <v-col cols="12">
                                    <v-row dense>
                                        <v-col cols="auto" class="textBody labelContent">
                                            <span>
                                                {{ $t("trips.origin") }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <v-autocomplete
                                        class="textBody inputContent mb-n2"
                                        v-model="trip.origin"
                                        :loading="$apollo.queries.tenantLocations.loading"
                                        :items="tenantLocations ? tenantLocations.edges : []"
                                        :no-data-text="$t('loading.no_available')"
                                        item-text="node.name"
                                        item-value="node.id"
                                        outlined
                                        clearable
                                        dense
                                        hide-selected
                                    >
                                        <template v-slot:item="data">
                                            <v-row class="ma-0 body-2" dense>
                                                <v-col cols="9" class="text-truncate pl-0 ml-0">
                                                    {{ data.item.node.name }}
                                                </v-col>
                                                <v-col cols="3">
                                                    <span class="grey--text mr-0 float-right"
                                                        ><i>{{
                                                            selectLabelByType(data)
                                                        }}</i></span
                                                    >
                                                </v-col>
                                            </v-row>
                                        </template>
                                        <template #append-item>
                                            <v-divider class="mt-4 append-divider"></v-divider>
                                            <v-list-item dense class="append">
                                                <v-list-item-content>
                                                    <v-btn
                                                        color="primary"
                                                        @click="openCreateLocationDialog('origin')"
                                                        block
                                                        class="mt-4"
                                                    >
                                                        {{ $t("general.create_location") }}
                                                    </v-btn>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12">
                                    <v-row dense>
                                        <v-col cols="auto" class="textBody labelContent">
                                            <span>
                                                {{
                                                    templateMode
                                                        ? $t("date.departure_date")
                                                        : $t("date.departure_date") + "*"
                                                }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <DateTimeComponent
                                        v-if="!templateMode"
                                        v-model="trip.plannedDepartureDate"
                                        :icon="'calendar_month'"
                                        :rules="[rules.requiredDateTime]"
                                        :outlined="true"
                                        :dense="true"
                                        :disabled="templateMode"
                                        :filled="templateMode"
                                        :defaultTime="trip.plannedDepartureTime || '00:00'"
                                        class="inputContent"
                                    >
                                    </DateTimeComponent>
                                    <DateComponent
                                        v-else
                                        v-model="trip.plannedDepartureDate"
                                        :icon="'calendar_month'"
                                        :rules="[rules.required]"
                                        :outlined="true"
                                        :dense="true"
                                        :disabled="templateMode"
                                        :filled="templateMode"
                                        class="inputContent"
                                    ></DateComponent>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-card
                    class="d-flex justify-center align-center"
                    width="20px"
                    flat
                    style="
                        background-color: transparent;
                    "
                    v-if="trip.transportMode && !isSmallScreen"
                >
                    <v-btn
                        fab
                        small
                        color="primary"
                        elevation="0"
                        style="
                            background-color: transparent;
                            z-index: 99;
                            position: relative;
                            top: 8%;
                            left: 100%;
                            transform: translateX(-50%);
                        "
                        @click="switchLocation"
                        :disabled="!enableSwitchLocation"
                    >
                        <v-icon>
                            multiple_stop
                        </v-icon>
                    </v-btn>
                </v-card>
                <v-col cols="12" v-if="isSmallScreen" class="d-flex justify-center">
                    <v-btn
                        fab
                        small
                        color="primary"
                        elevation="0"
                        style=""
                        @click="switchLocation"
                        :disabled="!enableSwitchLocation"
                    >
                        <v-icon>
                            multiple_stop
                        </v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="" lg="" xl="" class="pl-0 rounded-lg" v-if="trip.transportMode">
                    <v-card flat class="rounded-lg contentDisplay">
                        <v-card-title>
                            <span class="textTitle">{{ $t("trips.to") }}</span>
                        </v-card-title>
                        <v-card-text>
                            <v-row dense :class="isSmallScreen ? 'pl-4' : ''">
                                <v-col cols="12">
                                    <v-row dense>
                                        <v-col cols="auto" class="textBody labelContent">
                                            <span>
                                                {{ $t("trips.destination") }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <v-autocomplete
                                        v-model="trip.destination"
                                        class="textBody inputContent mb-n2"
                                        :loading="$apollo.queries.tenantLocations.loading"
                                        :no-data-text="$t('loading.no_available')"
                                        :items="tenantLocations ? tenantLocations.edges : []"
                                        item-text="node.name"
                                        item-value="node.id"
                                        outlined
                                        clearable
                                        hide-selected
                                        dense
                                    >
                                        <template v-slot:item="data">
                                            <v-row class="ma-0 body-2">
                                                <v-col cols="9" class="text-truncate pl-0 ml-0">
                                                    {{ data.item.node.name }}
                                                </v-col>
                                                <v-col cols="3">
                                                    <span class="grey--text mr-0 float-right"
                                                        ><i>{{
                                                            selectLabelByType(data)
                                                        }}</i></span
                                                    >
                                                </v-col>
                                            </v-row>
                                        </template>
                                        <template #append-item>
                                            <v-divider class="mt-4 append-divider"></v-divider>
                                            <v-list-item dense class="append">
                                                <v-list-item-content>
                                                    <v-btn
                                                        color="primary"
                                                        @click="openCreateLocationDialog('destination')"
                                                        block
                                                        class="mt-4"
                                                    >
                                                        {{ $t("general.create_location") }}
                                                    </v-btn>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12">
                                    <v-row dense>
                                        <v-col cols="auto" class="textBody labelContent">
                                            <span>
                                                {{ $t("date.arrival_date") }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <DateTimeComponent
                                        v-if="!templateMode"
                                        v-model="trip.plannedArrivalDate"
                                        :icon="'calendar_month'"
                                        :rules="
                                            trip.plannedArrivalDate !== null && trip.plannedArrivalDate.length > 0
                                                ? [rules.checkDate]
                                                : []
                                        "
                                        :outlined="true"
                                        :dense="true"
                                        :disabled="templateMode"
                                        :filled="templateMode"
                                        :defaultTime="trip.plannedArrivalTime || '00:00'"
                                        class="inputContent"
                                    >
                                    </DateTimeComponent>
                                    <DateComponent
                                        v-else
                                        v-model="trip.plannedArrivalDate"
                                        :icon="'calendar_month'"
                                        :outlined="true"
                                        :dense="true"
                                        :disabled="
                                            trip.plannedDepartureDate === null ||
                                                trip.plannedDepartureDate.length < 1 ||
                                                templateMode
                                        "
                                        :filled="templateMode"
                                        class="inputContent"
                                    ></DateComponent>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <AddLocation
                :openAddLocationDialog="openAddLocation"
                v-on:closeMap="openAddLocation = false"
                v-on:updateLocationsTable="$apollo.queries.tenantLocations.refetch()"
                v-on:updateLocation="updateLocation"
            >
            </AddLocation>
        </v-form>
    </v-container>
</template>
<script>
import gql from "graphql-tag";
import AddLocation from "@/components/locations/create/AddLocation.vue";
import DateComponent from "@/components/base/DateComponent.vue";
//import TimeComponent from "@/components/base/TimeComponent.vue";
import DateTimeComponent from "@/components/base/DateTimeComponent.vue";
import helper from "@/utils/helper.js";
import moment from "moment-timezone";

export default {
    props: {
        value: {
            type: Object
        },
        validate: {
            type: Boolean,
            default: false
        },
        transportMode: {
            type: String,
            default: ""
        },
        isOpen: {
            type: Boolean,
            default: false
        },
        templateMode: {
            type: Boolean,
            default: false
        }
    },
    components: {
        AddLocation,
        DateComponent,
        //TimeComponent,
        DateTimeComponent
    },
    apollo: {
        tenantLocations: {
            query: gql`
                query tenantLocations(
                    $search: String
                    $first: Int
                    $last: Int
                    $before: String
                    $after: String
                    $tenantPartnerNameIcontains: String
                    $nameIcontains: String
                    $cityIcontains: String
                    $countryIcontains: String
                ) {
                    tenantLocations(
                        search: $search
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                        orderBy: "name"
                        tenantPartner_Name_Icontains: $tenantPartnerNameIcontains
                        name_Icontains: $nameIcontains
                        city_Icontains: $cityIcontains
                        country_Icontains: $countryIcontains
                        locationType_In: ["tenant_location", "partner_location", "private_location"]
                    ) {
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                        edges {
                            node {
                                id
                                name
                                locationType
                                isTrackerStockLocation
                                fullAddress
                                city
                                state
                                country
                            }
                        }
                    }
                }
            `,
            variables() {},
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            debounce: 800,
            update: data => {
                return data.tenantLocations;
            },
            skip: false
        }
    },
    data() {
        return {
            openAddLocation: false,
            hide: false,
            locationType: "",
            system24: [
                "00:00",
                "00:30",
                "01:00",
                "01:30",
                "02:00",
                "02:30",
                "03:00",
                "03:30",
                "04:00",
                "04:30",
                "05:00",
                "05:30",
                "06:00",
                "06:30",
                "07:00",
                "07:30",
                "08:00",
                "08:30",
                "09:00",
                "09:30",
                "10:00",
                "10:30",
                "11:00",
                "11:30",
                "12:00",
                "12:30",
                "13:00",
                "13:30",
                "14:00",
                "14:30",
                "15:00",
                "15:30",
                "16:00",
                "16:30",
                "17:00",
                "17:30",
                "18:00",
                "18:30",
                "19:00",
                "19:30",
                "20:00",
                "20:30",
                "21:00",
                "21:30",
                "22:00",
                "22:30",
                "23:00",
                "23:30"
            ],
            rules: {
                required: v => !helper.isEmpty(v) || this.$t("rules.required"),
                requiredDateTime: v => {
                    if (v === null || v === undefined || v === "") {
                        return this.$t("rules.required");
                    }
                    let [date, time] = v.split(" ");
                    return (!this.isEmpty(date) && !this.isEmpty(time)) || this.$t("rules.required");
                },
                minLength: len => v => (v || "").length >= len || this.$t("rules.invalid") + ` ${len}`,
                maxLength: len => v => (v || "").length <= len || this.$t("rules.too_long"),
                alertIsInvalid: v => v === false || this.$t("rules.ref_no_exists"),
                //check if planned arrival date is not less than planned departure date
                checkDate: () => {
                    //let _selectedDate = new Date(v)
                    if (this.trip.plannedDepartureDate === null || this.trip.plannedDepartureDate.length < 1) {
                        return true;
                    }
                    if (this.trip.plannedArrivalDate === null || this.trip.plannedArrivalDate.length < 1) {
                        return true;
                    }

                    let [dateA, timeA] = this.trip.plannedDepartureDate.split(" ");
                    let [dateB, timeB] = this.trip.plannedArrivalDate.split(" ");

                    if (this.isEmpty(dateA) || this.isEmpty(timeA)) {
                        return true;
                    }

                    //dateTime A
                    let _newDateA = moment(dateA, helper.getDateFormat());
                    let _DateA = moment(_newDateA, ["DD/MM/YYYY", "YYYY/MM/DD", "MM/DD/YYYY"]).toDate();
                    let _dateFormatA = moment(_DateA).format("YYYY-MM-DD");

                    //dateTime B
                    let _newDateB = moment(dateB, helper.getDateFormat());
                    let _DateB = moment(_newDateB, ["DD/MM/YYYY", "YYYY/MM/DD", "MM/DD/YYYY"]).toDate();
                    let _dateFormatB = moment(_DateB).format("YYYY-MM-DD");

                    let dateTimeA = _dateFormatA + " " + timeA;
                    let dateTimeB = _dateFormatB + " " + timeB;

                    let validDate = moment(dateTimeA).isSameOrBefore(dateTimeB);

                    /*  let _date1 = moment(this.trip.plannedDepartureDate)
                    let _date2 = moment(this.trip.plannedArrivalDate)
                    let dateDiff = _date2.diff(_date1,'days') */

                    return validDate || this.$t("rules.diff");
                }
            }
        };
    },
    computed: {
        trip: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        },
        isSmallScreen() {
            return this.$vuetify.breakpoint.width < 960;
        },
        valid: {
            get() {
                return this.validate;
            },
            set(value) {
                this.$emit("update:validate", value);
            }
        },
        me() {
            return helper.me();
        },
        transportModeChoices() {
            let _transportModeChoices = [
                {
                    text: this.$t("transport_mode_choice.sea"),
                    value: "sea",
                    icon: "directions_boat",
                    iconRotate: "0deg"
                },
                {
                    text: this.$t("transport_mode_choice.air"),
                    value: "air",
                    icon: "flight",
                    iconRotate: "45deg"
                },
                {
                    text: this.$t("transport_mode_choice.road"),
                    value: "road",
                    icon: "local_shipping",
                    iconRotate: "0deg"
                },
                {
                    text: this.$t("transport_mode_choice.rail"),
                    value: "rail",
                    icon: "directions_railway",
                    iconRotate: "0deg"
                }
                //{ text: this.$t("transport_mode_choice.other"), value: "other" }
            ];
            return _transportModeChoices;
        },
        enableSwitchLocation() {
            return this.trip.origin !== null && this.trip.destination !== null;
        }
    },
    mounted() {
        if (!this.editMode) {
            let organisationPreferences = this.me.tenant?.organisationPreferences;
            if (
                organisationPreferences != null &&
                organisationPreferences != "" &&
                organisationPreferences != undefined
            ) {
                let _preferences = JSON.parse(organisationPreferences);
                if (_preferences != null && _preferences?.transportModeChoice != null && _preferences?.transportModeChoice != "" && (this.trip.transportMode == null && this.trip.transportMode == "")) {
                    this.trip.transportMode = Array.isArray(_preferences.transportModeChoice)
                        ? ""
                        : _preferences.transportModeChoice;
                }
            }
        }
    },
    watch: {
        "trip.plannedDepartureDate": function(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.$refs.locationForm.validate();
            }
        },
        "trip.plannedArrivalDate": function(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.$refs.locationForm.validate();
            }
        }
    },
    methods: {
        switchLocation() {
            let _origin = this.trip.origin;
            let _destination = this.trip.destination;
            this.trip.origin = _destination;
            this.trip.destination = _origin;
            let _plannedDepartureDate = this.trip.plannedDepartureDate;
            let _plannedArrivalDate = this.trip.plannedArrivalDate;
            this.trip.plannedDepartureDate = _plannedArrivalDate;
            this.trip.plannedArrivalDate = _plannedDepartureDate;
        },
        selectTransportMode(transport) {  
            this.trip.transportMode = transport;
        },
        openCreateLocationDialog(locationType) {
            this.$emit("createLocation", {
                path: "/createlocation",
                query: {
                    type: "createLocation",
                    field: locationType
                }
            });
        },
        selectLabelByType(data) {
            let val = data?.item?.node?.locationType?.toLowerCase() ?? "";
            if (val === "tenant_location") return this.$t("alert_rules.public");
            if (val === "partner_location") return this.$t("home.partner");
            if (val === "private_location") return "";
        },
        resetForm() {
            this.$refs.locationForm.reset();
        },
        updateLocation(id) {
            if (this.locationType === "origin") {
                this.trip.origin = id;
            } else if (this.locationType === "destination") {
                this.trip.destination = id;
            }
        },
        isEmpty(val) {
            if (
                val === null ||
                val === undefined ||
                val === "" ||
                val === "null" ||
                val === "undefined" ||
                val === "NaN" ||
                val === "Invalid date-undefined-undefined"
            ) {
                return true;
            } else {
                return false;
            }
        }
    }
};
</script>
<style scoped>
.textTitle {
    font-size: 18px;
    font-weight: 700;
    color: "#0e1e46" !important;
}
.textSubTitle {
    font-size: 21px;
    font-weight: 700;
    color: "#0e1e46" !important;
}

.textBody {
    font-size: 12px;
    font-weight: 400;
    color: "#868599" !important;
}
.append {
    position: sticky;
    bottom: 0; /* Adjust as needed, might not need !important if there's no conflict */
    width: 100%;
    display: flex;
    justify-content: center;
    background: rgb(254, 254, 254);
    z-index: 5; /* Ensure it's above other content */
}
.append-divider {
    position: sticky;
}
.inputContent {
    height: 52px;
}
.labelContent {
    height: 30px;
}
.contentDisplay {
    padding-left: 20px;
    padding-right: 20px;
}
.contentDisplay2 {
    padding-left: 36px;
    padding-right: 36px;
}
.contentDisplay3 {
    padding-left: 70px;
    padding-right: 70px;
}
</style>
